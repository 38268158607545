import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

import { Layout, SsModule } from '@app/models';
import { DropdownService, GenericModuleService, LayoutService, RoleAccessService, SharedUtilsService, SsModuleService, TableService, UserService } from '@app/services';

@Component({
  selector: 'mass-update-dialog',
  templateUrl: './mass-update-dialog.component.html',
  styleUrls: ['./mass-update-dialog.component.scss']
})
export class MassUpdateDialogComponent implements OnInit {
  /**
   * ! updateInfo options format
    {
      moduleName: 'Location',
      fieldMappings: [
        {fieldName: 'name', fieldLabel: 'Name', fieldType: 'input'},
        {fieldName: 'vendor', fieldLabel: 'Vendor', fieldType: 'dropdown', fieldValues: this.vendors},
        {fieldName: 'locationType', fieldLabel: 'School Type', fieldType: 'dropdown', fieldValues: this.schoolTypeList},
        {fieldName: 'region', fieldLabel: 'Region', fieldType: 'dropdown', fieldValues: this.regionList}
      ]
    }
  */
  @Input() updateInfo: any;
  massUpdateForm: UntypedFormGroup;
  items = [];

  fullModule: SsModule = null;
  filteredFields = null;

  recordIds;
  confirmationResult = false;
  newRecord = {};
  chosenRecordCount: number = null;

  checkboxDropdownField;
  isBuildingData: boolean = true;

  constructor(
    private _moduleService: SsModuleService,
    private _genericModuleService: GenericModuleService,
    private _roleAccessService: RoleAccessService,
    public _layoutService: LayoutService,
    private _sharedUtilsService: SharedUtilsService,
    private _dropdownService: DropdownService,
    private _userService: UserService,
    private _tableService: TableService,
    public dialogRef: MatDialogRef<MassUpdateDialogComponent>
  ) { }

  ngOnInit() {
    // console.log('updateInfo: ', this.updateInfo);
    this.initMassUpdate();
  }


  async initMassUpdate() {
    if (!this.fullModule) this.fullModule = await this._moduleService.getFullModuleByName(this.updateInfo.moduleName);
    // console.log('Full Module: ', this.fullModule);

    const formGroup = {};
    // console.log('Field Mappings: ', this.updateInfo.fieldMappings);

    const massUpdateLayout = await this.getMassUpdateLayout();
    // console.log('Mass Update Layout: ', massUpdateLayout);

    this.chosenRecordCount = this._tableService.selectedRows.selected.length;

    if (!massUpdateLayout) {
      this._sharedUtilsService.showErrorDialog("No Mass Update Layout for this module");
      this.closeDialog();
    } else {
      const massUpdateFields = (massUpdateLayout && massUpdateLayout.sections && massUpdateLayout.sections.length) ? massUpdateLayout.sections[0].sectionFields : [];

      // console.log('massUpdateFields: ', massUpdateFields);


      const modulePermissions = await this._roleAccessService.getModuleAccessInfo(this.fullModule.name);
      // console.log('modulePermissions: ', modulePermissions);

      let filteredFields = massUpdateFields.filter(_f => {
        // filter out those they do not have access to

        let fieldPasses = true;

        if (!_f || !_f.fieldName) fieldPasses = false;

        if (fieldPasses && modulePermissions && modulePermissions.fields) {
          // so far, field is valid. Check module permissions if they exist
          if (modulePermissions.fields[_f.fieldName]) {
            // console.log('A field in the mass update layout is included in module permissions for: ', _f.fieldName);
            // console.log('Permissions: ', modulePermissions.fields[_f.fieldName]);

            if (['None', 'Read Only', 'Owner Read/Owner Write', 'Read/Owner Write'].includes(modulePermissions.fields[_f.fieldName])) {
              fieldPasses = false;
            }
          }
        }

        return fieldPasses;
      });

      // convert checkboxes to dropdowns, checkbox left as false would not trigger update
      let checkboxIndex = filteredFields.findIndex(_f => _f.type.inputType === 'Checkbox');
      let checkboxField = null;

      if (checkboxIndex !== -1) {
        // get the checkbox dropdown
        const checkboxDropdown = await lastValueFrom(this._dropdownService.getByName({ name: 'checkbox_replacement' }));

        checkboxField = {
          default: null,
          required: false,
          dropdown: checkboxDropdown,
          type: { inputType: "Dropdown", fieldType: "Object", schemaType: "String" }
        };
      }

      // replace all checkbox fields with the yes/no dropdown field
      while (checkboxIndex !== -1) {
        filteredFields[checkboxIndex] = { ...checkboxField, fieldName: filteredFields[checkboxIndex].fieldName, label: filteredFields[checkboxIndex].label };
        checkboxIndex = filteredFields.findIndex(_f => _f.type.inputType === 'Checkbox');
      }

      this.filteredFields = filteredFields;

      for (let field of this.filteredFields) {
        formGroup[field.fieldName] = new UntypedFormControl();
      }

      this.massUpdateForm = new UntypedFormGroup(formGroup);

      // Build defaults for records, function needs filteredFields set first
      this.newRecord = {}; // await this.addDefaultValues(this.filteredFields);
      // console.log('newRecord: ', this.newRecord);

      this.isBuildingData = false;
    }
  }


  addDefaultValues(_massUpdatefields) {
    return new Promise(async (resolve) => {
      // Check if array with length was passed, if not then resolve an empty object
      if (_massUpdatefields != undefined && _massUpdatefields.length) {
        const _newModuleRecord = {};

        // Map through filtered fields and add any defaults we want to here
        const builtDefaultsPromise = _massUpdatefields.map(async _field => {
          if (_field != undefined && _field.fieldName != undefined) {
            // Set default values here
            if (_field.fieldName === 'teams') {
              const globalTeam = await this._userService.getGlobalTeam();
              if (globalTeam != undefined) _newModuleRecord['teams'] = [globalTeam];
              else _newModuleRecord['teams'] = [];
            }
          }
        });

        // Wait for defaults to be built, resolve is not waiting for deep awaits without Promise.all
        return Promise.all(builtDefaultsPromise).then(() => {
          resolve(_newModuleRecord);
        });
      } else resolve({});
    });
  }


  getMassUpdateLayout(): Promise<Layout> {
    return new Promise(async (resolve) => {
      let massUpdateLayout = this.fullModule.layouts.find(_l => _l.view === 'mass-update');

      if (!massUpdateLayout) {
        const layouts = await lastValueFrom(this._layoutService.search({ ssModule: this.fullModule._id, view: 'mass-update' }));
        // console.log('Mass Update Layouts: ', layouts);
      }

      resolve(massUpdateLayout);
    });
  }


  doMassUpdate() {
    // console.log('newRecord: ', this.newRecord);
    this.confirmationResult = true;
    this.closeDialog();
  }


  cancel() {
    this.confirmationResult = false;
    this.closeDialog();
  }


  closeDialog() {
    const massUpdateResponse = {
      performAction: this.confirmationResult,
      changesMade: this.newRecord,
      recordIds: this.recordIds
    };
    return this.dialogRef.close(massUpdateResponse);
  }


  relatedFieldChosen(relatedFieldObj) {
    // console.log('A related field was chosen: ', relatedFieldObj);

    const _field = relatedFieldObj.field;
    const _chosenSelection = relatedFieldObj.chosenRecord;

    let sourceField = (_field.type && _field.type.schemaType) ? _field.type.schemaType.name : _field.fieldName;

    if (!sourceField) sourceField = _field.fieldName;

    if (!_chosenSelection.name) {
      _chosenSelection.name = this._genericModuleService.getNameForModule(_field.ref, _chosenSelection);
    }

    // console.log('Source Field: ', sourceField);

    if ((!sourceField || sourceField === "Object") && _field.fieldName) sourceField = _field.fieldName;

    this.newRecord[sourceField] = _chosenSelection;
    // this.newRecord[sourceField] = (_chosenSelection && _chosenSelection._id) ? _chosenSelection._id : _chosenSelection;
  }
}