import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as moment from 'moment-mini-ts';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { AdminSettingsService } from './admin-settings.service';

export interface JobApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class JobService {
  private jobObs$ = new Subject<any>();
  private currentJob;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/jobs/';
  private CAMPAIGNS_URL = '/api/campaigns/';

  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(
    private httpClient: HttpClient,
    private _adminSettingsService: AdminSettingsService
  ) { }

  setCurrent(_job): void {
    // console.log('**************** SETTING CURRENT JOB: ', _job);
    this.currentJob = _job;
  }


  getCurrent() {
    return this.currentJob;
  }


  // triggerExportJobsToXml(searchTerms): Observable<any[]> {
  //   return this.httpClient.post<any[]>(this.API_URL + 'export/jobsToXml/*897897**&*(&(dsjdkfjdsfdf**', JSON.stringify(searchTerms), this.headerOptions);
  // }


  create(_job): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_job), this.headerOptions);
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = { ids: arrayOfIds, fields: fieldsToUpdate };
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<JobApi> {
    return this.httpClient.put<JobApi>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_job): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _job._id, JSON.stringify(_job), this.headerOptions);
  }


  updateFavorites(_job): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _job._id, JSON.stringify(_job), this.headerOptions);
  }


  getNextJobId(): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + 'next-job-id');
  }


  jobRatingSearch(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'job-rating-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    if (!searchTerms['selectAll'] && searchTerms['selectAll'] != false) searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getJob(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  basicSearch(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'basic-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  checkForExistingJobsMatchingData(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'checkForExistingJobsMatchingData', JSON.stringify(searchTerms), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  matchLeadsToJob(_job): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + _job._id + '/match-leads', JSON.stringify(_job), this.headerOptions);
  }


  autoGenerateCampaignsFromJob(_job): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + _job._id + '/auto-generate-campaigns', JSON.stringify(_job), this.headerOptions);
  }


  createSmsCampaignFromJob(_job): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + _job._id + '/create-sms-campaign', JSON.stringify(_job), this.headerOptions);
  }


  createEmailCampaignFromJob(_job): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + _job._id + '/create-email-campaign', JSON.stringify(_job), this.headerOptions);
  }


  jobLeadRadiusSearch(_searchTerms) {
    return this.httpClient.post<any[]>(this.API_URL + 'radius-search', JSON.stringify(_searchTerms), this.headerOptions);
  }


  pagedJobLeadRadiusSearch(_searchTerms) {
    return this.httpClient.post<any[]>(this.API_URL + 'paged-radius-search', JSON.stringify(_searchTerms), this.headerOptions);
  }


  getMatchingLeads(_jobId: string): Observable<any[]> {
    // find all jobs for this leads zip in a given radius
    return this.httpClient.get<any>(this.API_URL + _jobId + '/matching-leads');
  }


  getJobsCampaigns(_leadId): Observable<any[]> {
    return this.httpClient.get<any[]>(this.CAMPAIGNS_URL + 'jobs/' + _leadId + '/campaigns');
  }


  getAppliedLeads(_jobId: string): Observable<any[]> {
    // find all jobs for this leads zip in a given radius
    return this.httpClient.get<any>(this.API_URL + _jobId + '/applied-leads');
  }


  doRadiusSearchForLeads(_jobZip, _radius): Observable<any[]> {
    // find all leads for this jobs zip in a given radius
    return this.httpClient.get<any>(this.API_URL + 'leads-in-radius/' + _jobZip + '/' + _radius);
  }


  getLeadsMatchingJobs(_leadId: string): Observable<any[]> {
    return this.httpClient.get<any>(this.API_URL + 'matching-jobs-for-lead/' + _leadId);
  }


  selectAllSearch(searchOptions): Observable<JobApi> {
    searchOptions['selectAll'] = true;
    searchOptions['selectNumberOfRecords'] = false;
    searchOptions.searchTerms['idsOnly'] = false; // Incase it is set on searchTerms.
    return this.httpClient.post<JobApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchOptions), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    searchParams['idsOnly'] = false; // For only returning basic lightweight version of results if we don't need all fields.
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<JobApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;
    searchParams['idsOnly'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<JobApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  // Take start/end dates of Job and return back # of weeks.
  getLengthOfAssignment(_job) {
    return new Promise(async (resolve, reject) => {
      const startDate = _job['start_date'];
      const endDate = _job['end_date'];

      const weeks = (startDate && endDate) ? moment(endDate).diff(moment(startDate), 'weeks') : 0;
      resolve(weeks);
    });
  }



  doesPassBillRateCheck(_job): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const professionBillRateSettings = await lastValueFrom(this._adminSettingsService.getByKey('bill-rates'));
      const currentProfessionRate = professionBillRateSettings.setting.rates.find(_r => _r.profession.value === _job['profession']);
      const billRate = (_job && _job['bill_rate']) ? _job['bill_rate'] : null;

      const passesRateCheck = (currentProfessionRate && billRate && billRate >= currentProfessionRate.rate);
      // console.log('Passes Rate Check: ', passesRateCheck);

      resolve(passesRateCheck);
    });
  }


  processMonthlyHours(_monthlyHours): Promise<any> {
    return new Promise(async (resolve) => {
      console.log('_monthlyHours: ', _monthlyHours);

      const monthlyHoursWithTotals = _monthlyHours.filter(_mh => _mh && _mh['workingDays']).map(_m => {
        _m['totalHours'] = _m['workingDays'] * _m['hoursPerDay'];
        return _m;
      });

      // console.log('monthlyHoursWithTotals: ', monthlyHoursWithTotals);

      const processedMonths = monthlyHoursWithTotals.map((_month, _index) => {
        // console.log();
        // console.log('**************************');
        // console.log('Checking index: ', _index);

        const hoursForEligibility = 130;

        // range to check will be _index through end
        const checkStartIndex = monthlyHoursWithTotals.findIndex(_m => moment(_m.month).isSame(moment(_month.month), 'month'))
        const remainingRangeToCheck = monthlyHoursWithTotals.slice(checkStartIndex);
        // console.log('Months in this check chunk: ', remainingRangeToCheck);

        // if there are MORE yes (eligible) months than nos (non eligible)
        const remainingEligibleMonths = remainingRangeToCheck.filter(_rm => _rm.totalHours > hoursForEligibility);
        const remainingNonEligibleMonths = remainingRangeToCheck.filter(_rm => _rm.totalHours < hoursForEligibility);

        // console.log('Greater than 130: ', remainingEligibleMonths);
        // console.log('Less than 130: ', remainingNonEligibleMonths);
        // console.log('ELIGIBLE: ', remainingEligibleMonths.length);
        // console.log('NON ELIGIBLE: ', remainingNonEligibleMonths.length);

        _month['eligible'] = remainingEligibleMonths.length > remainingNonEligibleMonths.length;

        return _month;
      });

      resolve(processedMonths);
    });
  }


  getRating(_job): Promise<number> {
    return new Promise(async (resolve, reject) => {
      let jobRating = 0;
      let quickRatingSet = false;

      // console.log('JOB: ', _job);

      const statusesForZeroRating = ['Closed - Won', 'Closed - Lost', 'Cancelled'];
      if (statusesForZeroRating.includes(_job['job_status'])) {
        quickRatingSet = true;
      }

      if (_job['opportunity_type'] === 'Direct Hire') {
        jobRating = 5;
        quickRatingSet = true;
      }

      if (!quickRatingSet) {
        // here are the 5 possible stars for ratings

        const conditionalAdminSettings = await lastValueFrom(this._adminSettingsService.getByKey('job-rating-conditionals'));
        const conditionalSettings = (conditionalAdminSettings && conditionalAdminSettings.setting) ? conditionalAdminSettings.setting : null;

        let weeklyHoursRequiredForStar = 29;
        let statusesRequiredForStar = ['Accepting Applications'];
        let assignmentLengthRequiredForStar = 12;

        if (conditionalSettings) {
          weeklyHoursRequiredForStar = conditionalSettings['weekly_hours'];
          statusesRequiredForStar = conditionalSettings['statuses'];
          assignmentLengthRequiredForStar = conditionalSettings['assignment_length'];
        }

        // 1) If this job has an assignment of more than 12 weeks, add a star
        const weeksOfJob = await this.getLengthOfAssignment(_job) as number; // console.log('Weeks of assignment: ', weeksOfJob);
        if (weeksOfJob && weeksOfJob > assignmentLengthRequiredForStar) jobRating++;

        // 2) If this job has more than 29 hours per week, add a star
        if (_job['weekly_hours'] && _job['weekly_hours'] > weeklyHoursRequiredForStar) jobRating++;

        // 3) If this is NOT a RFP job, add a star
        if (!_job['is_rfp']) jobRating++;

        // 4) If this job is accepting applications, add a star
        if (_job['job_status'] && statusesRequiredForStar.includes(_job['job_status'])) jobRating++;

        // 5) If the bill rate is greater than that required for this profession (set by us in admin section), add a star
        const passesBillRateCheck = await this.doesPassBillRateCheck(_job);
        if (passesBillRateCheck) jobRating++;
      }

      resolve(jobRating);
    });
  }
}