export class User {
    _id: string;
    userId: string;
    email: string;
    username: string;
    password: string;
    userType: string;
    first_name: string;
    last_name: string;
    street: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    phone_ext: string;
    other_phone: string;
    assign_leads: boolean;
    sendgrid_approved: boolean;
    recruiter_level: string;
    client: any;
    active: boolean;
    pinning_posts_enabled: boolean;
    inviteSent: Date;
    last_login: Date;
    hire_date: Date;
    birth_date: Date;
    roles;
    teams;
    achievements;
    userProfile;
    deleted;
    usersOnlyToInclude; // Only include these users in search.
    usersToExclude; // Used to exclude passed users from search.
    userTypes: any;
    hasSearchTerms;
    dateCreatedRange;
    dateCreatedEnd;
}
