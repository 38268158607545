import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { HandbookPage } from '../models/handbookPage';
import { Subject } from 'rxjs/internal/Subject';

export interface HandbookPageApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class HandbookPageService {
    private currentHandbookPage;
    private handbookPageUpdateObs$ = new Subject<HandbookPage>();

    private API_URL = '/api/handbook-pages/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }


    setCurrent(_handbookPage): void {
        this.currentHandbookPage = _handbookPage;
    }


    getCurrent() {
        return this.currentHandbookPage;
    }


    getAll(): Observable<HandbookPage[]> {
        return this.httpClient.get<HandbookPage[]>(this.API_URL);
    }


    get(_id: string): Observable<HandbookPage> {
        return this.httpClient.get<HandbookPage>(this.API_URL + _id);
    }


    create(_setting): Observable<HandbookPage> {
        return this.httpClient.post<HandbookPage>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
    }


    update(_handbookPage: HandbookPage): Observable<HandbookPage> {
        return this.httpClient.put<HandbookPage>(`${this.API_URL}${_handbookPage._id}`, JSON.stringify(_handbookPage), this.headerOptions);
    }


    markAsDelete(id: string): Observable<HandbookPage> {
        return this.httpClient.put<HandbookPage>(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
    }


    delete(id: string): Observable<HandbookPage> {
        return this.httpClient.delete<HandbookPage>(this.API_URL + id);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<HandbookPageApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<HandbookPageApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }
}