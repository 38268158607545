import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

export interface SubApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class SubService {
  private currentSub;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/subs/';

  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  public subStatuses = null;

  constructor(
    private httpClient: HttpClient
  ) { }

  setCurrent(_sub): void {
    this.currentSub = _sub;
  }

  getCurrent() {
    return this.currentSub;
  }


  create(_sub): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_sub), this.headerOptions);
  }


  // updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
  //   const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
  //   return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields/', JSON.stringify(updateRequest), this.headerOptions);
  // }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<SubApi> {
    return this.httpClient.put<SubApi>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_sub): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _sub._id, JSON.stringify(_sub), this.headerOptions);
  }


  updateFavorites(_sub): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _sub._id, JSON.stringify(_sub), this.headerOptions);
  }


  normalSearch(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'normal-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  customRecruiterStatSearch(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'recruiter-stat-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getSub(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  getWithRelationships(_moduleOptions): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'with-relationships/' + _moduleOptions.searchTerms._id, JSON.stringify(_moduleOptions), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  selectAllSearch(searchTerms): Observable<SubApi> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    searchTerms['searchTerms']['idsOnly'] = false; // Incase it is set on searchTerms.
    return this.httpClient.post<SubApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    searchParams['idsOnly'] = false; // For only returning basic lightweight version of results if we don't need all fields.
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<SubApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;
    searchParams['idsOnly'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<SubApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }
}