import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';

import { InteractiveDocumentInstanceAction } from '../models/interactiveDocumentInstanceAction';

@Injectable({ providedIn: 'root' })
export class InteractiveDocumentInstanceActionsService {
  private currentDocument: InteractiveDocumentInstanceAction = null;

  private API_URL = '/api/interactive-document-instance-actions/';

  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  public docInstanceActions = ['Created', 'Signature Requested', 'Viewed', 'Canceled'];

  constructor(
    private httpClient: HttpClient
  ) { }


  setCurrent(_document: InteractiveDocumentInstanceAction): void {
    this.currentDocument = _document;
  }


  getCurrent() {
    return this.currentDocument;
  }


  create(_document): Observable<InteractiveDocumentInstanceAction> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_document), this.headerOptions);
  }


  update(_document): Observable<InteractiveDocumentInstanceAction> {
    return this.httpClient.put<InteractiveDocumentInstanceAction>(this.API_URL + _document._id, JSON.stringify(_document), this.headerOptions);
  }


  search(searchTerms): Observable<InteractiveDocumentInstanceAction[]> {
    return this.httpClient.post<InteractiveDocumentInstanceAction[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getInstance(_id: string): Observable<InteractiveDocumentInstanceAction> {
    return this.httpClient.get<InteractiveDocumentInstanceAction>(this.API_URL + _id);
  }


  resolve(path, obj) {
    return path.split('.').reduce(function (prev, curr) {
      return prev ? prev[curr] : null
    }, obj || self)
  }


  addSourceValues(_fieldsToPull, _currentInstance, _relatedRecord) {
    return new Promise(async (resolve) => {
      try {
        console.log('Related Record: ', _relatedRecord);

        _fieldsToPull.forEach(_field => {
          console.log('Field -> name: ', _field);

          if (!_field.fieldName && _field && _field.name) _field.fieldName = _field.name;

          let lookupMapping = _field.fieldName;

          if (_field.fieldMapping) {
            const sourceString = _currentInstance.relatedModule.moduleType + '.';
            let _mappingSourceString = _field.fieldMapping.replace(sourceString, '');
            // console.log('_mappingSourceString: ', _mappingSourceString);
            lookupMapping = _mappingSourceString + '.' + _field.fieldName;
          }

          console.log('lookupMapping: ', lookupMapping);
          const mappingValue = this.resolve(lookupMapping, _relatedRecord);
          // const testValue = _relatedRecord[lookupMapping];
          console.log('Mapping Value: ', mappingValue);

          if (mappingValue) {
            _currentInstance.values[_field.fieldName] = mappingValue;
            _currentInstance.values[_field.systemFieldName] = mappingValue;
          } else if (_field.fromRelationship) {
            // make sure they don't come from the module record
            console.log('***** This comes from a relationship on the record passed in.');

            const relationshipRecord = _relatedRecord[_field.fromRelationship];
            console.log('Relationship Record: ', relationshipRecord);

            const recordValueFromModule = (relationshipRecord) ? relationshipRecord[_field.fieldName] : null;

            if (recordValueFromModule) {
              _currentInstance.values[_field.fieldName] = recordValueFromModule;
              _currentInstance.values[_field.systemFieldName] = recordValueFromModule;
            }
          } else if (_field.fromModule) {
            const recordValueFromModule = _relatedRecord[_field.fieldName];

            if (recordValueFromModule) {
              _currentInstance.values[_field.fieldName] = recordValueFromModule;
              _currentInstance.values[_field.systemFieldName] = recordValueFromModule;
            }
          } else {
            if (!_currentInstance.values[_field.systemFieldName]) _currentInstance.values[_field.systemFieldName] = null;
            if (!_currentInstance.values[_field.fieldName]) _currentInstance.values[_field.fieldName] = null;
          }
        });

        resolve(_currentInstance);
      } catch (_err) {
        console.log('Error adding source values: ', _err);
        resolve(_currentInstance);
      }
    });
  }


  logAction(_type, _docInstanceId, _desc, _userId) {
    return new Promise(async (resolve) => {
      const newAction = new InteractiveDocumentInstanceAction();
      newAction.type = _type;
      newAction.interactive_document_instance = _docInstanceId;
      newAction.description = _desc;
      newAction.user = _userId;

      const _action = await lastValueFrom(this.create(newAction));
      resolve(_action);
    });
  }
}