import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import * as moment from 'moment-mini-ts'

import { Observable } from 'rxjs/internal/Observable';

import { CalendarGroup } from '@app/models';


@Injectable({ providedIn: 'root' })
export class CalendarGroupService {
  private currentCalendarGroupBeingViewed: CalendarGroup;

  private API_URL = '/api/calendar-groups/';
  headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(
    private httpClient: HttpClient
  ) { }


  setCurrentCalendarGroupBeingViewed(_event: CalendarGroup): void {
    this.currentCalendarGroupBeingViewed = _event;
  }


  getCurrentCalendarGroupBeingViewed(): CalendarGroup {
    return this.currentCalendarGroupBeingViewed;
  }


  getAll(): Observable<CalendarGroup[]> {
    return this.httpClient.get<CalendarGroup[]>(this.API_URL);
  }


  create(_calGroup: CalendarGroup): Observable<CalendarGroup> {
    return this.httpClient.post<CalendarGroup>(this.API_URL, JSON.stringify(_calGroup), this.headerOptions);
  }


  update(_calGroup: CalendarGroup): Observable<CalendarGroup> {
    return this.httpClient.put<CalendarGroup>(this.API_URL + _calGroup._id, JSON.stringify(_calGroup), this.headerOptions);
  }


  search(searchTerms): Observable<CalendarGroup[]> {
    return this.httpClient.post<CalendarGroup[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  deleteGroup(id: string): Observable<any> {
    return this.httpClient.delete(this.API_URL + id);
  }

}
