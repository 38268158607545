import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { ScheduledJob } from '../models/scheduledJob';
import { ScheduledJobRuns } from '@app/models';

export interface RunsApi {
  items: ScheduledJobRuns[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class ScheduledJobService {
  private API_URL = '/api/scheduled-jobs/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  private currentScheduledJob: ScheduledJob;

  constructor(
    private httpClient: HttpClient
  ) { }

  setCurrent(_job: ScheduledJob): void {
    this.currentScheduledJob = _job;
  }

  getCurrent(): ScheduledJob {
    return this.currentScheduledJob;
  }


  get(_id: string): Observable<ScheduledJob> {
    return this.httpClient.get<ScheduledJob>(this.API_URL + _id);
  }

  getAll(): Observable<ScheduledJob[]> {
    return this.httpClient.get<ScheduledJob[]>(this.API_URL);
  }  

  create(_job): Observable<ScheduledJob> {
    return this.httpClient.post<ScheduledJob>(this.API_URL, JSON.stringify(_job), this.headerOptions);
  } 

  update(_job: ScheduledJob): Observable<ScheduledJob> {
    return this.httpClient.put<ScheduledJob>(`${this.API_URL}/${_job._id}`, JSON.stringify(_job), this.headerOptions);
  }  

  delete(id: string): Observable<ScheduledJob> {
    return this.httpClient.delete<ScheduledJob>(this.API_URL + id);
  }    

  search(searchTerms): Observable<ScheduledJob[]> {
    return this.httpClient.post<ScheduledJob[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }
  
  
  // scheduled runs api
  getInstances(_id: string): Observable<ScheduledJobRuns[]> {
    return this.httpClient.get<ScheduledJobRuns[]>(this.API_URL + 'job-instances/' + _id);
  } 
  
  searchInstances(searchTerms): Observable<ScheduledJobRuns[]> {
    return this.httpClient.post<ScheduledJobRuns[]>(this.API_URL + 'instances/search', JSON.stringify(searchTerms), this.headerOptions);
  }

  getCustomDatasource(searchParams, sortField = 'execute_time', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<RunsApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<RunsApi>(this.API_URL + '/job-instances/pagedSearch', JSON.stringify(searchParams), this.headerOptions); 
  }

}
