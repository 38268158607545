<div class="colored-bg-wrapper bg" [ngClass]="{'jump-splash-page': isJumpRoute}">
    <div class="colored-bg-container vertical-align">
        <form class="colored-bg-content full-width" novalidate>
            <div class="header-block">
                <h2 class="formTitle">{{ isJumpRoute ? 'Jumphire' : 'yeehro' }}</h2>
            </div>

            <div *ngIf="errorMessage">
                <p style="color: red;">{{ errorMessage }}</p>
            </div>

            <div class="main">
                <p>You have been logged out.</p>
            </div>

            <form novalidate (ngSubmit)="login()" *ngIf="loginAvailable">
                <div class="submit-wrapper">
                    <button type="submit" class="btn btn-primary btn-lg btn-block signin-btn">Login</button>
                    <!-- <button type="submit" mat-flat-button color="primary">Login</button> -->
                </div>
            </form>            
        </form>
    </div>


    <div class="floating-logo">
        <!-- <img src="/assets/images/yeeHRologo.png" width="200"> -->
        <img *ngIf="!isJumpRoute" src="/assets/images/yeeHRologo.png" width="200" alt="YeeHRo Logo" />
        <img *ngIf="isJumpRoute" src="/assets/images/jumpHirelogo.png" width="200" alt="JumpHire Logo" />
    </div>
</div>
