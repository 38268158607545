import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  private sidebarToggledObs$ = new Subject<any>();
  private sidebarStatus: boolean;

  listenForSidebarToggle() {
    return this.sidebarToggledObs$;
  }

  announceSidebarToggle(_status: boolean) {
    this.sidebarStatus = _status;
    this.sidebarToggledObs$.next(_status);
  }


  setSidebarStatus(_status: boolean): void {
    this.sidebarStatus = _status;
  }

  getSidebarStatus(): boolean {
    return this.sidebarStatus;
  }  
}
