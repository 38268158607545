<div class="address-verification-dialog-wrapper">
    <h1 mat-dialog-title class="text-center">Address Verification</h1>

    <!-- Select Address To Use Layout -->
    <div *ngIf="!selectedAddress" class="select-address-content">
        <div class="current-address-section addresses-section">

            <div class="section-title">You Entered</div>

            <div class="address-card current-address">
                <!-- <div class="address-card-title">You Entered</div> -->

                <div class="address-card-content">
                    <div class="address-line-1">{{ currentAddress?.street }}</div>
                    <div class="address-line-2">
                        <span *ngIf="currentAddress?.city">{{ currentAddress?.city }},</span>
                        <span *ngIf="currentAddress?.state">{{ currentAddress?.state }}</span>
                        <span *ngIf="currentAddress?.zip">{{ currentAddress?.zip }}</span>
                    </div>
                </div>

                <div class="address-card-btn-wrapper">
                    <button mat-stroked-button type="button" color="primary" class="address-card-btn" (click)="setActiveAddress(currentAddress)">Keep Original</button>
                </div>
            </div>
        </div>

        <div class="suggested-addresses-section addresses-section">
            <div class="section-title">More Addresses</div>

            <div class="suggested-addresses-list">
                <ng-container *ngIf="addressesSuggested?.length; else noSuggestions">
                    <div class="address-card suggested-address" *ngFor="let _address of addressesSuggested; let i=index">
                        <!-- <div class="address-card-title">Recommended</div> -->

                        <div class="address-card-content">
                            <div class="address-line-1">{{ _address?.street }}</div>
                            <div class="address-line-2">
                                <span *ngIf="_address?.city">{{ _address?.city }},</span>
                                <span *ngIf="_address?.state">{{ _address?.state }}</span>
                                <span *ngIf="_address?.zip">{{ _address?.zip }}</span>
                            </div>
                        </div>

                        <div class="address-card-btn-wrapper">
                            <button mat-flat-button type="button" color="primary" class="address-card-btn" (click)="setActiveAddress(_address)">Select</button>
                        </div>
                    </div>
                </ng-container>

                <ng-template #noSuggestions>
                    <div class="address-card no-content">No Addresses To List</div>
                </ng-template>
            </div>
        </div>
    </div>

    <!-- Confirmation Layout -->
    <div *ngIf="selectedAddress" class="confirm-address-content">
        <div class="confirm-addresses-section addresses-section">
            <div class="section-title">Use selected address for lead</div>

            <div class="address-card confirm-address-info">
                <!-- <div class="address-card-title">Use selected address for lead</div> -->

                <div class="address-card-content">
                    <div class="address-line-1">{{ selectedAddress?.street }}</div>
                    <div class="address-line-2">
                        <span *ngIf="selectedAddress?.city">{{ selectedAddress?.city }},</span>
                        <span *ngIf="selectedAddress?.state">{{ selectedAddress?.state }}</span>
                        <span *ngIf="selectedAddress?.zip">{{ selectedAddress?.zip }}</span>
                    </div>
                </div>

                <div class="address-card-btn-wrapper duel-btns">
                    <button mat-flat-button type="button" color="primary" class="address-card-btn" (click)="confirmSelection()">Confirm</button>
                    <button mat-stroked-button type="button" color="primary" class="address-card-btn" (click)="setActiveAddress(null)">Change</button>
                </div>
            </div>
        </div>
    </div>
</div>