import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Wallet } from '../models/wallet';

@Injectable({ providedIn: 'root' })
export class WalletService {
  private API_URL = '/api/wallets/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient
  ) { }


  get(_id: string): Observable<Wallet> {
    return this.httpClient.get<Wallet>(this.API_URL + _id);
  }


  getForUser(_userId: any): Observable<Wallet> {
    return this.httpClient.get<Wallet>(this.API_URL + 'for-user/' + _userId);
  }


  getAll(): Observable<Wallet[]> {
    return this.httpClient.get<Wallet[]>(this.API_URL);
  }


  create(_wallet): Observable<Wallet> {
    return this.httpClient.post<Wallet>(this.API_URL, JSON.stringify(_wallet), this.headerOptions);
  }


  update(_wallet: Wallet): Observable<Wallet> {
    return this.httpClient.put<Wallet>(`${this.API_URL}/${_wallet._id}`, JSON.stringify(_wallet), this.headerOptions);
  }


  delete(id: string): Observable<Wallet> {
    return this.httpClient.delete<Wallet>(this.API_URL + id);
  }
}