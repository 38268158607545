import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { ChartAttribute, ReportChart } from '../models/reportChart';

@Injectable({ providedIn: 'root' })
export class ReportChartService {
  private API_URL = '/api/report-charts/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  private currentReportChart: ReportChart;
  private chartChangeObs$ = new Subject<ReportChart>();

  // Probably Need to use a select instead
  chartTypes = [
    {chartType: 'horizontal-bar', label: 'Horizontal Bar', icon: 'equalizer', minimumGroupsNeeded: 1},
    {chartType: 'vertical-bar', label: 'Vertical Bar', icon: 'equalizer', minimumGroupsNeeded: 1},
    {chartType: 'stacked-horizontal-bar', label: 'Stacked Horizontal Bar', icon: 'equalizer', minimumGroupsNeeded: 2},
    {chartType: 'stacked-vertical-bar', label: 'Stacked Vertical Bar', icon: 'equalizer', minimumGroupsNeeded: 2},
    {chartType: 'grouped-horizontal-bar', label: 'Grouped Horizontal Bar', icon: 'equalizer', minimumGroupsNeeded: 2},
    {chartType: 'grouped-vertical-bar', label: 'Grouped Vertical Bar', icon: 'equalizer', minimumGroupsNeeded: 2},    
    {chartType: 'donut', label: 'Donut', icon: 'donut_large', minimumGroupsNeeded: 1},
    {chartType: 'pie', label: 'Pie', icon: 'donut_large', minimumGroupsNeeded: 1},
    {chartType: 'advanced-pie', label: 'Advanced Pie', icon: 'donut_large', minimumGroupsNeeded: 1},
    {chartType: 'pie-grid', label: 'Pie Grid', icon: 'donut_large', minimumGroupsNeeded: 1},
    {chartType: 'line', label: 'Line', icon: 'donut_large', minimumGroupsNeeded: 2},
    {chartType: 'gauge', label: 'Gauge', icon: 'donut_large', minimumGroupsNeeded: 1},
    {chartType: 'number', label: 'Number Cards', icon: 'donut_large', minimumGroupsNeeded: 1},
  ];

  constructor(
    private httpClient: HttpClient
  ) { }


  setCurrent(_reportChart: ReportChart): void {
    this.currentReportChart = _reportChart;
  }


  getCurrent(): ReportChart {
    return this.currentReportChart;
  }


  listenForReportChartsChange() {
    return this.chartChangeObs$;
  }


  announceReportChartsChange(_chart: ReportChart) {
      this.chartChangeObs$.next(_chart);
  }


  get(_id: string): Observable<ReportChart> {
    return this.httpClient.get<ReportChart>(this.API_URL + _id);
  }


  getAll(): Observable<ReportChart[]> {
    return this.httpClient.get<ReportChart[]>(this.API_URL);
  }


  create(_setting): Observable<ReportChart> {
    return this.httpClient.post<ReportChart>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
  }


  update(_setting: ReportChart): Observable<ReportChart> {
    return this.httpClient.put<ReportChart>(this.API_URL + _setting._id, JSON.stringify(_setting), this.headerOptions);
  }


  deleteByIdArray(arrayOfIds): Observable<any> {
    const deleteRequest = {ids: arrayOfIds};
    return this.httpClient.post<any>(this.API_URL + 'delete-by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  delete(id: string): Observable<ReportChart> {
    return this.httpClient.delete<ReportChart>(this.API_URL + id);
  }


  search(searchTerms): Observable<ReportChart[]> {
    return this.httpClient.post<ReportChart[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getRowGroupToUse(_rowGroups, _fieldToMatch: string, _returnFirstGroupIfNotFound: boolean): Promise<any> {
    return new Promise(async(resolve) => {
      let matchingRowGroup = null;

      // console.log('_rowGroups: ', _rowGroups);
      // console.log('_fieldToMatch: ', _fieldToMatch);
      // console.log('_returnFirstGroupIfNotFound: ', _returnFirstGroupIfNotFound);

      if (_rowGroups != undefined && _rowGroups.length > 0) {
        if (_fieldToMatch != undefined) matchingRowGroup = _rowGroups.find(_rg => _rg.fieldName === _fieldToMatch);
        else if (_returnFirstGroupIfNotFound) matchingRowGroup = _rowGroups[0];
      }

      // console.log('matchingRowGroup: ', matchingRowGroup);

      resolve(matchingRowGroup);
    });
  }


  buildAttributeForChart(_chartType, _rowGroups, _currentChartAttributes): Promise<ChartAttribute> {
    return new Promise(async(resolve) => {
      // console.log('_chartType: ', _chartType);
      // console.log('_rowGroups: ', _rowGroups);
      // console.log('_currentChartAttributes: ', _currentChartAttributes);

      // Get field to match in getRowGroupToUse function. If chart had groupBy set already
      const fieldToMatch = (_currentChartAttributes != undefined && _currentChartAttributes.dataGroupBy != undefined) ? _currentChartAttributes.dataGroupBy : null;
      const fieldNameToMatch = (fieldToMatch && fieldToMatch['fieldName']) ? fieldToMatch['fieldName'] : fieldToMatch;

      // Get row group to use for build. Pass row groups, field from above, and if we want the first item returned if not matched to field or field is null.
      const currentRowGroupToUse = await this.getRowGroupToUse(_rowGroups, fieldNameToMatch, true);

      // Build values for below with data from above
      const dataValueTypeToUse = (_currentChartAttributes != undefined && _currentChartAttributes.dataValueType != undefined) ? _currentChartAttributes.dataValueType : 'Total Count';
      const dataGroupByNameToUse = currentRowGroupToUse;
      const dataGroupByLabelToUse = (currentRowGroupToUse != undefined && currentRowGroupToUse.label != undefined) ? currentRowGroupToUse.label : '';

      // Build new chart attributes
      const newAttribute = new ChartAttribute();

      // For all types
      newAttribute.dataValueType = dataValueTypeToUse;
      newAttribute.dataGroupBy = dataGroupByNameToUse;
      newAttribute.allRowGroups = _rowGroups;
      newAttribute.showLegend = false;
      newAttribute.tooltipDisabled = false;
      newAttribute.gradient = false;
      newAttribute.legendPosition = 'right';

      // Change other data here
      if (_chartType === 'horizontal-bar') {
        // X axis is the horizontal axis dataValueType select
        // Y axis is the vertical axis dataGroupBy select
        newAttribute.showXAxis = true;
        newAttribute.showYAxis = true;
        newAttribute.xAxisLabel = dataValueTypeToUse;
        newAttribute.yAxisLabel = dataGroupByLabelToUse;
        newAttribute.showXAxisLabel = true;
        newAttribute.showYAxisLabel = true;
        newAttribute.trimXAxisTicks = false;
        newAttribute.trimYAxisTicks = false;
        newAttribute.showDataLabel = true;
        newAttribute.showGridLines = false;
      } else if (_chartType === 'vertical-bar') {
        // X axis is the vertical axis dataGroupBy select
        // Y axis is the horizontal axis dataValueType select
        newAttribute.showXAxis = true;
        newAttribute.showYAxis = true;
        newAttribute.xAxisLabel = dataGroupByLabelToUse;
        newAttribute.yAxisLabel = dataValueTypeToUse;
        newAttribute.showXAxisLabel = true;
        newAttribute.showYAxisLabel = true;
        newAttribute.trimXAxisTicks = false;
        newAttribute.trimYAxisTicks = false;
        newAttribute.showDataLabel = true;
        newAttribute.showGridLines = false;
      } else if (_chartType === 'donut') {
        newAttribute.isDoughnut = true;
        newAttribute.showLabels = true;
        newAttribute.arcWidth = 0.25;
      } else if (_chartType === 'pie') {
        newAttribute.isDoughnut = false;
        newAttribute.showLabels = true;
        newAttribute.arcWidth = 0.25;
      } else if (_chartType === 'line') {
        newAttribute.showGridLines = true;
        newAttribute.showLabels = true;
        newAttribute.timeline = false;
      }

      resolve(newAttribute);
    });
  }

}
