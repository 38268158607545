import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedUtilsService } from '@app/services';

@Component({
  selector: 'gmail-auth-code',
  templateUrl: './gmail-auth-code.component.html',
  styleUrls: ['./gmail-auth-code.component.scss']
})
export class GmailAuthCodeComponent implements OnInit {
  @ViewChild('authCodeDiv', {static: true}) authCodeDiv: ElementRef;

  authCode: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _sharedService: SharedUtilsService
  ) {
    this.activatedRoute.queryParams.subscribe(params => this.authCode = params['code']);
  }

  ngOnInit() {
    console.log('this.authCode: ', this.authCode);
  }

  showConfirmCopy() {
    this._sharedService.showNotification("Authentication Code Copied to Clipboard")
  }

}
