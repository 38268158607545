import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';
import { YeehroWidget } from '@app/models';

export interface YeehroWidgetApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class YeehroWidgetService {
    private currentYeehroWidget;

    public currentSearch = {
        params: null,
        results: null
    };

    private API_URL = '/api/yeehro-widgets/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient,
        private formBuilder: UntypedFormBuilder
    ) { }

    setCurrent(_yeehroWidget): void {
        this.currentYeehroWidget = _yeehroWidget;
    }


    getCurrent() {
        return this.currentYeehroWidget;
    }


    getAll(): Observable<YeehroWidget[]> {
        return this.httpClient.get<YeehroWidget[]>(this.API_URL);
    }


    get(_id: string): Observable<YeehroWidget> {
        return this.httpClient.get<YeehroWidget>(this.API_URL + _id);
    }


    getBySelector(_keySelector: string): Observable<YeehroWidget> {
        return this.httpClient.get<YeehroWidget>(this.API_URL + 'bySelector/' + _keySelector);
    }


    create(_yeehroWidget): Observable<YeehroWidget> {
        return this.httpClient.post<YeehroWidget>(this.API_URL, JSON.stringify(_yeehroWidget), this.headerOptions);
    }


    search(searchTerms): Observable<YeehroWidget[]> {
        return this.httpClient.post<YeehroWidget[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<YeehroWidgetApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<YeehroWidgetApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<YeehroWidgetApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<YeehroWidgetApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    update(_yeehroWidget: YeehroWidget): Observable<YeehroWidget> {
        return this.httpClient.put<YeehroWidget>(`${this.API_URL}${_yeehroWidget._id}`, JSON.stringify(_yeehroWidget), this.headerOptions);
    }


    delete(_id: string): Observable<YeehroWidget> {
        return this.httpClient.delete<YeehroWidget>(this.API_URL + _id);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    yeehroWidgetFormGroup(formData: YeehroWidget) {   
        return this.formBuilder.group({
            name: [formData.name, Validators.required],
            description: [formData.description],
            widgetIcon: [formData.widgetIcon],
            widgetIconType: [formData.widgetIconType],
            key_selector: [formData.key_selector, Validators.required],
            isMenu: [formData.isMenu]
        });
    }
}