import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dup-account-dialog',
  templateUrl: './dup-account-dialog.component.html'
})
export class DupAccountDialogComponent implements OnInit {
  items: any[];
  confirmationResult = false;

  displayedColumns: string[] = ['name', 'createdAt'];

  constructor(public dialogRef: MatDialogRef<DupAccountDialogComponent>) { }

  ngOnInit() {}


  confirmCreate(_doMerge) {
    // this.confirmationResult = true;
    this.closeDialog(_doMerge);
  }

  cancel() {
    // this.confirmationResult = false;
    this.closeDialog(null);
  }

  closeDialog(_doMerge) {
    return this.dialogRef.close(_doMerge);
  }

}
