import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouteGuardComponent } from './route-guard.component';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [RouteGuardComponent] 
})
export class RouteGuardModule { }
