export class InteractiveDocumentInstanceAction {
  _id: string;
  type: string;
  description: string;
  interactive_document_instance;
  recipientId: any;
  user: any;
  nonUser: any; // non logged in action. Could be name, email, etc.
  createdAt: Date;
  updatedAt: Date;
  hasSearchTerms: any;
}
