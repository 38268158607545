import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

import { PtoRequest, SsModule, User } from '@app/models';

import { ModuleHooksService } from './module-hooks.service';
import { PtoRequestService } from './pto-request.service';
import { SharedUtilsService } from './shared-utils.service';
import { SsModuleService } from './ss-module.service';

@Injectable({ providedIn: 'root' })
export class PtoRequestHelperService {

  constructor(
    private _moduleHooks: ModuleHooksService,
    private _moduleService: SsModuleService,
    private _ptoRequestService: PtoRequestService,
    private _sharedUtilsService: SharedUtilsService
  ) { }


  saveOrUpdateRequestWithHooks(_fullModulePassedIn: SsModule, _requestToSave: PtoRequest, _uneditedRecord: PtoRequest, _currentUser: User): Promise<any> {
    return new Promise(async(resolve) => {
      let modifiedRequest: PtoRequest = null;

      const uneditedRecord = {..._uneditedRecord};

      // This is causing issues saving. Converts the object id to a string in the database and suppresses relationships
      _requestToSave = await this.recordWithRelatesAsIds(_requestToSave);

      const moduleAfterPreModuleHook = await this._moduleHooks.preModuleSaveHook(_fullModulePassedIn, uneditedRecord, _requestToSave);

      if (moduleAfterPreModuleHook && !moduleAfterPreModuleHook['error']) {
        let currentRecord = null;
        _requestToSave = moduleAfterPreModuleHook as PtoRequest;

        if (_requestToSave && !_requestToSave.name) _requestToSave.name = 'View';

        if (_requestToSave._id) {
          // console.log('SHOULD BE AN UPDATE');
          _requestToSave['modified_by'] = _currentUser._id;
          currentRecord = await lastValueFrom(this._ptoRequestService.update(_requestToSave));
        } else {
          // console.log('No ID. Should be a create.');
          _requestToSave['created_by'] = _currentUser._id;
          _requestToSave['deleted'] = false;
          currentRecord = await lastValueFrom(this._ptoRequestService.create(_requestToSave));
          _requestToSave._id = (currentRecord._id) ? currentRecord._id : currentRecord.id;
        }

        _requestToSave = {...currentRecord};

        await this._moduleHooks.postSaveHook(_fullModulePassedIn, _requestToSave, null, uneditedRecord);
        await lastValueFrom(this._moduleService.removeRedisById(_fullModulePassedIn._id)); // Doing this to remove cache for this module

        modifiedRequest = _requestToSave;
      }

      resolve(modifiedRequest);
    });
  }


  recordWithRelatesAsIds(_currentRecord): Promise<any> {
    return new Promise(async(resolve) => {
      const newRecord = {..._currentRecord};

      Object.keys(newRecord).map(_key => {
        if (newRecord[_key] && newRecord[_key]._id) newRecord[_key] = newRecord[_key]._id;
      });

      resolve(newRecord);
    });
  }
}