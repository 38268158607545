import { Injectable } from '@angular/core';
import * as moment from 'moment-mini-ts';

@Injectable({ providedIn: 'root' })
export class DateUtilsService {
    // Options with null momentDateIntervalType and momentIntervalVal are used in special cases and will not use these values
    public defaultDateTypes = [
        {label: 'Yesterday', momentDateIntervalType: 'd', momentIntervalVal: -1},
        {label: 'Today', momentDateIntervalType: 'd', momentIntervalVal: 0},
        {label: 'Tomorrow', momentDateIntervalType: 'd', momentIntervalVal: 1},
        {label: 'Next Week', momentDateIntervalType: 'd', momentIntervalVal: 7},
        {label: 'Next Monday', momentDateIntervalType: null, momentIntervalVal: null},
        {label: 'Next Friday', momentDateIntervalType: null, momentIntervalVal: null},
        {label: 'Two Weeks', momentDateIntervalType: 'w', momentIntervalVal: 2},
        {label: 'Next Month', momentDateIntervalType: 'M', momentIntervalVal: 1},
        {label: 'First Day of Next Month', momentDateIntervalType: null, momentIntervalVal: null},
        {label: 'Three Months', momentDateIntervalType: 'M', momentIntervalVal: 3},
        {label: 'Six Months', momentDateIntervalType: 'M', momentIntervalVal: 6},
        {label: 'Next Year', momentDateIntervalType: 'y', momentIntervalVal: 1},
        {label: 'Last Week', momentDateIntervalType: 'd', momentIntervalVal: -7},
        {label: 'This Month', value: 'this_month'},
        {label: 'Last Month', value: 'last_month'},
    ];

    constructor() {}


    // Pass one of the defaultDateTypes options in and get the default date
    getDefaultDateToUse(_dateTypeOption: any) {
        // console.log('_dateTypeOption: ', _dateTypeOption);
        let returnDate: Date = null;
        const today = moment();

        if (_dateTypeOption != undefined) {
            let dateTypeLabel = (_dateTypeOption.label != undefined) ? _dateTypeOption.label : null;

            if (dateTypeLabel) {
                let _defaultTimeType = (_dateTypeOption.momentDateIntervalType != undefined) ? _dateTypeOption.momentDateIntervalType : null;
                let _defaultTimeValue: number = (_dateTypeOption.momentIntervalVal != undefined) ? _dateTypeOption.momentIntervalVal : 0;

                // Special Cases
                if (dateTypeLabel === 'Next Monday' || dateTypeLabel === 'Next Friday') {
                    const dayOfWeekNeeded = (dateTypeLabel === 'Next Monday') ? 1 : 5; // 1 is Monday and 5 is Friday
                    returnDate = today.add(1, 'weeks').isoWeekday(dayOfWeekNeeded).toDate(); // otherwise, give me *next week's* instance of that same day
                } else if (dateTypeLabel === 'First Day of Next Month') {
                    returnDate = today.add(1, 'M').startOf('month').toDate();
                } else {
                    // Default Case, if a _defaultTimeType was passed use moment to get Date, else return null because we don't have data needed
                    if (_defaultTimeType) returnDate = today.add(_defaultTimeValue, _defaultTimeType).toDate();
                    else returnDate = null;
                }
            }
        }

        // console.log('Date being returned: ', returnDate);
        return returnDate;
    }
}