<div class="achievement-outer-wrapper" (click)="dismissNotification()">
    <confetti-animation [confettiDuration]="data?.display_time"></confetti-animation>>

    <div class="achievement" (click)="dismissNotification()">
        <div class="animation">
            <div class="circle">
                <div class="img trophy_animate trophy_img">
                    <img class="trophy_1" src="/assets/images/svgs/animated_full_trophy.svg" />
                    <img class="trophy_2" src="/assets/images/svgs/no_handles_trophy.svg" />
                </div>

                <div class="img xbox_img">
                    <img src="/assets/images/eddie_apple_cutout.png" />
                </div>

                <div class="img achv-img">
                    <img [src]="data.badge_url" />
                </div>
                
                <div class="brilliant-wrap">
                    <div class="brilliant"></div>
                </div>
            </div>
            <div class="banner-outer">
                <div class="banner">
                    <div class="achieve_disp">
                        <span class="unlocked">{{ (data?.rare) ? 'Rare achievement unlocked' : 'Achievement unlocked' }}</span>

                        <div class="score_disp">
                            <div class="gamerscore">
                                <!-- <img width="20px" src="https://dl.dropboxusercontent.com/s/gdqf5amvjkx9rfb/G.svg?dl=0" /> -->
                                <img src="/assets/images/svgs/small_g_icon.svg" />
                                <span *ngIf="data?.score" class="acheive_score">{{ data.score }}</span>
                            </div>
                            <span class="hyphen_sep">-</span>
                            <span class="achiev_name">{{ (data?.title) ? data.title : 'New Achievement' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>