import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Course } from '../models/course';

export interface CourseApi {
  items: Course[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class CourseService {
  private currentCourseBeingViewed: Course;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/courses/';

  headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  public categoryList = [
    'Allergies', 'Asthma', 'Catheterizations', 'Company Policies/Procedures', 'Covid', 'Diabetes', 'District Policies/Procedures', 'Emergency Response',
    'G-Tubes', 'Infectious Diseases', 'Mobility', 'Seizures', 'Service Logs', 'Tracheotomy'
  ];

  public typeList = ['Document', 'Video', 'Article'];

  constructor(
    private httpClient: HttpClient
  ) { }


  setCurrentCourseBeingViewed(_course: Course): void {
    this.currentCourseBeingViewed = _course;
  }


  getCurrentCourseBeingViewed(): Course {
    return this.currentCourseBeingViewed;
  }


  getAll(): Observable<Course[]> {
    return this.httpClient.get<Course[]>(this.API_URL);
  }


  getCourseById(id: string): Observable<Course> {
    return this.httpClient.get<Course>(this.API_URL + id);
  }


  getNextAvailableCourseId(): Observable<Object> {
    return this.httpClient.get<Object>(this.API_URL + 'get-next-available/courseId');
  }


  update(course: Course): Observable<Course> {
    return this.httpClient.put<Course>(this.API_URL + course._id, JSON.stringify(course), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<Course[]> {
    return this.httpClient.put<Course[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  updateCourseFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<Course[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<Course[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  deleteByIdArray(arrayOfIds): Observable<any[]> {
    const deleteRequest = {ids: arrayOfIds};
    return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  create(course: Course): Observable<Course> {
    return this.httpClient.post<Course>(this.API_URL, JSON.stringify(course), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  doCourseSearch(searchTerms): Observable<Course[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<Course[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCourseCustomDatasource(searchParams, sortField = 'title', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<CourseApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<CourseApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }
}