<div class="container confirm-action-dialog">
  <h1 mat-dialog-title class="text-center">Duplicate Leads Detected</h1>

  <p>The following appear to match the lead you are creating.</p>

  <div class="row" *ngIf="items && items.length">
    <table class="full-width" mat-table [dataSource]="items">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let _lead"> {{ _lead.first_name + ' ' + _lead.last_name }} </td>
      </ng-container>

      <ng-container matColumnDef="profession">
        <th mat-header-cell *matHeaderCellDef> Profession </th>
        <td mat-cell *matCellDef="let _lead"> {{ _lead.profession }} </td>
      </ng-container>
    
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef> Address </th>
        <td mat-cell *matCellDef="let _lead"> {{ _lead.current_address?.city + ', ' + _lead.current_address?.state }} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

<!--   <div class="row">
    <mat-dialog-actions class="two-button-full-width-flex">
      <button mat-stroked-button mat-dialog-close (click)="cancel()">Do Not Create</button>
      <button mat-raised-button color="primary" (click)="confirmCreate(false)">Create</button>
    </mat-dialog-actions>
  </div> -->

  <div class="row">
    <mat-dialog-actions class="two-button-full-width-flex">
      <!-- <button mat-stroked-button mat-dialog-close (click)="cancel()">Do Not Create</button> -->
      <button mat-raised-button color="primary" (click)="confirmCreate(false)">Save</button>
      <button mat-raised-button color="primary" (click)="confirmCreate(true)">Review and Merge</button>
    </mat-dialog-actions>
  </div>
</div>