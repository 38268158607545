import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { SidebarMenuTemplate } from '@app/models';

export interface MenuTemplatesApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class SidebarMenuTemplatesService {
  private currentTemplate;

  private API_URL = '/api/sidebar-menu-templates/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient
  ) { }

  setCurrent(_template): void {
    this.currentTemplate = _template;
  }

  getCurrent() {
    return this.currentTemplate;
  }


  get(_id: string): Observable<SidebarMenuTemplate> {
    return this.httpClient.get<SidebarMenuTemplate>(this.API_URL + _id);
  }  

  getAll(): Observable<SidebarMenuTemplate[]> {
    return this.httpClient.get<SidebarMenuTemplate[]>(this.API_URL);
  }  

  create(_setting): Observable<SidebarMenuTemplate> {
    return this.httpClient.post<SidebarMenuTemplate>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
  } 

  update(_campaign: SidebarMenuTemplate): Observable<SidebarMenuTemplate> {
    return this.httpClient.put<SidebarMenuTemplate>(`${this.API_URL}${_campaign._id}`, JSON.stringify(_campaign), this.headerOptions);
  }  

  delete(id: string): Observable<any> {
    return this.httpClient.delete<any>(this.API_URL + '/' + id);
  }  

  getDataSource(_moduleOptions, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<MenuTemplatesApi> {
    _moduleOptions.searchTerms['selectAll'] = false;
    _moduleOptions.searchTerms.sortField = sortField;
    _moduleOptions.searchTerms.sortOrder = sortDirection;
    _moduleOptions.searchTerms.pageNumber = pageNumber;
    _moduleOptions.searchTerms.pageSize = pageSize; 

    return this.httpClient.post<MenuTemplatesApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions); 
  }

  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }
}