import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { SelectionModel } from '@angular/cdk/collections';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

@Injectable({ providedIn: 'root' })
export class TableService {
  public selectedRows: SelectionModel<any>;
  public allSelected = false;
  public dataLength = 0;
  public selectedData;
  currentSavedOptions;

  private optionSaver = {
    searchTerms: {},
    pageNumber: 0,
    pageSize: 10,
    tableColumns: []
  };

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    this.currentSavedOptions = this.optionSaver;
  }


  resetSelectedRows() {
    this.allSelected = false;
    this.selectedRows = new SelectionModel<any>(true, []);
  }


  async selectAll(_searchTerms, searchFunc) {
    this.allSelected = true;

    const _results: any[] = await lastValueFrom(searchFunc) || [];
    // console.log('_results: ', _results);

    _results.forEach(row => this.selectedRows.select(row))
  }


  setResults(_results) {
    if (!this.selectedRows) this.resetSelectedRows();

    if (_results && _results.length) _results.forEach(row => this.selectedRows.select(row));
  }


  deselectAll() {
    this.selectedRows.clear();
  }


  getSelectedItems() {
    return this.selectedRows.selected;
  }


  /** Selects all rows if they are not all selected; otherwise clear selectedRows. */
  masterToggle() {
    this.isAllSelected() ? this.selectedRows.clear() : this.selectedData.forEach(row => this.selectedRows.select(row));
  }


  /**
   * Determines whether a value is selected.
   */
  isItemSelected(itemId) {
    let found = false;

    if (this.selectedRows && this.selectedRows.selected) {
      for (let i = 0; i < this.selectedRows.selected.length; i++) {
        if (this.selectedRows.selected[i]._id === itemId) {
          found = true;
          break;
        }
      }
    }

    return found;
  }


  toggleItem(item) {
    if (!this.selectedRows) {
      console.log('Please set selected rows to something...');
      return;
    }

    const index = this.selectedRows.selected.indexOf(this.selectedRows.selected.find(s => s._id == item._id));

    if (index !== -1) {
      this.selectedRows.selected.splice(index, 1);
    } else {
      this.selectedRows.selected.push(item);
    }
  }


  // hasSubArray(master, sub) {
  //   let containsAllColumns = true;

  //   sub.forEach(col => {
  //     if (!master.includes(col)) {
  //       containsAllColumns = false;
  //       return containsAllColumns;
  //     }
  //   })

  //   return containsAllColumns;
  // }


  hasSubArray(allColumns, tableColumns) {
    let containsAllColumns = true;

    const allColsAsStrings: string[] = allColumns.map(col => col.field);

    tableColumns.forEach(col => {
      if (col !== 'select' && col !== 'view' && !allColsAsStrings.includes(col)) {
        containsAllColumns = false;
        return containsAllColumns;
      }
    })

    return containsAllColumns;
  }


  addMissingDisplayedColumns(displayedColumns, tableColumns) {
    const initDisplayedColumns = (displayedColumns && displayedColumns.length) ? [...displayedColumns] : [];

    for (let index = 0; index < initDisplayedColumns.length; index++) {
      const col = initDisplayedColumns[index];

      // If it is a field column and not the passed tableColumns add it at the correct index.
      if (col !== 'select' && col !== 'view' && !tableColumns.includes(col)) {
        tableColumns.splice(index, 0, col)
      }
    }

    return tableColumns;
  }


  hasSelectedItems() {
    return (this.selectedRows && this.selectedRows.selected) ? this.selectedRows.selected.length : 0;
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    return (this.selectedRows) ? this.selectedRows.selected.length === this.dataLength : false;
  }


  saveSearchTermsInLocal(key, val): void {
    try {
      const termsFromStorage = this.storage.get(key);
      const currentlyStoredTableOptions = termsFromStorage ? JSON.parse(this.storage.get(key)) : null;

      this.currentSavedOptions = currentlyStoredTableOptions ? currentlyStoredTableOptions : this.optionSaver;

      this.currentSavedOptions.searchTerms = val;
      this.storage.set(key, JSON.stringify(this.currentSavedOptions));
    } catch (_err) {
      console.log('ERROR saving search terms in local storage: ');
      console.log("key: ", key);
      console.log("val: ", val);
    }
  }


  getSearchTermsFromLocal(key) {
    let termsFound = null;

    try {
      if (this.storage.get(key)) {
        const currentSavedOptions = JSON.parse(this.storage.get(key));

        if (currentSavedOptions && currentSavedOptions.searchTerms) {
          termsFound = currentSavedOptions.searchTerms;
        }
      }
    } catch (_err) {
      console.log('Error getting search terms from local storage: ', _err);
    }

    return termsFound;
  }


  saveTableOptionsInLocal(key, pageNumber, pageSize, displayedColumns = null) {
    const termsFromStorage = this.storage.get(key);
    const currentlyStoredTableOptions = termsFromStorage ? JSON.parse(this.storage.get(key)) : null;

    if (currentlyStoredTableOptions) {
      this.currentSavedOptions = currentlyStoredTableOptions
    } else {
      this.currentSavedOptions = this.optionSaver;
    }

    this.currentSavedOptions.pageNumber = pageNumber;
    this.currentSavedOptions.pageSize = pageSize;

    if (displayedColumns) {
      this.currentSavedOptions.tableColumns = displayedColumns;
    }

    this.storage.set(key, JSON.stringify(this.currentSavedOptions));
  }


  getTableOptionsFromLocal(key) {
    if (this.storage.get(key)) {
      this.currentSavedOptions = JSON.parse(this.storage.get(key));
    }

    return this.currentSavedOptions;
  }


  toggleCustomColumn(_field, _displayedColumns, _allColumns) {
    // console.log('Field: ', _field);
    // console.log('_displayedColumns: ', _displayedColumns);
    // console.log('_allColumns: ', _allColumns);

    if (_displayedColumns.includes(_field)) {
      // want to hide this column. Remove it from displayed columns array
      _displayedColumns.splice(_displayedColumns.indexOf(_field), 1);
    } else {
      // not being shown, select it
      let insertAt = _allColumns.findIndex(_ac => _ac.fieldName === _field);

      if (insertAt >= _displayedColumns.length) {
        // always want the view column to be last
        insertAt = _displayedColumns.length - 1;
      }

      _displayedColumns.splice(insertAt, 0, _field);
    }
  }


  // Used for optional columns
  toggleColumn(_field, _displayedColumns, _allColumns) {
    // console.log('_displayedColumns: ', _displayedColumns);
    // console.log('_allColumns: ', _allColumns);

    if (_displayedColumns.includes(_field)) {
      // want to hide this column. Remove it from displayed columns array
      _displayedColumns.splice(_displayedColumns.indexOf(_field), 1);
    } else {
      // not being shown, select it
      let insertAt = _allColumns.indexOf(_field);

      if (insertAt >= _displayedColumns.length) {
        // always want the view column to be last
        insertAt = _displayedColumns.length - 1;
      }

      _displayedColumns.splice(insertAt, 0, _field);
    }
  }
}