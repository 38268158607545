export class AchievementNotificationPopupData {
    title: string; // Name of achievement.
    score: number; // If there is a score for this achievement.
    rare: boolean; // If it is a rare achievement.
    mute_audio: boolean; // If sound should be played.
    display_time: number; // Used for snackbar and timeout timing, don't need to set this anywhere else because it will not be used anyways.
    badge_url: string;

    constructor(public _title: string = '', public _score: number = 0, public _rare: boolean = false, public _mute_audio: boolean = false, public _badge_url: string = '') {
        this.title = _title;
        this.score = _score;
        this.rare = _rare;
        this.mute_audio = _mute_audio;
        this._badge_url = _badge_url;
    }
}