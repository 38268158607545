export class Message {
    _id: string;
    name: string;
    client;
    campaign: any;
    gmailId: string;
    gmailAddress: string;
    messageType: string;
    direction: string;
    sentTo: string;
    sentFrom: string;
    to: any;
    cc: any;
    bcc: any;
    subject: string;
    message: string;
    status: string;
    createdAt: Date;
    updatedAt: Date;
    dateReceived: Date; 
    created_by: any;
    user: any;
    marked: boolean;
    deleted: boolean;
    folder: string;
    isSendGrid: boolean;
    template;
    attachments;
    documents;
    anyTerm;
    hasSearchTerms;
    dateCreatedRange: any;
    dateCreated;
    dateCreatedEnd;
    sentFromName;
}