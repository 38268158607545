import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { Commission } from '@app/models';

export interface CommissionApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class CommissionService {
    private API_URL = '/api/commissions/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;

    public currentSearch = {
        params: null,
        results: null
    };

    constructor(
        private httpClient: HttpClient
    ) { }


    setCurrent(_commission: Commission): void {
        this.currentBeingViewed = _commission;
    }


    getCurrent(): Commission {
        return this.currentBeingViewed;
    }


    get(_id: string): Observable<Commission> {
        return this.httpClient.get<Commission>(this.API_URL + _id);
    }


    getAll(): Observable<Commission[]> {
        return this.httpClient.get<Commission[]>(this.API_URL);
    }


    create(_commission): Observable<Commission> {
        return this.httpClient.post<Commission>(this.API_URL, JSON.stringify(_commission), this.headerOptions);
    }


    triggerCommissionGenerationForDate(_commissionDateReq): Observable<Commission> {
        return this.httpClient.post<Commission>(this.API_URL + 'generate-commissions-for-date/', JSON.stringify(_commissionDateReq), this.headerOptions);
    }


    triggerCommissionGenerationForTimesheetTracker(_timesheetTracker): Observable<Commission> {
        return this.httpClient.post<Commission>(this.API_URL + 'generate-commission-for-tracker', JSON.stringify(_timesheetTracker), this.headerOptions);
    }


    update(_commission: Commission): Observable<Commission> {
        return this.httpClient.put<Commission>(`${this.API_URL}/${_commission._id}`, JSON.stringify(_commission), this.headerOptions);
    }


    markDeleted(id: string): Observable<any> {
        return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
    }


    delete(id: string): Observable<Commission> {
        return this.httpClient.delete<Commission>(this.API_URL + id);
    }


    search(searchTerms): Observable<Commission[]> {
        return this.httpClient.post<Commission[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    searchForOne(searchTerms): Observable<Commission> {
        return this.httpClient.post<Commission>(this.API_URL + 'search-for-one', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchOptions): Observable<Commission[]> {
        searchOptions['selectAll'] = true;
        searchOptions['selectNumberOfRecords'] = false;
        return this.httpClient.post<Commission[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchOptions), this.headerOptions);
    }


    selectNumberOfRecordsSearch(searchParams): Observable<any> {
        searchParams['selectAll'] = false;
        searchParams['selectNumberOfRecords'] = true;
        return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<CommissionApi> {
        searchParams['selectAll'] = false;
        searchParams['selectNumberOfRecords'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<CommissionApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }
}