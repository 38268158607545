import { GameInstance } from "./gameInstance";
import { ArcadeData } from "./arcadeData";

export class GameItemBucket {
    bucket_id: string; // Used to identify the bucket.These are not stored in the database.
    title: string;
    sub_title: string;
    category: string;
    imageForGame: string;
    credits: number;
    gameCompleted: boolean;
    completionDate: Date;
    instanceRef: GameInstance;
    freePlayPath: string;
    arcadeData: ArcadeData;
    gameInstructions: string;
}