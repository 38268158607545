import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { timer } from 'rxjs/internal/observable/timer';

import * as moment from 'moment-mini-ts';

import { ConfirmActionDialogComponent } from '@app/modules/dialogs/confirm-action-dialog/confirm-action-dialog.component';
import { MassUpdateDialogComponent } from '@app/modules/module-tools/mass-update-dialog/mass-update-dialog.component';

import { SsModuleService } from './ss-module.service';
import { SharedUtilsService } from './shared-utils.service';
import { SsModule } from '@app/models';
import { TableService } from '.';
import { ActionProgressDialogComponent } from '@app/modules/dialogs/action-progress-dialog/action-progress-dialog.component';
import { MessageService } from './message.service';

export interface GenericApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class GenericModuleService {
  private API_URL = '/api/generic-modules/';

  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  private currentModuleRecord;
  private recordChosenObs$ = new Subject<any>();

  public globalTeam = null;

  public currentSearch = {
    params: null,
    results: null
  };

  constructor(
    private _dialog: MatDialog,
    private _tableService: TableService,
    private httpClient: HttpClient,
    private _messageService: MessageService,
    private confirmActionDialog: MatDialog,
    private _sharedUtilsService: SharedUtilsService,
    private _ssModuleService: SsModuleService
  ) { }


  listenForChosenRecord() {
    return this.recordChosenObs$;
  }

  announceChosenRecord(_record: any) {
    this.recordChosenObs$.next(_record);
  }


  setCurrentRecord(_module): void {
    this.currentModuleRecord = _module;
  }


  getCurrent() {
    return this.currentModuleRecord;
  }


  getGlobalTeam() {
    return new Promise(async (resolve) => {
      if (!this.globalTeam) {
        const teamModule = await this._ssModuleService.waitForModule('teams');

        const genericSearchData = {
          name: 'teams',
          schema: teamModule.customSchema,
          searchTerms: { name: 'Global' },
          relationshipsNeeded: null
        };

        const teams = await lastValueFrom(this.search(genericSearchData));
        this.globalTeam = teams?.find(_t => _t?.name === 'Global');
      }

      // console.log('Global Team: ', this.globalTeam);

      resolve(this.globalTeam);
    });
  }


  getTeamsForSearch(_currentUser): Promise<any[]> {
    return new Promise(async (resolve) => {
      let teamsToSearch = [];

      // console.log('Getting teams for user: ', _currentUser);

      // User had no teams set or was empty
      if (!_currentUser.teams || _currentUser.teams === undefined || !_currentUser.teams.length) {
        const globalTeam = await this.getGlobalTeam();
        teamsToSearch = (globalTeam && globalTeam !== undefined) ? [globalTeam['_id']] : [];
      } else {
        // teamsToSearch = _currentUser.teams.map(_t => _t._id ? _t._id : _t);
        teamsToSearch = _currentUser.teams; // .map(_t => _t._id ? _t._id : _t);
      }

      resolve(teamsToSearch);
    });
  }


  get(_moduleOptions): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'by-id/' + _moduleOptions.searchTerms._id, JSON.stringify(_moduleOptions), this.headerOptions);
  }


  getWithRelationships(_moduleOptions): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'with-relationships/' + _moduleOptions.searchTerms._id, JSON.stringify(_moduleOptions), this.headerOptions);
  }


  getAll(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.API_URL);
  }


  create(_moduleRecordAndSchema): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_moduleRecordAndSchema), this.headerOptions);
  }


  doSelectAll(_searchFunc = null, _recordCount: number = null) {
    return new Promise(async (resolve) => {
      const dataLengthToUse: number = (_recordCount != undefined) ? _recordCount : this._tableService.dataLength;

      const progressRef = this._dialog.open(ActionProgressDialogComponent, { width: '90%', disableClose: false });
      const progressInstance = progressRef.componentInstance;

      progressInstance.title = 'Gathering data on selected records';

      progressInstance.timingStatus = {
        percentComplete: 0,
        currentPerc: 1,
        fullAmount: dataLengthToUse
      };

      const ti = timer(500, 500);

      const timerSub = ti.subscribe(() => {
        if (progressInstance.timingStatus.currentPerc < (dataLengthToUse - 1200)) progressInstance.timingStatus.currentPerc += 1200;
        else if (progressInstance.timingStatus.currentPerc < dataLengthToUse) progressInstance.timingStatus.currentPerc += (dataLengthToUse - progressInstance.timingStatus.currentPerc);
        progressInstance.timingStatus.percentComplete = (progressInstance.timingStatus.currentPerc / dataLengthToUse * 100);
      });

      const selectAllResults = await lastValueFrom(_searchFunc);
      timerSub.unsubscribe();
      progressInstance.closeDialog();

      // console.log('selectAllResults: ', selectAllResults);

      const selectedRecords = (selectAllResults && selectAllResults['message']) ? selectAllResults['message'] : selectAllResults;

      if (selectedRecords && selectedRecords.length) {
        // console.log('selectAllRecords: ', selectedRecords);
        await this._tableService.setResults(selectedRecords);
      } else {
        console.log('No selected records detected.');
      }

      resolve(null);
    });
  }


  handleMassUpdate(_module: SsModule, _userIdUpdating) {
    // teams getting messed up when doing mass update

    return new Promise(async (resolve) => {
      // console.log('Performing mass update for module: ', _module);

      const dialogRef = this._dialog.open(MassUpdateDialogComponent, { width: '90%', disableClose: true });
      const instance = dialogRef.componentInstance;

      const massUpdateInfo = {
        moduleName: _module.label
      };

      // console.log('this._tableService.selectedRows: ', this._tableService.selectedRows);

      instance.updateInfo = massUpdateInfo;
      instance.fullModule = _module;
      instance.recordIds = this._tableService.selectedRows.selected.map(_item => _item._id);

      const _massUpdateResponse = await lastValueFrom(dialogRef.afterClosed());

      // progress dialog

      const progressRef = this._dialog.open(ActionProgressDialogComponent, { width: '90%', disableClose: false });
      const progressInstance = progressRef.componentInstance;

      progressInstance.title = 'Updating and Creating Change Log Entries on chosen records';

      progressInstance.timingStatus = {
        percentComplete: 0,
        currentPerc: 1,
        fullAmount: this._tableService.selectedRows.selected.length
      };

      if (_massUpdateResponse && _massUpdateResponse.performAction === true) {
        const changesMade = _massUpdateResponse.changesMade;
        const fieldsToUpdate = { updatedAt: new Date() };

        for (let property in changesMade) {
          if (changesMade.hasOwnProperty(property) && (changesMade[property] || changesMade[property] === false)) fieldsToUpdate[property] = changesMade[property];
        }

        const moduleOptions = { name: _module.name, schema: _module.customSchema };
        // console.log('Module Options: ', moduleOptions);

        // send this up in chunks, reporting progress on the way

        let importedRecords = 0;
        const chunkedUpdateData = [];
        let chunk = 2000; //upload in chunks of 2000

        for (let i = 0, recordsLength = _massUpdateResponse.recordIds.length; i < recordsLength; i += chunk) {
          chunkedUpdateData.push(_massUpdateResponse.recordIds.slice(i, i + chunk));
        }

        const massUpdatePromises = chunkedUpdateData.map(async (_updateDataChunk, _chunkIndex) => {
          await lastValueFrom(this.updateFieldsByIdArray(moduleOptions, _updateDataChunk, fieldsToUpdate, _userIdUpdating));

          if (_module.name === 'activities') {
            await lastValueFrom(this._messageService.updateFieldsByIdArray(_updateDataChunk, fieldsToUpdate));
          }

          importedRecords += _updateDataChunk.length;

          progressInstance.timingStatus.currentPerc = importedRecords;
          progressInstance.timingStatus.percentComplete = (progressInstance.timingStatus.currentPerc / this._tableService.dataLength * 100);
        });

        // wait for all relationships to be processed.
        return Promise.all(massUpdatePromises).then(() => {
          this._sharedUtilsService.showMintNotificationWithOptions("Mass Update Complete");
          progressInstance.closeDialog();
          resolve(null);
        });
      } else {
        progressInstance.closeDialog();
        resolve(null);
      }
    });
  }


  updateFieldsByIdArray(_moduleOptions, arrayOfIds, fieldsToUpdate, _userUpdating = null): Observable<any[]> {
    const updateRequest = { moduleOptions: _moduleOptions, ids: arrayOfIds, fields: fieldsToUpdate, user: _userUpdating };
    return this.httpClient.post<any>(`${this.API_URL}update-certain-fields-array/`, JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_moduleOptions, fieldsToUpdate): Observable<any[]> {
    const updateRequest = { moduleOptions: _moduleOptions, fields: fieldsToUpdate };
    return this.httpClient.put<any>(`${this.API_URL}update-certain-fields/${_moduleOptions.record._id}`, JSON.stringify(updateRequest), this.headerOptions);
  }


  update(_moduleOptions): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _moduleOptions.record._id, JSON.stringify(_moduleOptions), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  // does AI Search
  smartSearch(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'smart-search', JSON.stringify(searchTerms), this.headerOptions);
  }

  smartSearchUpdated(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'smart-search-updated', JSON.stringify(searchTerms), this.headerOptions);
  }


  filteredSmartSearch(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'filtered-smart-search', JSON.stringify(searchTerms), this.headerOptions);
  }

  // does AI Search and populates relationships
  smartSearchWithPoplulate(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'smart-search-with-populate', JSON.stringify(searchTerms), this.headerOptions);
  }


  // used for reporting with aggregate when necessary
  reportSearchWithAggregateSwitch(searchTerms): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'report-search-aggregate-check', JSON.stringify(searchTerms), this.headerOptions);
  }


  markDeletedByIdArray(_moduleOptions, arrayOfIds): Observable<any[]> {
    const updateRequest = { moduleOptions: _moduleOptions, ids: arrayOfIds };
    return this.httpClient.put<any>(`${this.API_URL}/custom/mark-deleted/by-array`, JSON.stringify(updateRequest), this.headerOptions);
  }


  permanentlyDeleteByIdArray(_moduleOptions, arrayOfIds): Observable<any[]> {
    const updateRequest = { moduleOptions: _moduleOptions, ids: arrayOfIds };
    return this.httpClient.put<any>(`${this.API_URL}/custom/delete-forever/by-array`, JSON.stringify(updateRequest), this.headerOptions);
  }


  delete(_moduleOptions): Observable<any> {
    return this.httpClient.put<any>(`${this.API_URL}/mark-deleted/${_moduleOptions.record._id}`, JSON.stringify(_moduleOptions), this.headerOptions);
  }


  selectAllSearch(_moduleOptions): Observable<any> {
    _moduleOptions.searchTerms['selectAll'] = true;
    _moduleOptions.searchTerms['selectNumberOfRecords'] = false;
    _moduleOptions.searchTerms['idsOnly'] = false;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions);
  }


  selectNumberOfRecordsSearch(_moduleOptions): Observable<any> {
    _moduleOptions.searchTerms['selectAll'] = false;
    _moduleOptions.searchTerms['selectNumberOfRecords'] = true;
    _moduleOptions.searchTerms['idsOnly'] = false;

    if (_moduleOptions['idsOnly']) delete _moduleOptions['idsOnly']; // The base idsOnly is for selectAll so remove it

    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions);
  }


  getDataSource(_moduleOptions, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<GenericApi> {
    // console.log('_moduleOptions: ', _moduleOptions);

    _moduleOptions.searchTerms['selectAll'] = false;
    _moduleOptions.searchTerms['selectNumberOfRecords'] = false;
    _moduleOptions.searchTerms['idsOnly'] = false;

    if (_moduleOptions['idsOnly']) delete _moduleOptions['idsOnly']; // The base idsOnly is for selectAll so remove it

    _moduleOptions.searchTerms.sortField = sortField;
    _moduleOptions.searchTerms.sortOrder = sortDirection;
    _moduleOptions.searchTerms.pageNumber = pageNumber;
    _moduleOptions.searchTerms.pageSize = pageSize;

    // console.log('_moduleOptions GOING OUT: ', _moduleOptions);

    return this.httpClient.post<GenericApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions);
  }


  getRelateField(_primary_module, _relationship) {
    // console.log('Getting relate field for: ', _primary_module);
    // console.log('Relationship: ', _relationship);

    const fieldName = (_relationship.primary_module.name !== _primary_module) ? _relationship.primary_module.name : _relationship.related_module.name;
    return fieldName;
  }


  getNameForModule(fullModule, moduleRecord) {
    if (fullModule) {
      // console.log('fullModule: ', fullModule);
      // console.log('Module Record Passed In: ', moduleRecord);

      const moduleName = (fullModule === 'users') ? 'users' : fullModule.name;
      let name = '';

      switch (moduleName) {
        case 'users':
        case 'contacts':
        case 'providers':
        case 'leads':
          name = `${moduleRecord.first_name} ${moduleRecord.last_name}`;
          break;
        case 'timesheetTracker':
          if (moduleRecord['placements_timesheetTracker']) name = moduleRecord.placements_timesheetTracker.name;
          break;
        case 'sms':
          if (moduleRecord['leads_sms']) {
            name = 'Text to ' + moduleRecord['leads_sms'].first_name + ' ' + moduleRecord['leads_sms'].last_name;
          }
          break;
        case 'placements':
          // "View" the Providers Name should be shown in both the Placements and Credentialing screen. 
          if (moduleRecord['leads_placements'] && moduleRecord['leads_placements']._id) name = `${moduleRecord['leads_placements'].first_name} ${moduleRecord['leads_placements'].last_name}`;
          else if (moduleRecord['providers_placements'] && moduleRecord['providers_placements']['leads_providers'] && moduleRecord['providers_placements']['leads_providers'].first_name) name = `${moduleRecord['providers_placements']['leads_providers'].first_name} ${moduleRecord['providers_placements']['leads_providers'].last_name}`;
          else if (moduleRecord['providers_placements'] && moduleRecord['providers_placements']._id) name = `${moduleRecord['providers_placements'].first_name} ${moduleRecord['providers_placements'].last_name}`;
          break;
        case 'credentialing':
          // "View" the Providers Name should be shown in both the Placements and Credentialing screen.
          if (moduleRecord['providers_credentialing'] && moduleRecord['providers_credentialing'].leads_providers) name = `${moduleRecord['providers_credentialing'].leads_providers.first_name} ${moduleRecord['providers_credentialing'].leads_providers.last_name}`;
          else if (moduleRecord['providers_credentialing'] && moduleRecord['providers_credentialing'].first_name) name = `${moduleRecord['providers_credentialing'].first_name} ${moduleRecord['providers_credentialing'].last_name}`;
          else name = 'View';
          break;
        case 'benefits':
          // "View" the Providers Name should be shown in both the Placements and Credentialing screen.
          if (moduleRecord['providers_benefits'] && moduleRecord['providers_benefits']._id) name = `${moduleRecord['providers_benefits'].first_name} ${moduleRecord['providers_benefits'].last_name}`;
          else name = 'Benefit Election';
          break;
        case 'providerRelations':
          // "View" the Providers Name should be shown in both the Placements and Credentialing screen.
          if (moduleRecord['providers_providerRelations'] && moduleRecord['providers_providerRelations'].first_name) name = `${moduleRecord['providers_providerRelations'].first_name} ${moduleRecord['providers_providerRelations'].last_name}`;
          else name = 'View';
          break;
        case 'subs':
          if (moduleRecord['leads_subs']) name = `${moduleRecord.leads_subs.first_name} ${moduleRecord.leads_subs.last_name}`;
          break;
        case 'stateFilings':
          // Get object with full state names. Assigning to object first just incase it is not found.
          const statesListObj = Object.assign({}, this._sharedUtilsService.stateListObj);

          if (moduleRecord['state'] != undefined) name = (statesListObj[moduleRecord['state']] != undefined) ? statesListObj[moduleRecord['state']] : moduleRecord['state'];
          else name = 'View';
          break;
        default:
          // not a special case. Get the fieldForName from the module and take into account the field type
          if (fullModule && fullModule.fieldForName) {
            const moduleFieldForName = fullModule.fieldForName;
            const fieldInfo = fullModule.fields.find(_f => _f.fieldName === moduleFieldForName);
            const fieldType = (fieldInfo && fieldInfo.type) ? fieldInfo.type.inputType : null;

            let recName = 'View';

            if (fieldType === 'Dropdown') {
              // get the label text
              const dropdownDetails = fieldInfo?.dropdown?.options.find(_o => _o.value === moduleRecord[moduleFieldForName]);
              if (dropdownDetails && dropdownDetails.label) recName = dropdownDetails.label;
            } else if (fieldType === 'Relate') {
              // get name from related module
              const nameField = (moduleRecord[moduleFieldForName]) ? moduleRecord[moduleFieldForName] : this.getNameForModule(fieldType.ref, moduleRecord[moduleFieldForName]); //moduleRecord[fullModule.fieldForName];
              if (nameField && nameField.name) recName = nameField.name;
            } else {
              const fieldValue = moduleRecord[moduleFieldForName]; // Get the value of the record field to us in check.
              recName = moduleRecord[moduleFieldForName] ?? 'View'; // If field is null or undefined it will default to 'View'.

              // Check if fieldValue is a valid string. If not, default to 'View' to avoid issues.
              recName = (typeof fieldValue === 'string' && fieldValue?.trim() !== '') ? fieldValue : (fieldValue != undefined ? fieldValue : 'View');
            }

            name = recName;
          } else {
            name = (moduleRecord.name && moduleRecord.name.length) ? moduleRecord.name : 'View';
          }
      }

      return name;
    } else {
      return null;
    }
  }


  async confirmDeletionOfItems(_deletionObject): Promise<boolean> {
    return new Promise(async (resolve) => {
      const dialogRef = this.confirmActionDialog.open(ConfirmActionDialogComponent, {});
      const instance = dialogRef.componentInstance;
      instance.title = 'Confirm Deletion';
      instance.items = _deletionObject.selectedRows.map(_item => { return { _id: _item._id, name: _item[_deletionObject.instanceNameFieldToUse] }; });

      if (_deletionObject.userPrivileges['deletionLevel'] === 'All') instance.textToDisplay = 'Are you sure you want to delete the following:';
      else instance.textToDisplay = 'Are you sure you want to delete the following. Items you did not create will not be removed:';

      instance.buttonsInfo = {
        confirmText: 'Delete',
        confirmBtnColor: 'warn',
        denyText: 'Cancel',
        denyBtnColor: ''
      };

      const _confirmationResult = await lastValueFrom(dialogRef.afterClosed());
      if (_confirmationResult === true) {
        await this.deleteSelectedItems(_deletionObject);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }


  deleteSelectedItems(_deletionObject): Promise<object> {
    return new Promise(async (resolve) => {
      const itemsToDelete = _deletionObject.selectedRows;

      // If can only delete owned records itemsToDelete will be filtered
      const allowedToDelete = (_deletionObject.userPrivileges['deletionLevel'] === 'Owner') ? itemsToDelete.filter(_i =>
        (_i.assigned_to && ((_i.assigned_to._id && _i.assigned_to._id === _deletionObject.currentUser._id) || (_i.assigned_to === _deletionObject.currentUser._id)))
      ) : itemsToDelete;

      if (allowedToDelete && allowedToDelete.length) {
        const idOnlyArray = allowedToDelete.map(_a => _a._id ? _a._id : _a);
        const moduleOptions = { name: _deletionObject.currentModule.name, schema: _deletionObject.currentModule.customSchema };

        const results = (_deletionObject.deletionType === 'permanent') ? await lastValueFrom(this.permanentlyDeleteByIdArray(moduleOptions, idOnlyArray)) : await lastValueFrom(this.markDeletedByIdArray(moduleOptions, idOnlyArray));

        if (results && results !== undefined) {
          if (results['success'] && results['success'] == true) this._sharedUtilsService.showMintNotificationWithOptions('Deletion process was successful.', 1000);
          else this._sharedUtilsService.showErrorDialog('There was an issue with the deletion process. Please try again later.');
        }

        resolve(results);
      } else {
        this._sharedUtilsService.showErrorDialog('No items available for deletion!');
        resolve(null);
      }
    });
  }


  shouldRenderField(_field, recordPassedIn): boolean {
    if (recordPassedIn == undefined) recordPassedIn = {};

    if (_field?.deleted) return false;

    if (_field?.dependent && _field?.parentField) {
      const parentField = _field?.parentField;

      if (parentField?.deleted) {
        return false
      } else if (parentField?.fieldName) {
        const isEmail: boolean = parentField?.type?.inputType === 'EmailAddress';
        const parentFieldValue = (isEmail) ? recordPassedIn['email_addresses'] : recordPassedIn[parentField?.fieldName];
        // console.log("~ parentFieldValue:", parentFieldValue)

        return this.fieldMatchesDependentValues(_field, parentFieldValue);
        // return (parentFieldValue != undefined && this.fieldMatchesDependentValues(_field, parentFieldValue)) ? true : false;
      }
    }

    // If not dependent or parent field was not set correctly, always render the field
    return true;
  }


  fieldMatchesDependentValues(_field, _valueToCheck): boolean {
    let passesCheck: boolean = false;

    // These have to be present, but _valueToCheck can be null for first check and will check for null value on second check.
    if (_field?.fieldsToTrigger != undefined && _field.parentField != undefined) {
      const fieldType = _field?.parentField?.type?.inputType;
      // console.log("~ fieldType: ", fieldType);
      // console.log("~ _valueToCheck: ", _valueToCheck);

      if (['File', 'Email Address', 'EmailAddress', 'Phone'].includes(fieldType)) {
        // If fieldsToTrigger is true field needs to have value. If false field can't have value.
        let hasValue: boolean = false;

        // Instead of checking fieldType since more may be added, just see if the value is array or string with value.
        if (_valueToCheck != undefined) {
          if (fieldType === 'EmailAddress') hasValue = (_valueToCheck?.length) ? _valueToCheck.some(_email => _email?.email_address !== '' && !_email?.deleted) : false;
          else if (fieldType === 'Email Address') hasValue = (_valueToCheck?.email_address !== '') ? true : false; // {email_address: ''}
          else if (Array.isArray(_valueToCheck) && _valueToCheck.length) hasValue = true;
          else if (!Array.isArray(_valueToCheck) && _valueToCheck !== '') hasValue = true;
        }

        // console.log("~ hasValue: ", hasValue);

        if (_field?.fieldsToTrigger === 'true') passesCheck = hasValue ? true : false;
        else if (_field?.fieldsToTrigger === 'false') passesCheck = !hasValue ? true : false;
        else passesCheck = true; // fieldsToTrigger is not setup correct so just pass it.
      } else if (_valueToCheck == undefined) {
        // The above check needs to check if _valueToCheck is null, but all below will value not to be null.
        passesCheck = false;
      } else if (['TextField', 'TextArea', 'Url'].includes(fieldType)) {
        const valueToCheckString = (_valueToCheck != undefined) ? String(_valueToCheck).toLowerCase() : null;
        const fieldsToTriggerString = String(_field?.fieldsToTrigger).toLowerCase();

        // Just make sure it is set and includes fieldsToTrigger string.
        passesCheck = valueToCheckString && valueToCheckString.includes(fieldsToTriggerString);
      } else if (['Date', 'DateTime'].includes(fieldType)) {
        const triggerObject = _field?.fieldsToTrigger; // {'dateCreatedRange': null, 'dateCreated': null, 'dateCreatedEnd': null}
        const dateRange = (triggerObject['dateCreatedRange'] != undefined) ? triggerObject['dateCreatedRange'] : null;
        const dateCreated = (triggerObject['dateCreated'] != undefined) ? triggerObject['dateCreated'] : null;
        const dateCreatedEnd = (triggerObject['dateCreatedEnd'] !== undefined) ? triggerObject['dateCreatedEnd'] : null;

        if (dateRange) {
          passesCheck = this.dateFallsWithinRange(_valueToCheck, dateRange, dateCreated, dateCreatedEnd);
        } else {
          passesCheck = true; // fieldsToTrigger is not setup correct so just pass it.
        }
      } else if (['Number', 'AutoIncrement', 'Currency'].includes(fieldType)) {
        const triggerObject = _field?.fieldsToTrigger; // {'numberRange': null, 'initialValue': null, 'endValue': null}
        const numberRange = (triggerObject['numberRange'] != undefined) ? triggerObject['numberRange'] : null;
        const initialValue = (triggerObject['initialValue'] != undefined) ? (typeof triggerObject['initialValue'] === 'number') ? triggerObject['initialValue'] : Number(triggerObject['initialValue']) : null;
        const endValue = (triggerObject['endValue'] != undefined) ? (typeof triggerObject['endValue'] === 'number') ? triggerObject['endValue'] : Number(triggerObject['endValue']) : null;

        if (numberRange && initialValue) {
          // console.log(`Search for number in range ${numberRange}: ${initialValue} ${(numberRange === 'Between') ? 'and ' + endValue : ''}`);
          if (numberRange === 'Less') {
            passesCheck = _valueToCheck < initialValue;
          } else if (numberRange === 'Greater') {
            passesCheck = _valueToCheck > initialValue;
          } else if (numberRange === 'Equals') {
            passesCheck = _valueToCheck == initialValue;
          } else if (numberRange === 'Between' && endValue) {
            passesCheck = endValue != undefined && (_valueToCheck >= initialValue && _valueToCheck <= endValue);
          } else {
            passesCheck = _valueToCheck == initialValue;
          }
        } else {
          passesCheck = true; // fieldsToTrigger is not setup correct so just pass it.
        }
      } else if (fieldType === 'Dropdown') {
        // Must have one of the set ones present.
        const triggerField = (_field?.fieldsToTrigger?.length) ? _field.fieldsToTrigger : [];
        passesCheck = triggerField.includes(_valueToCheck);
      } else if (fieldType === 'MultiSelect') {
        // Must at least have the ones set present.
        const triggerField = (_field?.fieldsToTrigger?.length) ? _field.fieldsToTrigger : [];
        const valueToCheckArray = (_valueToCheck?.length) ? _valueToCheck : [];
        passesCheck = triggerField.every(_value => valueToCheckArray.includes(_value));
      } else if (['Module', 'Relate'].includes(fieldType)) {
        // Must have one of the set ones present.
        const triggerIds = _field.fieldsToTrigger.map(_value => _value?._id ? _value._id : _value);
        const valueToCheckId = (_valueToCheck?._id) ? _valueToCheck?._id : _valueToCheck;
        passesCheck = (valueToCheckId && triggerIds.includes(valueToCheckId)) ? true : false;
      } else if (['MultiModule', 'MultiRelate'].includes(fieldType)) {
        // Must at least have the ones set present.
        const triggerIds = _field.fieldsToTrigger.map(_value => _value?._id ? _value._id : _value);
        const valueIds = (_valueToCheck?.length) ? _valueToCheck.map(_value => _value?._id ? _value._id : _value) : [];
        passesCheck = triggerIds.every(_id => valueIds.includes(_id));
      } else if (fieldType === 'FlexRelate') {
        const moduleName = _field?.fieldsToTrigger?.moduleName;
        const recordId = _field?.fieldsToTrigger?.recordId || null;

        if (moduleName && moduleName !== '' && recordId?._id) {
          const valueToCheckRecordId = (_valueToCheck?.recordId?._id) ? _valueToCheck?.recordId?._id : _valueToCheck?.recordId; // Incase not populated which does happen.
          const moduleNamesMatch: boolean = _valueToCheck?.moduleName === moduleName;
          const recordIdsMatch: boolean = valueToCheckRecordId === recordId?._id;

          // Both must match.
          passesCheck = moduleNamesMatch && recordIdsMatch;
        } else {
          // fieldsToTrigger was not set correctly, so just pass it.
          passesCheck = true;
        }
      } else if (fieldType === 'MultiFlexRelate') {
        const moduleName = _field?.fieldsToTrigger?.moduleName;
        const records = _field?.fieldsToTrigger?.records || [];

        if (moduleName && moduleName !== '' && records?.length) {
          // Must at least have the ones set present.
          const moduleNamesMatch: boolean = _valueToCheck?.moduleName === moduleName;
          const triggerIds = records.map(_r => _r?._id ? _r._id : _r);
          const valueIds = (_valueToCheck?.records?.length) ? _valueToCheck.records.map(_value => _value?._id ? _value._id : _value) : [];

          passesCheck = moduleNamesMatch && triggerIds.every(_id => valueIds.includes(_id));
        } else {
          // fieldsToTrigger was not set correctly, so just pass it.
          passesCheck = true;
        }
      } else if (fieldType === 'Address') {
        const triggerObject = _field?.fieldsToTrigger;
        const currentAddress = {
          street: _valueToCheck['street'] || null,
          city: _valueToCheck['city'] || null,
          state: _valueToCheck['state'] || null,
          zip: _valueToCheck['zip'] || null
        };

        let addressPassed = true;

        for (const prop in triggerObject) {
          if (triggerObject[prop] && (!currentAddress[prop] || currentAddress[prop] !== triggerObject[prop])) {
            addressPassed = false;
            break; // No need to continue checking once addressPassed is false
          }
        }

        passesCheck = addressPassed;
      } else if (fieldType === 'Checkbox') {
        // Set as a string true or false. So convert to string to compare.
        const _valueToCheckAsString = (_valueToCheck) ? String(_valueToCheck) : null;
        passesCheck = (_valueToCheckAsString && _field?.fieldsToTrigger === _valueToCheckAsString) ? true : false;
      } else if (fieldType === 'Array') {
        // Must at least have the ones set present.
        const triggerValues = _field.fieldsToTrigger.map(_value => _value?._id ? _value._id : _value);
        const incomingValues = (_valueToCheck?.length) ? _valueToCheck.map(_value => _value?._id ? _value._id : _value) : [];
        passesCheck = triggerValues.every(_value => incomingValues.includes(_value));
      } else if (fieldType === 'Object') {
        const triggerObject = _field?.fieldsToTrigger;

        if (triggerObject?._id) passesCheck = _valueToCheck?._id === triggerObject._id;
        else passesCheck = true; // Don't have anything to compare to so return true.
      } else {
        passesCheck = (_field?.fieldsToTrigger === _valueToCheck) ? true : false;
      }
    } else {
      passesCheck = true;
    }

    return passesCheck;
  }


  dateFallsWithinRange(_valueToCheck, dateRange, dateSpecifier, dateSpecifierEnd = null) {
    if (!_valueToCheck || !dateRange) {
      return false;
    }

    // safeParseMoment will make sure the date is valid moment object.
    const dateAsMoment = this.safeParseMoment(_valueToCheck);
    const momentStartDate = (dateSpecifier != undefined) ? this.safeParseMoment(dateSpecifier) : null;
    const momentEndDate = (dateSpecifierEnd != undefined) ? this.safeParseMoment(dateSpecifierEnd) : null;

    if (!dateAsMoment) return false; // Handle the invalid _valueToCheck. Could not be converted to moment.

    switch (dateRange) {
      case 'Before':
        return momentStartDate && dateAsMoment.isSameOrBefore(momentStartDate.endOf('day'));
      case 'After':
        return momentStartDate && dateAsMoment.isSameOrAfter(momentStartDate.startOf('day'));
      case 'Between':
        return (momentStartDate && momentEndDate) && (dateAsMoment.isSameOrAfter(momentStartDate) && dateAsMoment.isSameOrBefore(momentEndDate));
      case 'last_year':
        const lastYearStartDate = moment().subtract(1, 'year').startOf('year');
        const lastYearEndDate = moment().subtract(1, 'year').endOf('year');
        return dateAsMoment.isSameOrAfter(lastYearStartDate) && dateAsMoment.isSameOrBefore(lastYearEndDate);
      case 'this_year':
        const thisYearStartDate = moment().startOf('year');
        const thisYearEndDate = moment().endOf('year');
        return dateAsMoment.isSameOrAfter(thisYearStartDate) && dateAsMoment.isSameOrBefore(thisYearEndDate);
      case 'this_month':
        const thisMonthStartDate = moment().startOf('month');
        const thisMonthEndDate = moment().endOf('month');
        return dateAsMoment.isSameOrAfter(thisMonthStartDate) && dateAsMoment.isSameOrBefore(thisMonthEndDate);
      case 'last_month':
        const lastMonthStartDate = moment().subtract(1, 'month').startOf('month');
        const lastMonthEndDate = moment().subtract(1, 'month').endOf('month');
        return dateAsMoment.isSameOrAfter(lastMonthStartDate) && dateAsMoment.isSameOrBefore(lastMonthEndDate);
      default:
        if (!momentStartDate) return false;

        const days = parseInt(dateRange);
        const todayAsMoment = moment().endOf('day');

        if (days > 0) {
          const rangeEndDate = momentStartDate.clone().add(days, 'd').endOf('day');
          return dateAsMoment.isSameOrAfter(momentStartDate.startOf('day')) && dateAsMoment.isSameOrBefore(rangeEndDate);
        } else if (days == 0) {
          return dateAsMoment.isSameOrAfter(momentStartDate.startOf('day')) && dateAsMoment.isSameOrBefore(momentStartDate.endOf('day'));
        } else if (days < 0) {
          const subtractDays = Math.abs(days);
          const rangeStartDate = todayAsMoment.clone().subtract(subtractDays, 'd').startOf('day');
          return dateAsMoment.isSameOrAfter(rangeStartDate) && dateAsMoment.isSameOrBefore(todayAsMoment);
        }
    }

    return false;
  }


  safeParseMoment(dateString) {
    try {
      const parsedDate = moment(dateString);
      return parsedDate.isValid() ? parsedDate : null;
    } catch (error) {
      return null;
    }
  }
}