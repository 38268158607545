import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Handbook } from '../models/handbook';
import { Subject } from 'rxjs/internal/Subject';

export interface HandbookApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class HandbookService {
    private currentHandbook;
    private handbookUpdateObs$ = new Subject<Handbook>();

    private API_URL = '/api/handbooks/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }


    setCurrent(_handbook): void {
        this.currentHandbook = _handbook;
    }


    getCurrent() {
        return this.currentHandbook;
    }


    getAll(): Observable<Handbook[]> {
        return this.httpClient.get<Handbook[]>(this.API_URL);
    }


    get(_id: string): Observable<Handbook> {
        return this.httpClient.get<Handbook>(this.API_URL + _id);
    }


    getForHrPolicy(_policyId: string): Observable<Handbook> {
        return this.httpClient.get<Handbook>(this.API_URL + 'for-policy/' + _policyId);
    }


    create(_setting): Observable<Handbook> {
        return this.httpClient.post<Handbook>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
    }


    update(_handbook: Handbook): Observable<Handbook> {
        return this.httpClient.put<Handbook>(`${this.API_URL}${_handbook._id}`, JSON.stringify(_handbook), this.headerOptions);
    }


    markAsDelete(id: string): Observable<Handbook> {
        return this.httpClient.put<Handbook>(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
    }


    delete(id: string): Observable<Handbook> {
        return this.httpClient.delete<Handbook>(this.API_URL + id);
    } 


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<HandbookApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<HandbookApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }
}