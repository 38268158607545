import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { WorkflowAction } from '@app/models';

@Injectable({ providedIn: 'root' })
export class WorkflowActionService {
    private API_URL = '/api/workflow-actions/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;

    public actionOptions: any[] = [
        {label: 'Update fields in the target module', value: 1},
        {label: 'Update fields in a related module', value: 2},
        {label: 'Create a record in a module associated with target module', value: 3},
        // {label: 'Create a record associated with a module related to the target module', value: 4}
    ];


    public actionDateRangeTypes: any[] = [
        { label: 'Days', value: 'days' },
        { label: 'Weeks', value: 'weeks' },
        { label: 'Months', value: 'months' },
        { label: 'Years', value: 'years' }
    ];
    

    constructor(
        private formBuilder: UntypedFormBuilder,
        private httpClient: HttpClient
    ) { }


    setCurrentBeingViewed(_workflow: WorkflowAction): void {
        this.currentBeingViewed = _workflow;
    }
    
    
    getCurrentBeingViewed(): WorkflowAction {
        return this.currentBeingViewed;
    }


    get(_id: string): Observable<WorkflowAction> {
        return this.httpClient.get<WorkflowAction>(this.API_URL + _id);
    }


    getAll(): Observable<WorkflowAction[]> {
        return this.httpClient.get<WorkflowAction[]>(this.API_URL);
    }


    create(_workflow): Observable<WorkflowAction> {
        return this.httpClient.post<WorkflowAction>(this.API_URL, JSON.stringify(_workflow), this.headerOptions);
    }


    update(_workflow: WorkflowAction): Observable<WorkflowAction> {
        return this.httpClient.put<WorkflowAction>(`${this.API_URL}/${_workflow._id}`, JSON.stringify(_workflow), this.headerOptions);
    }


    delete(id: string): Observable<WorkflowAction> {
        return this.httpClient.delete<WorkflowAction>(this.API_URL + id);
    }


    deleteByWorkflow(workflowId: string): Observable<WorkflowAction> {
        return this.httpClient.delete<WorkflowAction>(this.API_URL + 'by-workflow/' + workflowId);
    }


    search(searchTerms): Observable<WorkflowAction[]> {
        return this.httpClient.post<WorkflowAction[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchTerms: WorkflowAction): Observable<WorkflowAction[]> {
        searchTerms['selectAll'] = true;
        return this.httpClient.post<WorkflowAction[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }

    workflowConditionFormGroup(formData: WorkflowAction) {
        return this.formBuilder.group({
          workflow: [formData.workflow, Validators.required]
        });
    }
}