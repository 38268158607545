import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { UserDashboardReport } from '@app/models';

export interface UserDashboardReportApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class UserDashboardReportService {
    public  userDashboardReportsEnabled: boolean = false; // Disable if this is being worked on
    private currentUserDashboardReport;
    private userDashboardReportChanged$ = new Subject<UserDashboardReport>();

    private API_URL = '/api/user-dashboard-reports/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }

    setCurrent(_userDashboardReport): void {
        this.currentUserDashboardReport = _userDashboardReport;
    }


    getCurrent() {
        return this.currentUserDashboardReport;
    }


    listenForUserDashboardReportChange() {
        return this.userDashboardReportChanged$;
    }


    announceUserDashboardReportChange(_userDashboardReport: UserDashboardReport) {
        this.userDashboardReportChanged$.next(_userDashboardReport);
    }


    getAll(): Observable<UserDashboardReport[]> {
        return this.httpClient.get<UserDashboardReport[]>(this.API_URL);
    }


    get(_id: string): Observable<UserDashboardReport> {
        return this.httpClient.get<UserDashboardReport>(this.API_URL + _id);
    }


    getAllUserDashReports(_userId: string): Observable<UserDashboardReport[]> {
        return this.httpClient.get<UserDashboardReport[]>(this.API_URL + 'allUserDashReports/' + _userId);
    }


    create(_setting): Observable<UserDashboardReport> {
        return this.httpClient.post<UserDashboardReport>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
    }


    update(_userDashboardReport: UserDashboardReport): Observable<UserDashboardReport> {
        return this.httpClient.put<UserDashboardReport>(`${this.API_URL}${_userDashboardReport._id}`, JSON.stringify(_userDashboardReport), this.headerOptions);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    delete(_id: string): Observable<UserDashboardReport> {
        return this.httpClient.delete<UserDashboardReport>(this.API_URL + _id);
    }


    deleteAllCurrentUsersDashReports(_userId: string): Observable<UserDashboardReport> {
        return this.httpClient.delete<UserDashboardReport>(this.API_URL + 'delete-all-by-user/' + _userId);
    }


    sortUserDashArrayByOrder(_userDashArray): Promise<UserDashboardReport[]> {
        return new Promise(async(resolve) => {
            let sortedUserDashArray = _userDashArray.sort(this.sortByOrder);
            resolve(sortedUserDashArray);
        });
    }


    sortByOrder( a, b ) {
        if (a.options == undefined || a.options.order == undefined) return 1;
        else if (b.options == undefined || b.options.order == undefined) return -1;
        else if ( a.options.order < b.options.order ) return -1;
        else if ( a.options.order > b.options.order ) return 1;
        else return 0;
    }
}
