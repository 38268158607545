import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { LibraryResource } from '../models/libraryResource';

@Injectable({ providedIn: 'root' })
export class LibraryResourceService {
  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/library-resources/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
  private currentLibraryResource: LibraryResource;

  public defaultPdfImage: string = '/uploads/placeholders/pdfPlaceholder.png';
  public defaultVideoImage: string = '/uploads/placeholders/videoPlaceholder.jpg';

  public resourceTypes = ['Article', 'Document', 'Video'];
  public resourceCategories = ['Something', 'Nothing'];

  // 'doc', 'docx'
  public nonSupportedFiles = [
    'exe', 'html', 'htm', 'odt', 'xls', 'xlsx', 'ods', 'ppt', 'pptx', 'txt'
  ];

  constructor(
    private httpClient: HttpClient
  ) { }


  getCurrent(): LibraryResource {
    return this.currentLibraryResource;
  }


  setCurrent(_libraryResource: LibraryResource): void {
    this.currentLibraryResource = _libraryResource;
  }


  getAll(): Observable<LibraryResource[]> {
    return this.httpClient.get<LibraryResource[]>(this.API_URL);
  }


  get(_id: string): Observable<LibraryResource> {
    return this.httpClient.get<LibraryResource>(this.API_URL + _id);
  }


  update(_libraryResource: LibraryResource): Observable<LibraryResource> {
    return this.httpClient.put<LibraryResource>(this.API_URL + _libraryResource._id, JSON.stringify(_libraryResource), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<LibraryResource[]> {
    return this.httpClient.put<LibraryResource[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<LibraryResource[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<LibraryResource[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  deleteByIdArray(arrayOfIds): Observable<any[]> {
    const deleteRequest = {ids: arrayOfIds};
    return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  create(_libraryResource): Observable<LibraryResource> {
    return this.httpClient.post<LibraryResource>(this.API_URL, JSON.stringify(_libraryResource), this.headerOptions);
  }


  search(searchTerms): Observable<LibraryResource[]> {
    return this.httpClient.post<LibraryResource[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectAll(searchTerms): Observable<LibraryResource[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<LibraryResource[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<LibraryResource[]> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<LibraryResource[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }


  setupResourceDataWithFileUpload(_resourceFormData): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'setupResourceData/with-file-upload', JSON.stringify(_resourceFormData), this.headerOptions);
  }
}
