export class Task {
    _id: string;
    parent_type: any;
    related_to_module: string;
    related_to_record_id: string;
    related_record_name: string;
    related_record: {
        moduleName: string;
        recordId: string;
    };
    district: any;
    description: string;
    assigned_to: any;
    created_by: any;
    status: any;
    name: string;
    subject: string;
    task_type: string;
    is_urgent: Boolean;
    is_private: Boolean;
    due_date: Date;
    start_date: Date;
    subs_tasks: any;
    placements_tasks: any;
    providers_tasks: any;
    date_created: Date;
    createdAt: Date;
    deleted: Boolean;
    dueDateRange: any;
    dateCreatedRange: any; 
    dateCreated;
    dateCreatedEnd;
    dueDateCreated;
    dueDateCreatedEnd;
    hasSearchTerms;  
    restrictedTo; 
    myTasks: boolean;
}
