import { Injectable } from '@angular/core';

import { Subject } from 'rxjs/internal/Subject';

import { CartItem, StoreItem, StoreItemOption } from '@app/models';

import { SharedUtilsService } from './shared-utils.service';
import { StoreItemService } from './store-item.service';

@Injectable({ providedIn: 'root' })

export class ShoppingCartService {
    private shoppingCartObs$ = new Subject<CartItem[]>();
    private currentCart: CartItem[];

    private CART_KEY = "shopping-cart";

    constructor(
      private _sharedUtilsService: SharedUtilsService,
      private _storeItemService: StoreItemService
    ) {}


    listenForCartChange() {
        return this.shoppingCartObs$;
    }


    announceCartChange(_shoppingCart) {
        this.shoppingCartObs$.next(_shoppingCart);
    }


    getCurrentShoppingCart() {
      let currentCart = this.currentCart;

      if (currentCart) return currentCart;

      const ufs = this._sharedUtilsService.getFromLocal(this.CART_KEY);
      // console.log('Shopping cart from storage: ', ufs);

      if (ufs) {
        currentCart = JSON.parse(ufs);
        this.currentCart = currentCart;
        // console.log('Found it in storage: ', this.currentCart);
      }

      // console.log('Cart Found: ', currentCart);
      return currentCart;
    }


    setCurrentShoppingCart(_cart) {
      this.currentCart = _cart;
      this._sharedUtilsService.saveInLocal(this.CART_KEY, JSON.stringify(this.currentCart));
      this.announceCartChange(_cart);
    }


    saveCart(_cart): void {
      this._sharedUtilsService.saveInLocal(this.CART_KEY, JSON.stringify(_cart));
    }


    // Return true if successful, or false if not
    addItemToCart(_storeItem: StoreItem, _selectedOption: StoreItemOption, _quantity: number): Promise<boolean> {
      return new Promise(async(resolve) => {
        if ((_storeItem != undefined && _storeItem._id != undefined) && (_selectedOption != undefined && _selectedOption._id != undefined) && _quantity != undefined) {
          let cart = this.getCurrentShoppingCart();
          if (cart == undefined) cart = [];

          let existingCartItem = cart.find((_cartItem) => (_cartItem.storeItem != undefined && _cartItem.storeItem._id != undefined) &&
            (_cartItem.selectedOption != undefined && _cartItem.selectedOption._id != undefined) &&
            (_cartItem.storeItem._id === _storeItem._id) &&
            (_cartItem.selectedOption._id === _selectedOption._id));

          // console.log("Existing: ", existingCartItem);

          let cartUpdated: boolean = false;

          if (existingCartItem == undefined) {
            const newCartItem = new CartItem();
            newCartItem.storeItem = _storeItem;
            newCartItem.selectedOption = _selectedOption;
            newCartItem.quantity = _quantity;
            cart.push(newCartItem);
            this._sharedUtilsService.showNotification('Item was added to your cart.');
            cartUpdated = true; // Sets current cart if true and returns success
          } else {
            if (existingCartItem.quantity == undefined) existingCartItem.quantity = 0; // Need this not to be undefined for the check below
            const maxAvailable: number = (existingCartItem.selectedOption.quantity != undefined) ? existingCartItem.selectedOption.quantity : 0; // Used for the check below
            // console.log("maxAvailable", maxAvailable);

            // Check if max quantity for CartItem selectedOption has been reached
            if (maxAvailable != undefined && existingCartItem.quantity < maxAvailable && ((existingCartItem.quantity + _quantity) <= maxAvailable)) {
              // console.log('Add quantity!');
              existingCartItem.quantity += _quantity;
              this._sharedUtilsService.showNotification('Your cart was updated.');
              cartUpdated = true; // Sets current cart if true and returns success
            } else {
              // console.log('Quantity reached!');
              this._sharedUtilsService.showErrorDialog('The quantity you selected is more than the available quantity for this item.');
            }
          }

          // console.log("addItem ~ updated cart", cart)

          // If cart was updated set current cart and return successful
          if (cartUpdated) {
            this.setCurrentShoppingCart(cart);
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    }


    // Return true if successful, or false if not
    removeCartItem(_cartItemToRemove: CartItem): Promise<boolean> {
      return new Promise(async(resolve) => {
        let cart = this.getCurrentShoppingCart();
        if (cart == undefined || !cart.length) {
          this._sharedUtilsService.showErrorDialog('Your cart is empty.');
          resolve(false);
        }

        if (_cartItemToRemove != undefined && _cartItemToRemove.storeItem != undefined && _cartItemToRemove.storeItem._id != undefined && _cartItemToRemove.selectedOption != undefined && _cartItemToRemove.selectedOption._id != undefined) {
          let cartItemIndex = cart.findIndex((_cartItem) => (_cartItem.storeItem != undefined && _cartItem.storeItem._id != undefined) &&
            (_cartItem.selectedOption != undefined && _cartItem.selectedOption._id != undefined) &&
            (_cartItem.storeItem._id === _cartItemToRemove.storeItem._id) &&
            (_cartItem.selectedOption._id === _cartItemToRemove.selectedOption._id));

          // console.log("Cart Item Index: ", cartItemIndex);

          if (cartItemIndex > -1) {
            cart.splice(cartItemIndex, 1); // Remove cart item from cart
            this._sharedUtilsService.showNotification('Item was removed from your cart.');
            this.setCurrentShoppingCart(cart);
            // console.log("addItem ~ updated cart", cart);
            resolve(true);
          } else {
            this._sharedUtilsService.showErrorDialog('Item was not found in your cart.');
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    }


    calculateCart(cart: CartItem[]) {
      let cartTotal: number = 0;
      if (cart != undefined && cart.length) {
        cartTotal = cart.filter(_cartItem => (_cartItem.selectedOption != undefined && _cartItem.selectedOption.price != undefined))
                        .map((_cartItem) => _cartItem.quantity * _cartItem.selectedOption.price)
                        .reduce((previous, current) => previous + current, 0);
      }

      return cartTotal;
    }


    emptyCart(): void {
      const newCart: CartItem[] = [];
      this.setCurrentShoppingCart(newCart);
    }
}