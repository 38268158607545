import { GamePlayerState } from "./gamePlayerState";

export class GameSession {
    _id: string;
    game_instance: any;
    user: any;
    current_state: GamePlayerState[]; // Array of objects for each player in the game. Holds goal progress, and the user.
    record_ids_used: any;
    points: number;
    available_credits: number;
    used_credits: number;
    last_time_viewed: Date;

    // For leaderboard data build
    positionFinished: number
    currentPosition: number;
    last_name: string;
}