<div class="container">
  <h1 mat-dialog-title class="text-center">Inactivity Warning</h1>

  <p>
    Your session is about to expire! You will be logged out due to inactivity in <b>{{ currentTimeRemaining }}</b> seconds.
    <br>
    Do you want to stay signed in?
  </p>

  <div class="row">
    <mat-dialog-actions class="two-button-full-width-flex">
      <button mat-flat-button color="primary" mat-dialog-close (click)="closeDialog(true)">Yes, Keep me signed in</button>
      <button mat-raised-button mat-dialog-close (click)="closeDialog(false)">No, Sign me out</button>
    </mat-dialog-actions>
  </div>
</div>