<div class="credits-notification-dialog-wrapper">
    <!-- <div class="header-wrapper">
        <div class="search-title">
            <h2>Credits Notification</h2>
        </div>

        <div class="cancel-wrapper">
            <button mat-flat-button color="accent" (click)="closeDialog()">Close</button>
        </div>
    </div> -->

    <div class="credits-notification-dialog-content scrollable-dialog-content">
        <div class="credits-notification-main-wrapper">
            <div class="notification-content-wrapper">
                <div class="notification-content-centered-wrapper">
                    <div class="spinning-coin-section">
                        <div class="coin">
                            <div class="side-front"><span>$</span></div>
                            <div class="side-back"><span>C</span></div>
                        </div>
                    </div>

                    <div class="notification-message-section">
                        <ng-container *ngIf="!messagePassedIn">
                            <div class="message-text">You received <span class="bold">{{ creditAmount }}</span> credit{{ (creditAmount !== 1) ? 's' : '' }}.</div>
                        </ng-container>

                        <ng-container *ngIf="messagePassedIn">
                            <div class="message-text">{{ messagePassedIn }}</div>
                            <div class="credits-text"><span class="credit-label">Credits:</span> {{ creditAmount }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>