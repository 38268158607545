import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Subject } from 'rxjs/internal/Subject';

import { Observable } from 'rxjs/internal/Observable';
import { UserProfile } from '../models/userProfile';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  private API_URL = '/api/user-profiles/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
  private userProfileUpdateObs$ = new Subject<UserProfile>();

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder
  ) { }


  listenForProfileChange() {
    return this.userProfileUpdateObs$;
  }


  announceProfileChange(_userProfile: UserProfile) {
    this.userProfileUpdateObs$.next(_userProfile);
  }


  get(_id: string): Observable<UserProfile> {
    return this.httpClient.get<UserProfile>(this.API_URL + _id);
  }


  getForUser(_userId: any): Observable<UserProfile> {
    return this.httpClient.get<UserProfile>(this.API_URL + 'for-user/' + _userId);
  }


  getAll(): Observable<UserProfile[]> {
    return this.httpClient.get<UserProfile[]>(this.API_URL);
  }


  create(_setting): Observable<UserProfile> {
    return this.httpClient.post<UserProfile>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
  }


  update(_setting: UserProfile): Observable<UserProfile> {
    return this.httpClient.put<UserProfile>(`${this.API_URL}/${_setting._id}`, JSON.stringify(_setting), this.headerOptions);
  }


  delete(id: string): Observable<UserProfile> {
    return this.httpClient.delete<UserProfile>(this.API_URL + id);
  }


  profileFormGroup(formData: UserProfile) {   
    return this.formBuilder.group({
      background_image: [formData.background_image],
      avatar: [formData.avatar],
      user: [formData.user],
      about_me: [formData.about_me],
      theme: [formData.theme]
    });
  }
}
