import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { StoreTransaction } from '@app/models';

export interface StoreTransactionApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class StoreTransactionService {
    private currentStoreTransaction;

    private API_URL = '/api/store-transactions/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }

    setCurrent(_storeTransaction): void {
        this.currentStoreTransaction = _storeTransaction;
    }


    getCurrent() {
        return this.currentStoreTransaction;
    }


    getAll(): Observable<StoreTransaction[]> {
        return this.httpClient.get<StoreTransaction[]>(this.API_URL);
    }


    get(_id: string): Observable<StoreTransaction> {
        return this.httpClient.get<StoreTransaction>(this.API_URL + _id);
    }


    create(_setting): Observable<StoreTransaction> {
        return this.httpClient.post<StoreTransaction>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
    }


    search(searchTerms): Observable<StoreTransaction[]> {
        return this.httpClient.post<StoreTransaction[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<StoreTransactionApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<StoreTransactionApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<StoreTransactionApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<StoreTransactionApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    sendTransactionEmail(_emailObject): Observable<any> {
        return this.httpClient.post<any>(`${this.API_URL}/send-transaction-email`, JSON.stringify(_emailObject), this.headerOptions);
    }


    update(_storeTransaction: StoreTransaction): Observable<StoreTransaction> {
        return this.httpClient.put<StoreTransaction>(`${this.API_URL}${_storeTransaction._id}`, JSON.stringify(_storeTransaction), this.headerOptions);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    delete(_id: string): Observable<StoreTransaction> {
        return this.httpClient.delete<StoreTransaction>(this.API_URL + _id);
    }
}