import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { Socket } from 'ngx-socket-io';
import { DialerCampaign } from '../models/dialerCampaign';
import { YeeHroSession } from './yeehro-session.service';
import { Recording } from '@app/models';

@Injectable({ providedIn: 'root' })
export class TwilioDialerService implements OnDestroy {
  private API_URL = '/api/dialer/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  private _destroyed$ = new Subject<any>();
  private campaignObs$ = new Subject<any>();

  dialerEvents = this.socket.fromEvent<any>('dialer-event');

  // private dialerCampaignTrigger$ = new Subject<DialerCampaign>();
  public currentCampaign: DialerCampaign;
  private currentCampaign$;

  public dialerStates = {
    0: 'Ready',
    1: 'Awaiting Call In',
    2: 'Running',
    3: 'Attempting Call',
    4: 'Connected',
    5: 'Paused',
    6: 'Caller Left',
    7: 'Complete'
  };

  constructor(
    readonly _session: YeeHroSession,
    private socket: Socket,
    private httpClient: HttpClient
  ) { 
    this.currentCampaign = _session.callCampaign;
    this.currentCampaign$ = _session.callCampaign$;

    this.loadCampaign();
  }  

  ngOnDestroy() {
    // this.saveDialer();

    this._destroyed$.next(null);
    this._destroyed$.complete();
  }


  listenForCampaignUpdate() {
    return this.campaignObs$;
  }

  announceCampaignUpdate(_campaign) {
    this.campaignObs$.next(_campaign);
  } 


  loadCampaign() {
    // this.getFromStorage();
    this._session.callCampaign$.subscribe( (_campaign) => this.currentCampaign = _campaign);
  }


  getEventListener() {
    return this.socket.fromEvent('dialer-event');
  }


  initCampaign(_currentUser, _participants, _voicemail: Recording, _name) {

    // REALLY, this is just a campaign. Has all the fields needed
    // We do not need to store this in the cache. Just store the campaignId of current campaign
    // if a campaignId exists in memory(cache) get it from the database
    this.currentCampaign = new DialerCampaign(_currentUser, _participants, _voicemail);
    console.log('New event set up: ', this.currentCampaign);

    this.currentCampaign.name = _name;
    this.currentCampaign.isStopped = true;
    this.currentCampaign.isPaused = false;
    this.currentCampaign.conferenceStarted = false;
    this.currentCampaign.remainingCalls = [...this.currentCampaign.participants];
    
    // use setter
    this._session.callCampaign = this.currentCampaign;
  }


  getConferenceDetailsByName(_confSearch): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'get-conference-details', JSON.stringify(_confSearch), this.headerOptions);
  }


  endConference(_cid: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + 'end-conference/' + _cid);
  }


  updateCampaignInMemory() {
    this._session.callCampaign = this.currentCampaign;
    // console.log('Updating in memory: ', this.currentCampaign);
    this.announceCampaignUpdate(this._session.callCampaign);
  }


  create(_setting): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'request-new-conference', JSON.stringify(_setting), this.headerOptions);
  }


  getConferenceDetails(_cid: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + 'conference-details/' + _cid);
  }


  search(searchTerms): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'conference-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  endCall(_cid: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + 'end-call/' + _cid);
  }


  getCallDetails(_cid: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + 'call-details/' + _cid);
  }


  addParticipant(_participantRequest): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'add-participant', JSON.stringify(_participantRequest), this.headerOptions);
  }

}
