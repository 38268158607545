import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { ArcadeData } from '@app/models';

@Injectable({ providedIn: 'root' })
export class ArcadeDataService {
    private API_URL = '/api/arcade-data/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }


    getAll(): Observable<ArcadeData[]> {
        return this.httpClient.get<ArcadeData[]>(this.API_URL);
    }


    get(_id: string): Observable<ArcadeData> {
        return this.httpClient.get<ArcadeData>(this.API_URL + _id);
    }


    getByArcadeKey(_key: string): Observable<ArcadeData> {
        return this.httpClient.get<ArcadeData>(this.API_URL + 'by-key/' + _key);
    }


    createOrUpdate(_gameActivity): Observable<ArcadeData> {
        return this.httpClient.post<ArcadeData>(this.API_URL, JSON.stringify(_gameActivity), this.headerOptions);
    }


    update(_gameActivity: ArcadeData): Observable<ArcadeData> {
        return this.httpClient.put<ArcadeData>(`${this.API_URL}/${_gameActivity._id}`, JSON.stringify(_gameActivity), this.headerOptions);
    }
}