<div class="sidebar-faker">
  <div class="card">
    <div class="card-heading">
      <div class="flex-center">
        <h4 class="card-title inline-block pdd-top-5">Pay Raise Calculator</h4>
      </div>
    </div>
  
    <div class="card-body">

      <form novalidate (ngSubmit)="calculateHourly()" class="full-width spaced-form">

        <section class="pay-table-options full-width">
          <header>
            <div class="col">
              <div class="flex-center">Current</div>
              <div class="flex-center">Pay</div>
            </div>

            <div class="col">
              <div class="flex-center">Pay</div>
              <div class="flex-center">Period</div>
            </div>

            <div class="col">
              <div class="flex-center">Weekly</div>
              <div class="flex-center">Hours</div>
            </div>

            <div class="col">
              <div class="flex-center">Pay</div>
              <div class="flex-center">Raise</div>
            </div>
          </header>

          <div class="line-break"></div>

          <div class="row">
            <div class="col">
              <div class="flex-center">
                <div class="input-wrapper">
                  <mat-form-field appearance="outline" class="no-padding-wrapper">
                    <input type="number" matInput placeholder="Current Pay Rate" [(ngModel)]="currentPayRate" name="currentPayRate">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="flex-center">
                <div class="select-wrapper">
                  <mat-form-field appearance="outline" class="no-padding-wrapper">
                    <mat-select placeholder="Pay Period" [(ngModel)]="payPeriod" name="payPeriod">
                      <mat-option *ngFor="let _period of payPeriodOptions" [value]="_period">
                          {{ _period }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>                  
                </div>
              </div>
            </div>

            <div class="col">
              <div class="flex-center">
                <div class="input-wrapper">
                  <mat-form-field appearance="outline" class="no-padding-wrapper">
                    <input type="number" matInput placeholder="Weekly Hours" [(ngModel)]="weeklyHours" name="weeklyHours">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="col-rows">
                <div class="col-row">

                  <div class="flex-center">
                    <div class="input-wrapper">
                      <mat-form-field appearance="outline" class="no-padding-wrapper">
                        <input type="number" matInput placeholder="Pay Raise" [(ngModel)]="currentPayRaise" name="currentPayRaise">
                      </mat-form-field>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="submit-btn-wrapper full-width">
          <button class="auto-width" type="submit" mat-flat-button color="primary">Calculate</button>
        </div>

      </form>


      <div class="results-table full-width">
        <table>
          <thead>
            <th>Pay Period</th>
            <th>Current Pay</th>
            <th>New Pay</th>
            <th>Difference</th>
          </thead>

          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>{{ resultsTableData.differencePerc | number : '1.2-2' }}%</td>
            </tr>

            <tr>
              <td>Hourly:</td>
              <td>{{ resultsTableData.hourly.currentPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.hourly.newPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.hourly.difference | number : '1.2-2' }}</td>
            </tr>

            <tr>
              <td>Weekly:</td>
              <td>{{ resultsTableData.weekly.currentPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.weekly.newPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.weekly.difference | number : '1.2-2' }}</td>
            </tr>

            <tr>
              <td>Every 2 Weeks:</td>
              <td>{{ resultsTableData.biWeekly.currentPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.biWeekly.newPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.biWeekly.difference | number : '1.2-2' }}</td>
            </tr>

            <tr>
              <td>Twice a Month:</td>
              <td>{{ resultsTableData.biMonthly.currentPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.biMonthly.newPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.biMonthly.difference | number : '1.2-2' }}</td>
            </tr>

            <tr>
              <td>Monthly:</td>
              <td>{{ resultsTableData.monthly.currentPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.monthly.newPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.monthly.difference | number : '1.2-2' }}</td>
            </tr>

            <tr>
              <td>Annually:</td>
              <td>{{ resultsTableData.annually.currentPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.annually.newPay | number : '1.2-2' }}</td>
              <td>{{ resultsTableData.annually.difference | number : '1.2-2' }}</td>
            </tr>

          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>

