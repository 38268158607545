import { Component, OnInit, Input } from '@angular/core';

import { LeaderboardItem } from '@app/models';

@Component({
  selector: 'leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {
  @Input() leaderboardItemsPassedIn: LeaderboardItem[] = [];
  @Input() inDialog?: boolean = false;
  @Input() isInProfile?: boolean = false;
  @Input() useScoreWithoutPipe?: boolean = false;
  @Input() leaderboardMode?: string = null;

  isLoadingData: boolean = false;
  topLeaders: LeaderboardItem[] = [];

  constructor() { }

  ngOnInit(): void {
    console.log('Leaderboard Items Passed: ', this.leaderboardItemsPassedIn);
    this.setupLeaderBoard();
  }


  async setupLeaderBoard() {
    this.isLoadingData = true;

    // Get first 3 items for top 3 container.
    if (this.leaderboardItemsPassedIn && this.leaderboardItemsPassedIn.length) {
      this.topLeaders = this.leaderboardItemsPassedIn.slice(0, 3);
    }

    this.isLoadingData = false;
  }
}