import { Role } from './role';

export class LayoutField {
    fieldName: string;
    label: string;
    isFullWidth: boolean;
    type: {
        inputType: string,
        fieldType: string,
        schemaType: string
    };
    fromModule: string;
    fieldMapping: any;
    labelMapping: any;
    default;
    order: number;
    required: boolean;
    dropdown: any; // Needed for module service field change function
    dependent: any;
    parentField: any;
    fieldsToTrigger: any;
    ref: any; // Needed for module service field change function

    constructor(public _name: string = '', public _label: string = '') {
        this.fieldName = _name;
        this.label = _label;
    }    
};

export class LayoutSection {
    sectionName: string;
    sectionFields: LayoutField[];
    subSections;
}

export class Layout {
    _id: string;
    ssModule;
    view: string;
    app: string;
    templateType: string;
    relationshipType: string;
    role: Role;
    panelType: string;
    syncedTo: string;
    sections: LayoutSection[];
    active: boolean;
}