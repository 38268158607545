import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class AddressVerificationService {
    private API_URL = '/api/address-verification';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }


    checkAddress(_address): Observable<any> {
        // console.log('Checking address: ', _address);
        return this.httpClient.post<any>(this.API_URL + '/verify-address', JSON.stringify(_address), this.headerOptions);
    }
}