import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from '../../services';

@Injectable()

export class RouteGuardComponent implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const currentUser = this.authService.getCurrentlyLoggedInUser();

    if (!currentUser) {
      // Set the url incase someone entered link and was not logged in. This will allow us to redirect them back after login.
      if (!['/', '/login'].includes(window.location.pathname)) this.authService.redirectUrl = window.location.pathname;
      // console.log('Path trying to access: ', window.location.pathname);

      this.router.navigate(['/login']);
      return false;
    }

    // const userRoute = this._userService.getRouteForUser(currentUser.userType);
    // console.log('Current User: ', currentUser);
    // console.log('User Route: ', userRoute);

    if (!this.authService.isTokenExpired()) {
      // console.log('Token is not expired.')

      let result = true;

      const anypermissions = route.data['anypermissions'] as Array<string>;
      // console.log('anypermissions: ', anypermissions);

      if (!anypermissions.includes('any') && result && anypermissions) {
        let anyResult = false;

        for (let i = 0; i < anypermissions.length; i++) {
          // console.log('Checking for required permission of: ', anypermissions[i]);

          // only check until they get a true for access
          if (!anyResult && this.authService.doesUserHaveAccessForGivenType(anypermissions[i], currentUser.userType)) {
            anyResult = true;
          }
        }

        // console.log('result: ', result);

        result = anyResult;
      }

      if (result) {
        return true;
      }
    }

    this.router.navigate(['/login']);
    return false;
  }  
}