import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { SsModule } from '@app/models';
import { TableService } from '@app/services';

@Component({
  selector: 'duplication-merger-dialog',
  templateUrl: './duplication-merger-dialog.component.html',
  styleUrls: ['./duplication-merger-dialog.component.scss']
})
export class DuplicationMergerDialogComponent implements OnInit {
  modulePassedIn: SsModule = null;

  retrievingRecords: boolean = true;
  chosenRecords = null;
  fields = null;
  primaryRecord = null;
  primaryCopy = null;
  masterRecordIndex = 0;
  mergedEmail: boolean = false;

  fieldToIndexMapping = {};

  constructor(
    public dialogRef: MatDialogRef<DuplicationMergerDialogComponent>,
    private _tableService: TableService
  ) { }

  ngOnInit() {
    // console.log('updateInfo: ', this.updateInfo);
    // this.initMassUpdate();
    this.retrievingRecords = false;
    this.chosenRecords = this._tableService.selectedRows.selected.filter(_i => _i);

    console.log('chosenRecords: ', this.chosenRecords);
    console.log('modulePassedIn: ', this.modulePassedIn);

    if (this.chosenRecords && this.chosenRecords.length) {
      this.primaryRecord = {...this.chosenRecords[0]}; // default is first selected value
    }

    this.fields = this.modulePassedIn.fields;

    this.setPrimaryRecord(0);
  }


  removeRecord(_recordIndex) {
    this.chosenRecords.splice(_recordIndex, 1);
  }


  setPrimaryRecord(_recordIndex) {
    // console.log('Changing primary record to index of: ', _recordIndex);
    this.primaryRecord = null;
    this.fields.forEach(_f => this.fieldToIndexMapping[_f.fieldName] = _recordIndex);

    setTimeout(() => {
      console.log('Setting Primary Record to: ', this.chosenRecords[_recordIndex]);
      this.primaryRecord = {...this.chosenRecords[_recordIndex]};
      this.masterRecordIndex = _recordIndex;
    }, 500);
  }


  setPrimaryFromField(_chosenRecordIndex, _fieldName) {
    // console.log('_fieldName: ', _fieldName);
    // console.log('Related Record to copy from: ', this.chosenRecords[_chosenRecordIndex]);

    if (_fieldName === 'email') {
      this.mergedEmail = true;
      _fieldName = 'email_addresses';

      const emailAddressesToMerge = this.chosenRecords[_chosenRecordIndex][_fieldName];

      // console.log('Primary Record Emails: ', this.primaryRecord['email_addresses']);
      
      if (this.primaryRecord['email_addresses'] && this.primaryRecord['email_addresses'].length) {
        // add the new one
        const existingEmailAddresses = [...this.primaryRecord['email_addresses']];
        emailAddressesToMerge.forEach(_ea => existingEmailAddresses.push(_ea));

        this.primaryRecord['email_addresses'] = existingEmailAddresses;
      } else {
        this.primaryRecord['email_addresses'] = emailAddressesToMerge;
      }
    } else {
      this.primaryRecord[_fieldName] = this.chosenRecords[_chosenRecordIndex][_fieldName];
    }

    this.fieldToIndexMapping[_fieldName] = _chosenRecordIndex;

    this.primaryCopy = {...this.primaryRecord};
    this.primaryRecord = null;

    setTimeout(() => this.primaryRecord = {...this.primaryCopy}, 100);
  }


  saveMerge() {
    // console.log('newRecord: ', this.newRecord);
    // this.confirmationResult = true;
    this.closeDialog(true);
  }


  cancel() {
    // this.confirmationResult = false;
    this.closeDialog(false);
  }


  closeDialog(_mergeConfirmed) {
    // const massUpdateResponse = {
    //   performAction: this.confirmationResult,
    //   changesMade: this.newRecord,
    //   recordIds: this.recordIds
    // };

    let mergeRequest = null;

    // console.log('this.primaryRecord: ', this.primaryRecord);
    // console.log('chosenRecords: ', this.chosenRecords);

    if (_mergeConfirmed && this.primaryRecord._id) {
      const primaryIndex = this.chosenRecords.findIndex(_r => _r && _r._id === this.primaryRecord._id);
      this.chosenRecords.splice(primaryIndex, 1).map(_r => _r._id);
  
      mergeRequest = {
        mergedEmail: this.mergedEmail,
        primaryRecord: this.primaryRecord,
        recordsToDelete: this.chosenRecords
      };
    } else {
      console.log('Primary Record does not have an id? ', this.primaryRecord);
    }

    return this.dialogRef.close(mergeRequest);
  }

}
