import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import * as moment from 'moment';

import { TimeEntry, TimeSheet, User } from '@app/models';
import { GenericModuleService } from './generic-module.service';
import { SsModuleService } from './ss-module.service';

export interface TimeSheetApi {
  items: TimeSheet[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class TimeSheetService {
  private API_URL = '/api/timeSheets/';
  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  public timeAndAttendanceEnabled: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private _genericService: GenericModuleService,
    private _moduleService: SsModuleService,
  ) { }


  getAll(): Observable<TimeSheetApi> {
    return this.httpClient.get<TimeSheetApi>(this.API_URL);
  }


  get(_id: string): Observable<TimeSheet> {
    return this.httpClient.get<TimeSheet>(this.API_URL + _id);
  }


  create(_timeSheet): Observable<TimeSheet> {
    return this.httpClient.post<TimeSheet>(this.API_URL, JSON.stringify(_timeSheet), this.headerOptions);
  }


  update(_timeSheet: TimeSheet): Observable<TimeSheet> {
    return this.httpClient.put<TimeSheet>(this.API_URL + _timeSheet._id, JSON.stringify(_timeSheet), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'date', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<TimeSheetApi> {
    searchParams['selectAll'] = false;
    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<TimeSheetApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  search(searchTerms): Observable<TimeSheetApi> {
    return this.httpClient.post<TimeSheetApi>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  searchForUsersWeeklyTimesheet(searchTerms): Observable<TimeSheet> {
    return this.httpClient.post<TimeSheet>(this.API_URL + 'usersWeeklyTimesheet', JSON.stringify(searchTerms), this.headerOptions);
  }


  delete(id: String): Observable<any> {
    return this.httpClient.delete(this.API_URL + id);
  }


  // Get the total hours in a passed TimeSheet's timeEntries array
  getTotalHours(_timeSheet: TimeSheet): string {
    if (_timeSheet?.timeEntries == undefined) return '0:00'; // If no time sheet or entries were found, just return '0:00'.

    const totalMinutes = _timeSheet.timeEntries.reduce((total, entry) => {
      // Needs a start and end time.
      if (entry?.start_time && entry?.end_time) {
        const startTime = moment(entry.start_time, "HH:mm"); // Convert the string to moment.
        const endTime = moment(entry.end_time, "HH:mm"); // Convert the string to moment.
        total += moment.duration(endTime.diff(startTime)).asMinutes(); // Now get the difference as a duration in minutes and add it to the total.
      }
      return total;
    }, 0);

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const totalHourString = `${totalHours}:${remainingMinutes.toString().padStart(2, '0')}`;
    // console.log('Total Hours as String: ', totalHourString);

    return totalHourString;
  }


  async getOrCreateUsersWeeklyTimeSheet(_user: User, _skipCreate: boolean = false): Promise<TimeSheet> {
    return new Promise(async (resolve) => {
      try {
        const timesheetModule = await this._moduleService.getFullModuleByNameWithRefresh('timesheets');
        let currentTimeSheet = null;

        // Make sure the module was retrieved with fields.
        if (timesheetModule?.name != undefined && _user?._id != undefined) {
          const inclusionSearch = {
            user: { inclusionType: "Include", value: [_user?._id] },
            generated_on: {
              inclusionType: "Include", value: {
                dateCreatedRange: "Between",
                dateCreated: moment().startOf('week').toDate(),
                dateCreatedEnd: moment().endOf('week').toDate()
              }
            }
          };

          const _searchOptions = {
            name: timesheetModule?.name,
            schema: timesheetModule?.customSchema,
            searchTerms: { selectAll: false, nonCache: true, fields: timesheetModule?.fields, inclusionSearch: inclusionSearch }
          };

          const foundTimesheetsReq = await lastValueFrom(this._genericService.getDataSource(_searchOptions, null, null, 0, 1));
          const foundTimesheets = (foundTimesheetsReq && foundTimesheetsReq['message']) ? foundTimesheetsReq['message'] : [];
          currentTimeSheet = (foundTimesheets && foundTimesheets.length) ? foundTimesheets[0] : null;

          if (currentTimeSheet == undefined && !_skipCreate) {
            const createdTimesheet = await this.createNewTimesheet(_user, timesheetModule);
            currentTimeSheet = (createdTimesheet?._id != undefined) ? createdTimesheet : null;
          } else if (currentTimeSheet != undefined && !currentTimeSheet.timeEntries) {
            const foundEntries = await this.initTimeEntries(_user, currentTimeSheet); // Needed if until timeEntries relate is added to timesheet
            currentTimeSheet.timeEntries = foundEntries;
          }
        }

        resolve(currentTimeSheet);
      } catch (_err) {
        console.log('Error retrieving timesheet for user: ', _err);
        resolve(null);
      }
    });
  }


  async createNewTimesheet(_user: User, _timesheetModule): Promise<TimeSheet> {
    return new Promise(async (resolve) => {
      try {
        let currentTimeSheet = null;

        if (_timesheetModule?.name != undefined && _user?._id != undefined) {
          const newTimesheet = new TimeSheet();
          newTimesheet.user = _user?._id;
          newTimesheet.name = 'Timesheet for ' + _user?.first_name + ' ' + _user?.last_name;
          newTimesheet.total_hours = 0;
          newTimesheet.generated_on = new Date();
          newTimesheet.deleted = false;

          const createObj = { name: _timesheetModule?.name, schema: _timesheetModule?.customSchema, record: newTimesheet };
          const savedTimesheet = await lastValueFrom(this._genericService.create(createObj));

          if (savedTimesheet?._id) {
            savedTimesheet.timeEntries = [];
            currentTimeSheet = savedTimesheet;
          }
        }

        resolve(currentTimeSheet);
      } catch (_err) {
        console.log('Error creating timesheet for user: ', _err);
        resolve(null);
      }
    });
  }


  async initTimeEntries(_user: User, _timesheet: TimeSheet): Promise<TimeEntry[]> {
    return new Promise(async (resolve) => {
      try {
        const timeEntryModule = await this._moduleService.getFullModuleByNameWithRefresh('timeEntries');
        let timeEntries = [];

        // Make sure the module was retrieved with fields.
        if (timeEntryModule?.name != undefined && _user?._id != undefined && _timesheet?._id != undefined) {
          const inclusionSearch = {
            user: { inclusionType: "Include", value: [_user?._id] },
            timesheet: { inclusionType: "Include", value: [_timesheet?._id] }
          }

          const _searchOptions = {
            name: timeEntryModule?.name,
            schema: timeEntryModule?.customSchema,
            searchTerms: { selectAll: true, nonCache: true, fields: timeEntryModule?.fields, inclusionSearch: inclusionSearch }
          };

          const foundTimeEntries = await lastValueFrom(this._genericService.selectAllSearch(_searchOptions));
          timeEntries = (foundTimeEntries != undefined) ? foundTimeEntries : [];
        }

        resolve(timeEntries);
      } catch (_err) {
        console.log('Error retrieving time entries for users timesheet: ', _err);
        resolve(null);
      }
    });
  }
}