import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { GameInstance } from '@app/models';

export interface GameInstanceApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class GameInstanceService {
    private API_URL = '/api/game-instances/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;
    private changedGameInstanceObs$ = new Subject<GameInstance>();

    constructor(
        private httpClient: HttpClient
    ) { }


    setCurrentBeingViewed(_gameInstance: GameInstance): void {
        this.currentBeingViewed = _gameInstance;
    }


    getCurrentBeingViewed(): GameInstance {
        return this.currentBeingViewed;
    }


    listenForInstanceChange() {
        return this.changedGameInstanceObs$;
    }


    announceInstanceChange(_gameInstance: GameInstance) {
        // console.log('Change detected in GameInstance!');
        this.changedGameInstanceObs$.next(_gameInstance);
    }


    get(_id: string): Observable<GameInstance> {
        return this.httpClient.get<GameInstance>(this.API_URL + _id);
    }


    getAll(): Observable<GameInstance[]> {
        return this.httpClient.get<GameInstance[]>(this.API_URL);
    }


    create(_gameInstance): Observable<GameInstance> {
        return this.httpClient.post<GameInstance>(this.API_URL, JSON.stringify(_gameInstance), this.headerOptions);
    }


    update(_gameInstance: GameInstance): Observable<GameInstance> {
        return this.httpClient.put<GameInstance>(`${this.API_URL}/${_gameInstance._id}`, JSON.stringify(_gameInstance), this.headerOptions);
    }


    markDeleted(id: string): Observable<any> {
        return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
    }


    setInactiveByGame(_gameId: string): Observable<GameInstance[]> {
        const updateRequest = { gameId: _gameId };
        return this.httpClient.put<GameInstance[]>(this.API_URL + 'set-inactive/by-game', JSON.stringify(updateRequest), this.headerOptions);
    }


    setInactiveByGameIdArray(arrayOfIds): Observable<any[]> {
        const updateRequest = { ids: arrayOfIds };
        return this.httpClient.put<any>(this.API_URL + 'set-inactive/by-game-id-array', JSON.stringify(updateRequest), this.headerOptions);
    }


    delete(id: string): Observable<GameInstance> {
        return this.httpClient.delete<GameInstance>(this.API_URL + id);
    }


    deleteByGame(gameId: string): Observable<GameInstance> {
        return this.httpClient.delete<GameInstance>(this.API_URL + 'by-game/' + gameId);
    }


    search(searchTerms): Observable<GameInstance[]> {
        return this.httpClient.post<GameInstance[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    searchForOne(searchTerms): Observable<GameInstance> {
        return this.httpClient.post<GameInstance>(this.API_URL + 'search-for-one', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<GameInstance> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<GameInstance>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<GameInstanceApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<GameInstanceApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    processArcadeGameTest(_gameInstance: GameInstance): Observable<any> {
        return this.httpClient.post<any>(this.API_URL + 'process-passed-arcade-game', JSON.stringify(_gameInstance), this.headerOptions);
    }
}