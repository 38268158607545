import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AchievementNotificationPopupData } from '@app/models/achievementNotificationPopupData';

@Component({
  selector: 'achievement-notification',
  templateUrl: './achievement-notification.component.html',
  styleUrls: ['./achievement-notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AchievementNotificationComponent implements OnInit, OnDestroy {
  timeoutID; // Used to set the timeout globally to use in OnDestroy
  audio: any; // Used to set audio to play and for OnDestroy to remove audio on close. Or it keeps playing.
  /*
    Data is passed up in AchievementNotificationPopupData object:
    data: {
      title: 'Name of  achievement',
      score: 'If there is a score for this achievement',
      rare: 'If it is a rare achievement',
      display_time: 'Amount of time achievement is displayed',
      mute_audio: 'Is user wants sound to play'
    }
  */
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: AchievementNotificationPopupData,
    private _snackBar: MatSnackBar
  ) { }


  ngOnInit() {
    this.doAnimation();
  }


  ngOnDestroy(): void {
    // destroy audio here so sound does not continue after closing. Which it does if not destroyed here.
    if(this.audio) {
      this.audio.pause();
      this.audio = null;
    }

    clearTimeout(this.timeoutID); // Stop animation
  }


  doAnimation() {
    // console.log('Data: ', this.data);

    const normalAchievementSound: string = '/assets/sounds/normalAchievementXboxSound.mp3'; // Sound to be played for normal achievements.
    const rareAchievementSound: string = '/assets/sounds/rareAchievementXboxSound.mp3'; // Sound to be played for rare achievements.

    // Setting elements to use for adding or remove classList classes. Can use in check to stop errors if not found, especially in setTimeout if user closed snackbar early.
    const achievementElement = document.querySelector('.achievement');
    const circleElement = document.querySelector('.circle');
    const bannerElement = document.querySelector('.banner');
    const achieveDispElement = document.querySelector('.achieve_disp');

    if (this.data.rare && achievementElement != undefined) achievementElement.classList.add('rare'); // If rare then add the rare class here.

    // If not muted audio, then set up audio object using one of the sound sources and play it.
    if (!this.data.mute_audio) {
        this.audio = new Audio();
        this.audio.src = (this.data.rare) ? rareAchievementSound : normalAchievementSound;
        this.audio.volume = 0.5; // adjusting volume because it is way too loud.
        this.audio.load();
        this.audio.play(); // auto-start.
    }

    // Hide these and timeout stuff if we want to debug styles without animation collapsing stuff.
    if (circleElement != undefined) circleElement.classList.add('circle_animate');
    if (bannerElement != undefined) bannerElement.classList.add('banner-animate');
    if (achieveDispElement != undefined) achieveDispElement.classList.add('achieve_disp_animate');

    this.timeoutID = setTimeout(() => {
        // console.log('Removing classes')
        if (circleElement != undefined) circleElement.classList.remove('circle_animate');
        if (bannerElement != undefined) bannerElement.classList.remove('banner-animate');
        if (achieveDispElement != undefined) achieveDispElement.classList.remove('achieve_disp_animate');
        if (this.data.rare && achievementElement != undefined) achievementElement.classList.remove('rare');
        this.dismissNotification(); // Closing snackbar
    }, this.data.display_time);

    // Show these to keep animation expanded 
    // if (circleElement != undefined) circleElement.classList.add('circle_show');
    // if (bannerElement != undefined) bannerElement.classList.add('banner-show');
    // if (achieveDispElement != undefined) achieveDispElement.classList.add('achieve_disp_show');
  }


  dismissNotification() {
    this._snackBar.dismiss();
  }
}