import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        public snackBar: MatSnackBar,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log('Handling Error: ', err);

            /*
            this.snackBar.open('Your session has expired', 'Logged Out', {
                duration: 6000
            });
            */

            // window.location.href = window.location.href + '?' + new Date().getMilliseconds();

            if (err.status === 500) {
                // this.snackBar.open('Internal Server Error', 'ERROR', {duration: 6000});   
            } else if (err.status === 401 || err.status === 403) {
                // auto logout if 401 response returned from api
                // console.log('401 error detected. Logging out and reloading');
                this.snackBar.open('Your session has expired', 'Logged Out', { duration: 6000 });
                this.router.navigate(['/login']);
                // window.location.href = window.location.href + '?' + new Date().getMilliseconds();
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}