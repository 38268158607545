import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';
import { EmailAddress } from '../models/emailAddress';

@Injectable({ providedIn: 'root' })
export class EmailAddressService {
  private API_URL = '/api/email-addresses/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder
  ) { }

  get(_id: string): Observable<EmailAddress> {
    return this.httpClient.get<EmailAddress>(this.API_URL + _id);
  }  

  getAll(): Observable<EmailAddress[]> {
    return this.httpClient.get<EmailAddress[]>(this.API_URL);
  }  

  create(_emailAddress: EmailAddress): Observable<EmailAddress> {
    return this.httpClient.post<EmailAddress>(this.API_URL, JSON.stringify(_emailAddress), this.headerOptions);
  } 

  update(_emailAddress: EmailAddress): Observable<EmailAddress> {
    return this.httpClient.put<EmailAddress>(`${this.API_URL}${_emailAddress._id}`, JSON.stringify(_emailAddress), this.headerOptions);
  }  

  updateAllEmailRecords(_moduleName, _recordId, _emailAddresses): Observable<any> {
    const emailsUpdateRequest = {module: _moduleName, record: _recordId, emailAddresses: _emailAddresses};
    return this.httpClient.post<any>(this.API_URL + 'update-email-addresses', JSON.stringify(emailsUpdateRequest), this.headerOptions);
  }

  delete(id: string): Observable<EmailAddress> {
    return this.httpClient.delete<EmailAddress>(this.API_URL + id);
  }    

  search(searchTerms): Observable<EmailAddress[]> {
    return this.httpClient.post<EmailAddress[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }

  getEmailsForRecord(searchTerms): Observable<EmailAddress[]> {
    return this.httpClient.post<EmailAddress[]>(this.API_URL + 'get-emails-for-record', JSON.stringify(searchTerms), this.headerOptions);
  }


  addressFormGroup(formData: EmailAddress) {   
    return this.formBuilder.group({
      email_address: [formData.email_address, Validators.required],
      invalid_email: [formData.invalid_email],
      opt_out: [formData.opt_out],
      deleted: [formData.deleted]
    });
  }  


  // utilities
  getEmailFromRecord(_r) {
    const emails = (_r.email_addresses && _r.email_addresses.length) ? _r.email_addresses : null;
    console.log('Emails: ', emails);

    let recordEmail = null;

    if (emails && emails.length) {
      const primaryEmail = emails.find(_e => _e && _e.primary);
      recordEmail = (primaryEmail) ? primaryEmail : emails.find(_e => _e && !_e.invalid_email && !_e.opt_out);
    }

    return recordEmail;
    // return (recordEmail && recordEmail.email_address) ? recordEmail.email_address : recordEmail;
  }

}
