import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

import * as moment from 'moment-mini-ts';

import { LeaderboardViewerDialogComponent } from '@app/modules/dialogs/leaderboard-viewer-dialog/leaderboard-viewer-dialog.component';
import { GameRewardsViewerDialogComponent } from '@app/modules/dialogs/game-rewards-viewer-dialog/game-rewards-viewer-dialog.component';
import { CreditsNotificationDialogComponent } from '@app/modules/dialogs/credits-notification-dialog/credits-notification-dialog.component';
import { Game, GameInstance, GameItemBucket, GamePlayerState, GameSession, LeaderboardItem, LeaderboardItemMember, User } from '@app/models';
import { ArcadeDataService, GameActivityService, GameInstanceService, GameService, GameSessionService, SharedUtilsService } from '.';

@Injectable({ providedIn: 'root' })
export class GameControllerService {
  arcadeTypeOptions: any[] = [];
  defaultGameBg: string = '';

  constructor(
    private _arcadeDataService: ArcadeDataService,
    private _gameActivityService: GameActivityService,
    private _gameInstanceService: GameInstanceService,
    private _gameService: GameService,
    private _gameSessionService: GameSessionService,
    private _sharedUtilsService: SharedUtilsService,
    public _matDialog: MatDialog,
  ) {
    this.arcadeTypeOptions = this._gameService.arcadeTypeOptions;
    this.defaultGameBg = this._gameService.defaultGameCardBg;
  }


  subtractCreditsFromUserSession(_userSession: GameSession, _amountToRemove: number): Promise<any> {
    return new Promise(async (resolve) => {
      if (_userSession != undefined && _userSession._id != undefined) {
        if (_userSession.available_credits == undefined) _userSession.available_credits = 0;
        if (_userSession.used_credits == undefined) _userSession.used_credits = 0;

        let creditsAfterSubtraction: number = _userSession.available_credits - _amountToRemove;
        if (creditsAfterSubtraction < 0) creditsAfterSubtraction = 0;

        _userSession.available_credits = creditsAfterSubtraction;
        _userSession.used_credits += _amountToRemove;

        await lastValueFrom(this._gameSessionService.update(_userSession));
        this._gameInstanceService.announceInstanceChange(null); // We want credits for this game in sidebar game center to be updated.
      }

      resolve(null);
    });
  }


  assignArcadePoints(_instance: GameInstance, _userSession: GameSession, _currentUser: User, _pointsToReward: number): Promise<any> {
    return new Promise(async (resolve) => {
      if ((_instance != undefined && _instance._id != undefined) && (_userSession != undefined && _userSession._id != undefined) && (_currentUser != undefined && _currentUser._id != undefined)) {
        if (_userSession.points == undefined) _userSession.points = 0;
        _userSession.points += _pointsToReward;

        await lastValueFrom(this._gameSessionService.update(_userSession));

        // Create game activity to show that user has received points.
        await this.createGameActivityForPointsAwarded(_instance, _currentUser, _pointsToReward);

        this._gameInstanceService.announceInstanceChange(null); // We want activities and leaderboard for this game in sidebar game center to be updated.
      }

      resolve(null);
    });
  }


  createGameActivityForPointsAwarded(_instance: GameInstance, _userReceivingPoints: User, _pointsReceived: number): Promise<any> {
    return new Promise(async (resolve) => {
      if ((_instance != undefined && _instance._id != undefined) && (_userReceivingPoints != undefined && _userReceivingPoints._id != undefined)) {
        const gameName: string = (_instance.game != undefined && _instance.game.name != undefined) ? this._sharedUtilsService.titleCaseString(_instance.game.name) : 'a game'

        const pointText: string = (_pointsReceived === 1) ? 'point' : 'points';
        const messageForActivity: string = `just played ${gameName} and earned ${_pointsReceived} ${pointText}.`;

        await this._gameActivityService.buildAndCreateGameActivity(_instance, _userReceivingPoints, messageForActivity);
      }

      resolve(null);
    });
  }


  addNonArcadeFieldsToSessions(_instance: GameInstance, _sessionsToAppend: GameSession[] = []): Promise<GameSession[]> {
    return new Promise(async (resolve) => {
      const appendedSessions: GameSession[] = [];
      const instanceCurrentState: GamePlayerState[] = (_instance != undefined && _instance.current_state != undefined && _instance.current_state.length) ? _instance.current_state : [];
      const instanceResults: any[] = (_instance != undefined && _instance.results != undefined && _instance.results.length) ? _instance.results : [];
      const relatedGame: Game = (_instance != undefined && _instance.game != undefined) ? _instance.game : null;
      const goalSetOnGame: number = (relatedGame != undefined && relatedGame.goal != undefined) ? relatedGame.goal : 0;

      // If there is a multiplier do calc to get goal, else set it to one set on game.
      const goalAmountForGame: number = (relatedGame != undefined && relatedGame.progressMultiplier != undefined && relatedGame.progressMultiplier > 0) ? goalSetOnGame * relatedGame.progressMultiplier : goalSetOnGame;

      // Build LeaderboardItems and push to array that will be passed to leaderboard component.
      const appendToSessionsPromise = _sessionsToAppend.map(async (_session, index) => {
        if (_session.user != undefined && _session.user._id != undefined) {
          const usersGameResults = instanceResults.find((_cs) => _cs.user != undefined && _cs.user._id != undefined && _cs.user._id === _session.user._id);
          const usersCurrentState = instanceCurrentState.find((_cs) => _cs.user_id != undefined && _cs.user_id === _session.user._id);

          _session.last_name = (_session.user.last_name != undefined) ? _session.user.last_name : null;
          _session.positionFinished = (usersGameResults != undefined && usersGameResults['finishedPosition'] != undefined) ? usersGameResults['finishedPosition'] : 0;
          _session.currentPosition = (usersCurrentState != undefined && usersCurrentState['current_tile'] != undefined) ? usersCurrentState['current_tile'] : 0;

          if (_session.currentPosition > goalAmountForGame) _session.currentPosition = goalAmountForGame; // If the currentPosition is greater than goal set, then set it to that.
        }

        appendedSessions.push(_session);
      });

      Promise.all(appendToSessionsPromise).then(() => {
        // console.log('Appended Sessions:', appendedSessions);
        resolve(appendedSessions);
      });
    });
  }


  buildNonArcadeLeaderboardItems(_instance: GameInstance): Promise<LeaderboardItem[]> {
    return new Promise(async (resolve) => {
      const leaderboardItems: LeaderboardItem[] = [];

      const sessions: GameSession[] = (_instance != undefined && _instance.game_sessions != undefined && _instance.game_sessions.length) ? _instance.game_sessions : [];

      // Pass array of fields to sort array by.
      const fieldSorter = (fields) => (a, b) => fields.map(_fieldName => {
        let sortDirection = 1;

        if (_fieldName[0] === '-') { sortDirection = -1; _fieldName = _fieldName.substring(1); }

        // console.log('_fieldName: ', _fieldName);
        // console.log('Comparing field in a: ', a[_fieldName]);
        // console.log('To field in b: ', b[_fieldName]);

        return a[_fieldName] > b[_fieldName] ? sortDirection : a[_fieldName] < b[_fieldName] ? -(sortDirection) : 0;
      }).reduce((p, n) => p ? p : n, 0);


      const clonedSessions: GameSession[] = (sessions.length) ? this._sharedUtilsService.deepClone(sessions) : [];
      const appendedSessions = await this.addNonArcadeFieldsToSessions(_instance, sessions);
      // console.log('Appended Sessions: ', appendedSessions);

      const sortedArray = appendedSessions
        .filter(_s => _s.user != undefined)
        .sort(fieldSorter(['-positionFinished', '-currentPosition', 'last_name']));

      // console.log('Sorted Array: ', sortedArray);

      // Build LeaderboardItems and push to array that will be passed to leaderboard component.
      const builtLeaderboardItemsPromise = sortedArray.map(async (_session, index) => {
        if (_session.user != undefined) {
          const profileImage = (_session.user.userProfile != undefined && _session.user.userProfile.avatar != undefined) ? _session.user.userProfile.avatar : null;

          let userName = '';
          if (_session.user.first_name != undefined) userName += _session.user.first_name;
          if (_session.user.last_name != undefined) userName += ' ' + _session.user.last_name;

          const newLeaderBoardItem = new LeaderboardItem();
          newLeaderBoardItem.rank = index + 1;
          newLeaderBoardItem.image = profileImage;
          newLeaderBoardItem.ownerId = (_session.user._id != undefined) ? _session.user._id : null;
          newLeaderBoardItem.title = (userName !== '') ? userName : 'Unknown User';
          newLeaderBoardItem.score = (_session.currentPosition != undefined) ? _session.currentPosition : 0;

          leaderboardItems.push(newLeaderBoardItem);
        }
      });

      Promise.all(builtLeaderboardItemsPromise).then(() => {
        // console.log('Leaderboard Items:', leaderboardItems);
        resolve(leaderboardItems);
      });
    });
  }


  buildTeamArcadeLeaderboardItems(_instance: GameInstance): Promise<LeaderboardItem[]> {
    return new Promise(async (resolve) => {
      const leaderboardItems: LeaderboardItem[] = [];

      const teams = (_instance.game_teams != undefined && _instance.game_teams.length) ? _instance.game_teams : [];
      const sessions = (_instance.game_sessions != undefined && _instance.game_sessions.length) ? _instance.game_sessions : [];

      // Build LeaderboardItems and push to array that will be passed to leaderboard component.
      const builtLeaderboardItemsPromise = teams.map(async (_team, index) => {
        let teamScore = 0;
        const memberItems: LeaderboardItemMember[] = [];

        // If members create a new LeaderboardItem for each, add it to them to memberItems array and add the score to team score so it can be sorted.
        if (_team.members != undefined && _team.members.length) {
          _team.members.forEach(_user => {
            // See if there is a session in instance for this user.
            const matchingSession: GameSession = sessions.find((session) => (session.user != undefined && session.user._id != undefined) && session.user._id === _user._id);
            // console.log("Matching Session: ", matchingSession)

            // Build name for user.
            let userName = '';
            if (_user.first_name != undefined) userName += _user.first_name;
            if (_user.last_name != undefined) userName += ' ' + _user.last_name;

            // Create new leaderboard item member.
            const memberItem = new LeaderboardItemMember();
            memberItem.ownerId = (_user._id != undefined) ? _user._id : null;
            memberItem.name = (userName !== '') ? userName : 'Unknown User';
            memberItem.score = (matchingSession != undefined && matchingSession.points != undefined) ? matchingSession.points : 0;
            memberItem.credits = (matchingSession != undefined && matchingSession.available_credits != undefined) ? matchingSession.available_credits : 0;

            teamScore += memberItem.score; // Add members score to team score.

            memberItems.push(memberItem); // Push member to array
          });
        }

        // Build and push new leaderboard item for this team.
        const newLeaderBoardItem = new LeaderboardItem();
        newLeaderBoardItem.rank = index + 1;
        newLeaderBoardItem.image = null;
        newLeaderBoardItem.title = (_team.name != undefined && _team.name !== '') ? _team.name : 'Unknown Team';
        newLeaderBoardItem.score = teamScore;
        newLeaderBoardItem.members = memberItems.sort((a, b) => b.score - a.score);

        leaderboardItems.push(newLeaderBoardItem);
      });

      Promise.all(builtLeaderboardItemsPromise).then(() => {
        // Sort LeaderboardItem array by score to show order from highest to lowest.
        const sortedArray: LeaderboardItem[] = leaderboardItems.sort((a, b) => b.score - a.score);
        // console.log('Leaderboard Items:', sortedArray);

        resolve(sortedArray);
      })
    });
  }


  buildUserArcadeLeaderboardItems(_sessions: GameSession[]): Promise<LeaderboardItem[]> {
    return new Promise(async (resolve) => {
      const leaderboardItems: LeaderboardItem[] = [];

      if (_sessions == undefined) _sessions = [];

      const sortedArray = _sessions.sort((a, b) => b.points - a.points);

      // Build LeaderboardItems and push to array that will be passed to leaderboard component.
      const builtLeaderboardItemsPromise = sortedArray.map(async (_session, index) => {
        if (_session.user != undefined) {
          const profileImage = (_session.user.userProfile != undefined && _session.user.userProfile.avatar != undefined) ? _session.user.userProfile.avatar : null;

          let userName = '';
          if (_session.user.first_name != undefined) userName += _session.user.first_name;
          if (_session.user.last_name != undefined) userName += ' ' + _session.user.last_name;

          const newLeaderBoardItem = new LeaderboardItem();
          newLeaderBoardItem.rank = index + 1;
          newLeaderBoardItem.image = profileImage;
          newLeaderBoardItem.ownerId = (_session.user._id != undefined) ? _session.user._id : null;
          newLeaderBoardItem.title = (userName !== '') ? userName : 'Unknown User';
          newLeaderBoardItem.score = (_session.points != undefined) ? _session.points : 0;
          newLeaderBoardItem.credits = 0;

          leaderboardItems.push(newLeaderBoardItem);
        }
      });

      Promise.all(builtLeaderboardItemsPromise).then(() => {
        // console.log('Leaderboard Items:', leaderboardItems);
        resolve(leaderboardItems);
      })
    });
  }


  shouldShowTeamLeaderboard(_instance: GameInstance): Promise<boolean> {
    return new Promise(async (resolve) => {
      let isTeamLeaderboard: boolean = false;

      if (_instance != undefined && _instance._id != undefined && _instance.game != undefined) {
        const templateKey: string = (_instance.game.templateKey != undefined) ? _instance.game.templateKey : '';
        if (templateKey === 'arcade' && (_instance.game_teams != undefined && _instance.game_teams.length)) {
          isTeamLeaderboard = true; // Set to team-leaderboard to show team leaderboard
        }
      }

      resolve(isTeamLeaderboard);
    });
  }


  getLeaderboardItemsForInstance(_instance: GameInstance): Promise<LeaderboardItem[]> {
    return new Promise(async (resolve) => {
      let leaderboardItems: LeaderboardItem[] = [];

      if (_instance != undefined && _instance._id != undefined && _instance.game != undefined) {
        const templateKey: string = (_instance.game.templateKey != undefined) ? _instance.game.templateKey : '';
        if (templateKey === 'arcade') {
          if (_instance.game_teams != undefined && _instance.game_teams.length) {
            leaderboardItems = await this.buildTeamArcadeLeaderboardItems(_instance);
          } else {
            if (_instance.game_sessions == undefined) _instance.game_sessions = [];
            leaderboardItems = await this.buildUserArcadeLeaderboardItems(_instance.game_sessions);
          }
        } else {
          leaderboardItems = await this.buildNonArcadeLeaderboardItems(_instance);
        }
      }

      resolve(leaderboardItems);
    });
  }


  async showLeaderboard(_instance: GameInstance) {
    let leaderboardItems: LeaderboardItem[] = [];
    let leaderboardMode: string = null;

    if (_instance != undefined && _instance._id != undefined && _instance.game != undefined) {
      const templateKey: string = (_instance.game.templateKey != undefined) ? _instance.game.templateKey : '';
      if (templateKey === 'arcade') {
        if (_instance.game_teams != undefined && _instance.game_teams.length) {
          leaderboardMode = 'team-leaderboard'; // Set to team-leaderboard to show team leaderboard

          leaderboardItems = await this.buildTeamArcadeLeaderboardItems(_instance);
        } else {
          if (_instance.game_sessions == undefined) _instance.game_sessions = [];
          leaderboardItems = await this.buildUserArcadeLeaderboardItems(_instance.game_sessions);
        }
      } else {
        leaderboardItems = await this.buildNonArcadeLeaderboardItems(_instance);
      }
    }

    const dialogRef = this._matDialog.open(LeaderboardViewerDialogComponent, { width: '95%', maxWidth: '95vw' });
    const instance = dialogRef.componentInstance;
    instance.leaderboardItemsPassedIn = leaderboardItems;
    instance.useScoreWithoutPipe = true;
    instance.leaderboardMode = leaderboardMode;

    await lastValueFrom(dialogRef.afterClosed());
  }


  async showGameRewardsDialog(_instance: GameInstance) {
    const dialogRef = this._matDialog.open(GameRewardsViewerDialogComponent, { width: '70%' });
    const instance = dialogRef.componentInstance;
    instance.gameInstancePassedIn = _instance;

    await lastValueFrom(dialogRef.afterClosed());
  }


  // Pass credits and if we want a different message than the default one pass it. Default message is : You received {{ _credits }} credits.
  async showCreditsNotificationDialog(_credits: number, _message: string = null) {
    const dialogRef = this._matDialog.open(CreditsNotificationDialogComponent, { width: '250px', maxWidth: '95vw', panelClass: 'credit-notification-dialog-panel' });
    const instance = dialogRef.componentInstance;
    instance.creditAmount = _credits;
    instance.messagePassedIn = _message;

    await lastValueFrom(dialogRef.afterClosed());
  }


  buildGameCardItemBuckets(_instanceArrayPassedIn: GameInstance[], currentUser: User): Promise<GameItemBucket[]> {
    return new Promise(async (resolve) => {
      const gameItemBuckets: GameItemBucket[] = []; // Empty array.

      // If active games setup buckets
      if (_instanceArrayPassedIn != undefined && _instanceArrayPassedIn.length) {
        const today = moment();

        for (let index = 0; index < _instanceArrayPassedIn.length; index++) {
          const instance = _instanceArrayPassedIn[index];

          // If no game then we don't want to create bucket because the games need this so data is correct.
          if (instance.game != undefined && instance.game.templateKey != undefined) {
            const userId: string = (currentUser != undefined && currentUser._id != undefined) ? currentUser._id : null; // Make sure user id is present for session check.
            let titleForCard: string = null;
            let subTitleForCard: string = null;
            let categoryForCard: string = null;
            let imageForCard: string = this.defaultGameBg;
            let creditsAvailable: number = 0;
            let gameHasEnded: boolean = false;
            let gameInstructions: string = null;

            // If arcade we need to do some extra stuff to get data. Else it is a regular game and we can just set the needed data from populated game.
            if (instance.game.templateKey === 'arcade') {
              if (instance.game.arcadeType != undefined) {
                const matchingGame = this.arcadeTypeOptions.find((_option) => _option.value != undefined && _option.value === instance.game.arcadeType);

                // If option was found and has label use it. Else just add the type to the title string.
                titleForCard = (matchingGame != undefined && matchingGame.label != undefined) ? matchingGame.label : `Arcade (${instance.game.arcadeType})`;

                subTitleForCard = (instance.game.name != undefined) ? instance.game.name : null;
                categoryForCard = 'arcade-game';

                // If option was found and has link to image assign it.
                if (matchingGame != undefined && matchingGame.backgroundImage != undefined) imageForCard = matchingGame.backgroundImage;

                // If option was found and has game instructions assign them.
                if (matchingGame != undefined && matchingGame.instructions != undefined) gameInstructions = matchingGame.instructions;
              }

              // See if we can find a session for this user and assign their available credits if one is found.
              if (userId != undefined && (instance.game_sessions != undefined && instance.game_sessions.length)) {
                const foundUserSession: GameSession = instance.game_sessions.find((_session) => _session.user != undefined && ((_session.user._id != undefined && _session.user._id === userId) || _session.user === userId));
                creditsAvailable = (foundUserSession != undefined && foundUserSession.available_credits != undefined) ? foundUserSession.available_credits : 0;
              }
            } else {
              titleForCard = (instance.game.name != undefined) ? instance.game.name : null;
              categoryForCard = 'board-game';
              if (instance.game.background_image != undefined) imageForCard = instance.game.background_image;
            }

            // Get set end_date as moment to compare with today
            const gameEndDateMoment = (instance.end_date != undefined) ? moment(instance.end_date) : null;
            // console.log('End date as moment: ', gameEndDateMoment);
            // console.log('Game has ended: ', (gameEndDateMoment != undefined && today.isAfter(gameEndDateMoment)));

            // See if date_completed was set which means game was processed and if end_date is before today which means it has ended and should not allow user to play game.
            if (instance.date_completed != undefined || (gameEndDateMoment != undefined && today.isSameOrAfter(gameEndDateMoment))) gameHasEnded = true;

            const gameCompletionDate: Date = (instance.date_completed != undefined) ? instance.date_completed : (instance.end_date != undefined) ? instance.end_date : new Date();

            const newGameItemBucket = new GameItemBucket();
            newGameItemBucket.bucket_id = instance._id;
            newGameItemBucket.title = titleForCard;
            newGameItemBucket.sub_title = subTitleForCard;
            newGameItemBucket.category = categoryForCard;
            newGameItemBucket.imageForGame = imageForCard;
            newGameItemBucket.credits = creditsAvailable;
            newGameItemBucket.gameCompleted = gameHasEnded;
            newGameItemBucket.completionDate = gameCompletionDate;
            newGameItemBucket.instanceRef = instance;
            newGameItemBucket.gameInstructions = gameInstructions;

            gameItemBuckets.push(newGameItemBucket);
          }
        }
      }

      resolve(gameItemBuckets);
    });
  }


  buildAvailableGameTypeCardItemBuckets(_isFreePlay: boolean): Promise<GameItemBucket[]> {
    return new Promise(async (resolve) => {
      const availableGameItemBuckets: GameItemBucket[] = []; // Empty array.

      let backupLabel: string = (_isFreePlay) ? 'Arcade Free Play' : 'Available Game';

      // If active games setup buckets
      if (this.arcadeTypeOptions != undefined && this.arcadeTypeOptions.length) {
        for (let index = 0; index < this.arcadeTypeOptions.length; index++) {
          const arcadeOption = this.arcadeTypeOptions[index];

          // Find arcade data in db for this game to show stuff like highscore. If found attach it.
          const foundArcadeData = (arcadeOption.value != undefined) ? await lastValueFrom(this._arcadeDataService.getByArcadeKey(arcadeOption.value)) : null;

          const newGameItemBucket = new GameItemBucket();
          newGameItemBucket.bucket_id = (arcadeOption.value != undefined) ? arcadeOption.value : this._sharedUtilsService.createObjectId();
          newGameItemBucket.title = (arcadeOption.label != undefined) ? arcadeOption.label : backupLabel;
          newGameItemBucket.sub_title = null;
          newGameItemBucket.category = (_isFreePlay) ? 'free-play' : 'available-game';
          newGameItemBucket.imageForGame = (arcadeOption.backgroundImage != undefined) ? arcadeOption.backgroundImage : this.defaultGameBg;
          newGameItemBucket.credits = 0;
          newGameItemBucket.gameCompleted = false;
          newGameItemBucket.completionDate = null;
          newGameItemBucket.instanceRef = null;
          newGameItemBucket.freePlayPath = (_isFreePlay && arcadeOption.value != undefined) ? arcadeOption.value : null;
          newGameItemBucket.arcadeData = (foundArcadeData != undefined) ? foundArcadeData : null;
          newGameItemBucket.gameInstructions = (arcadeOption.instructions != undefined) ? arcadeOption.instructions : null;

          availableGameItemBuckets.push(newGameItemBucket);
        }
      }

      resolve(availableGameItemBuckets);
    });
  }
}