<div class="flex-wrapper mint-snackbar-wrapper wrapper-{{ data.class }}" matTooltip="Click to Dismiss" (click)="dismissMessage()">
  <img data-notify="icon" width="150px" class="img-circle pull-left" src="/assets/images/yeehro-x-icon.png" *ngIf="data.class === 'stealth'">

  <div class="title" *ngIf="data.title">
    {{ data.title }}
  </div>

  <div class="main-message-content {{ data.class }}">
    {{ data.message }}
  </div>

  <div *ngIf="data.class !== 'stealth'" class="message-icon">
    <img src="/assets/images/yeehro-x-icon.png" height="50px" />
  </div>

<!--   <div class="close-icon default-flex">
    <span class="material-icons">clear</span>
  </div> -->
</div>