import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { VoiceShot } from '@app/models';

@Component({
  selector: 'app-voiceshot-dialog',
  templateUrl: './voiceshot-dialog.component.html',
  styleUrls: ['./voiceshot-dialog.component.scss']
})
export class VoiceshotDialogComponent {
  @Input() recipients: any;
  @Input() availableVoiceshots: VoiceShot[];

  chosenVoiceshot: VoiceShot;
  sanitizedUrl;

  constructor(
    public dialogRef: MatDialogRef<VoiceshotDialogComponent>,
    private sanitizer: DomSanitizer
  ) { }


  voiceshotSelected(_voiceshot) {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.chosenVoiceshot?.path + this.chosenVoiceshot?.filename);
  }


  getSantizeUrl() {
    return this.sanitizer.bypassSecurityTrustUrl(this.chosenVoiceshot?.path + this.chosenVoiceshot?.filename);
  }


  closeDialog(_confirmed) {
    return this.dialogRef.close({
      confirmed: _confirmed,
      voiceshotChosen: this.chosenVoiceshot
    });
  }
}