import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-action-dialog',
  templateUrl: './confirm-action-dialog.component.html'
})
export class ConfirmActionDialogComponent implements OnInit {
  @Input() items: any;
  @Input() textToDisplay: string;
  @Input() title: string;

  @Input() buttonsInfo: any;

  confirmationResult = false;

  constructor(public dialogRef: MatDialogRef<ConfirmActionDialogComponent>) { }

  ngOnInit() {}

  confirmDelete() {
    this.confirmationResult = true;
    this.closeDialog();
  }

  unconfirmDelete() {
    this.confirmationResult = false;
    this.closeDialog();
  }

  closeDialog() {
    return this.dialogRef.close(this.confirmationResult);
  }

}
