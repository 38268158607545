import { Injectable } from '@angular/core';

import * as moment from 'moment-mini-ts';

@Injectable({ providedIn: 'root' })
export class TimeService {

  constructor() { }

  timeFromNow(_date: Date): string {
    return moment(_date).fromNow();
  }
}