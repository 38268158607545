export class InteractiveDocumentInstance {
  _id: string;
  name: string;
  description: string;
  status: string;
  interactive_document;
  saveAsTemplate: boolean;
  relatedRecord: { // lead, account, etc.
    recordName: string,
    recordId: string,
    email: string
  };
  relatedModule: { // email, sms, call, etc.
    moduleType: string,
    moduleRecordId: string
  };
  completeInOrder: boolean;
  recipients: any;
  // recipients: [
  //   {
  //     recipientId: number,
  //     recipientType: string,
  //     fromType: string,
  //     email: string
  //   }
  // ];
  doc_data: any;
  path: any;
  actions: [];
  values;
  client: any;
  dateSigned: Date;
  created_by: any;
  modified_by: any;
  assigned_to: any;
  deleted: boolean;  
  createdAt: Date;
  updatedAt: Date;
  hasSearchTerms: any;
}
