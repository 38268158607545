import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { SocialConversation } from '@app/models';

export interface SocialConversationApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class SocialConversationService {
    public  socialSectionEnabled: boolean = false; // Disable if this is being worked on
    private currentSocialConversation;

    private conversationObs$ = new Subject<SocialConversation>();
    private conversationFeedReset$ = new Subject<any>();
    private applyFeedSearch$ = new Subject<any>();

    private API_URL = '/api/social-conversations/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    public currentSearchId;

    constructor(
        private httpClient: HttpClient
    ) { }

    setCurrent(_conversation: SocialConversation): void {
        this.currentSocialConversation = _conversation;
    }


    getCurrent() {
        return this.currentSocialConversation;
    }


    conversationChange() {
        return this.conversationObs$;
    }


    announceConversation(conversation: SocialConversation) {
        // console.log('Change detected in Conversations! Announcing to all listening..');
        this.conversationObs$.next(conversation);
    }


    listenForFeedReset() {
        return this.conversationFeedReset$;
    }


    resetConversationFeed() {
        // console.log('Change detected in Conversations! Announcing to all listening..');
        this.conversationFeedReset$.next(null);
    }


    listenForFeedSearch() {
        return this.applyFeedSearch$;
    }


    applyFeedSearch(_searchTerms) {
        this.applyFeedSearch$.next(_searchTerms);
    }


    getAll(): Observable<SocialConversation[]> {
        return this.httpClient.get<SocialConversation[]>(this.API_URL);
    }


    get(_id: string): Observable<SocialConversation> {
        return this.httpClient.get<SocialConversation>(this.API_URL + _id);
    }


    create(_socialConversation): Observable<SocialConversation> {
        return this.httpClient.post<SocialConversation>(this.API_URL, JSON.stringify(_socialConversation), this.headerOptions);
    }


    createSocialConversationsForAchievements(_user, _achievements): Observable<any> {
        const _createData = { user: _user, achievements: _achievements };
        return this.httpClient.post<any>(this.API_URL + 'social-achievement-conversation-create', JSON.stringify(_createData), this.headerOptions);
    }


    search(searchTerms): Observable<SocialConversation[]> {
        return this.httpClient.post<SocialConversation[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<SocialConversationApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<SocialConversationApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<SocialConversationApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<SocialConversationApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    update(_socialConversation: SocialConversation): Observable<SocialConversation> {
        return this.httpClient.put<SocialConversation>(`${this.API_URL}${_socialConversation._id}`, JSON.stringify(_socialConversation), this.headerOptions);
    }


    updateActivityDate(_conversationId: string): Observable<SocialConversation> {
        return this.httpClient.put<SocialConversation>(this.API_URL + 'update-activity-date/' + _conversationId, this.headerOptions);
    }


    markDeleted(id: string): Observable<any> {
        return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
    }


    markDeletedByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = { ids: arrayOfIds };
        return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    delete(_id: string): Observable<SocialConversation> {
        return this.httpClient.delete<SocialConversation>(this.API_URL + _id);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }
}