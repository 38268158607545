import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from "@angular/common";

import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

import { SharedUtilsService } from './shared-utils.service';
import { SsModuleService } from './ss-module.service';

@Injectable({ providedIn: 'root' })
export class HistoryMenuService implements OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  private _destroyed$ = new Subject<any>();
  private historyMenuToggledObs$ = new Subject<any>();

  public currentMenu;

  menuStorageKey: string = 'history-menu';
  historyMenuStorageKey: string = 'YeeHro CRM-history-menu';
  allModules;
  public lastModuleAndRecord;

  constructor(
    private _sharedService: SharedUtilsService,
    private _moduleService: SsModuleService,
    private router: Router,
    private location: Location
  ) {
    // this.listenForSectionChange();
  }  

  ngOnDestroy() {
    this._destroyed$.next(null);
    this._destroyed$.complete();
  }


  listenForSectionChange() {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(_routeEvent => {

      this._destroyed$.next(null);
      this._destroyed$.complete();      

      if (_routeEvent instanceof NavigationEnd) {
        // Navigation Ended Successfully.
        setTimeout(() => {
          // console.log('NAVIGATION END route event. Loading last page to history.');
          this.loadPageToHistory(_routeEvent);
        }, 1000);
      }
    });      
  }


  removeInvalidItems(_itemState) {
    // Remove this history item. It is no longer valid. Could have been created since
    this.currentMenu.splice(this.currentMenu.findIndex(_item => _item.visitedAt === _itemState.visitedAt), 1);
  }


  addToMenu(_item) {
    const lastRecordInfo = _item;
    // console.log('Last Record Info: ', lastRecordInfo);

    // const newMenuItem = {
    //   url: this.router.url,
    //   module: {
    //     name: this.fullModule.name,
    //     label: this.fullModule.label,
    //     icon: this.fullModule.moduleIcon,
    //     iconType: this.fullModule.moduleIconType,
    //     section: 'Accounts List'
    //   },
    //   record: null,
    //   wasEditingOrCreating: false
    // };

    if (!this.currentMenu) {
      this.currentMenu = this._sharedService.getFromLocal(this.menuStorageKey);
      // console.log('Current Menu loaded from local storage: ', this.currentMenu);
    }

    if (this.currentMenu && lastRecordInfo && (!this.currentMenu[0] || this.currentMenu[0].route !== lastRecordInfo.url)) {
      // console.log('Route action found for module: ', routeModuleInfo);
      // console.log('- lastRecordInfo.url: ', lastRecordInfo.url);
      // console.log(' - this.currentMenu[0].lastModuleAndRecord.route: ', this.currentMenu[0].route);

      const routeAction = {
        label: lastRecordInfo.module ? lastRecordInfo.module.label : '',
        moduleName: lastRecordInfo.module.name,
        route: lastRecordInfo.url,
        lastModuleAndRecord: lastRecordInfo,
        visitedAt: Date.now()
      };

      // console.log("%c***** PUSHING routeAction on to menu: ", "color: pink; font-size: 30px;");
      // console.log('*** PUSHING routeAction: ', routeAction);

      this.currentMenu.unshift(routeAction); // add to the front of the array
      // console.log('CURRENT MENU AFTER ADDING: ', this.currentMenu);

      // only keep a max of 50 pages in history
      if (this.currentMenu.length > 50) this.currentMenu.pop();

      this._sharedService.saveInLocal(this.menuStorageKey, this.currentMenu);
    }
  }


  loadPageToHistory(_routeEvent) {
    this.recheckMenu();

    // console.log('');
    // console.log('*********************** Loading Page to History ******************');
    // console.log('Current Menu: ', this.currentMenu);

    this.allModules = this._moduleService.allModules;

    // last module and record is called on record load. this function is called on route change.
    //  - Page gets added to menu stack when route changes. After page is destroyed, we get page/action details and any saved records we need

    // console.log('***** Last module and record: ', this.lastModuleAndRecord);
    // console.log('Last record in the stack: ', this.currentMenu[0]);

    if (this.allModules) {
      // console.log('Modules: ', this.allModules);

      const routePath = this.location.path();
      const routeModuleInfo = this.allModules.find(_am => routePath.includes(_am.name));

      // if (this.lastModuleAndRecord && this.currentMenu.length) {
      //   // set last record to this
      //   this.currentMenu[0].lastModuleAndRecord = this.lastModuleAndRecord;
      //   this.lastModuleAndRecord = null;
      // }

      const lastRecordInfo = this.lastModuleAndRecord;
      // console.log('Last Record Info: ', lastRecordInfo);

      if (lastRecordInfo && (!this.currentMenu[0] || (this.currentMenu[0] && this.currentMenu[0].route !== lastRecordInfo.url))) {
        // console.log('Route action found for module: ', routeModuleInfo);
        // console.log('- lastRecordInfo.url: ', lastRecordInfo.url);
        // console.log(' - this.currentMenu[0].lastModuleAndRecord.route: ', this.currentMenu[0].route);

        const routeAction = {
          label: lastRecordInfo.module ? lastRecordInfo.module.label : routePath,
          moduleName: lastRecordInfo.module.name,
          route: routePath,
          lastModuleAndRecord: lastRecordInfo,
          visitedAt: Date.now()
        };

        // console.log("%c***** PUSHING routeAction on to menu: ", "color: pink; font-size: 30px;");
        // console.log('*** PUSHING routeAction: ', routeAction);

        this.currentMenu.unshift(routeAction); // add to the front of the array
        // console.log('CURRENT MENU AFTER ADDING: ', this.currentMenu);
  
        // only keep a max of 50 pages in history
        if (this.currentMenu.length > 50) this.currentMenu.pop();
      } else {
        // console.log('Avoiding duplicate menu item');
        // console.log('lastRecordInfo: ', lastRecordInfo);
        // console.log('this.currentMenu[0]: ', this.currentMenu[0]);
      }
    }

    this._sharedService.saveInLocal(this.menuStorageKey, this.currentMenu);
    // console.log('Menu after unshift: ', this.currentMenu);
    this.triggerMenuToggle();
  }


  listenForHistoryMenuToggle() {
    return this.historyMenuToggledObs$;
  }

  triggerMenuToggle() {
    this.historyMenuToggledObs$.next(this.currentMenu);
  }


  recheckMenu() {
    if (!this.currentMenu || !this.currentMenu.length) {
      const menuFromStorage = this._sharedService.getFromLocal(this.menuStorageKey);
      // console.log('App reloaded. Get menu from storage: ', menuFromStorage);

      this.currentMenu = menuFromStorage ? menuFromStorage : [];
      this._sharedService.saveInLocal(this.menuStorageKey, this.currentMenu);
    }
  }


  resetMenu() {
    this.currentMenu = [];
    this.lastModuleAndRecord = null;
    this._sharedService.saveInLocal(this.menuStorageKey, this.currentMenu);
  }


  // showMenu() {
  //   this.recheckMenu();
    
  //   const historyMenuRef = this._bottomSheet.open(HistoryMenuComponent, {backdropClass: 'history-menu-backdrop', panelClass: 'history-menu-panel'});
  //   const instance = historyMenuRef.instance;

  //   instance.currentMenuItems = this.currentMenu;

  //   historyMenuRef.afterDismissed().pipe(takeUntil(this._destroyed$)).subscribe(_menu => {
  //     const menuAfterClosing = instance.currentMenuItems;

  //     // check if user cleared menu.
  //     if (menuAfterClosing && !menuAfterClosing.length) {
  //       // User cleared menu
  //       this.currentMenu = [];
  //       this.lastModuleAndRecord = null;
  //       this._sharedService.saveInLocal(this.menuStorageKey, this.currentMenu);
  //     }
  //   });
  // }

}
