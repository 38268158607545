import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { MenuNav } from '@app/models';

export interface MenuNavApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class MenuNavService {
    public  menuNavsEnabled: boolean = true; // Disable if this is being worked on
    private currentMenuNav;

    private API_URL = '/api/menu-navs/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }

    setCurrent(_menuNav): void {
        this.currentMenuNav = _menuNav;
    }


    getCurrent() {
        return this.currentMenuNav;
    }


    getAll(): Observable<MenuNav[]> {
        return this.httpClient.get<MenuNav[]>(this.API_URL);
    }


    get(_id: string): Observable<MenuNav> {
        return this.httpClient.get<MenuNav>(this.API_URL + _id);
    }


    getByClient(_clientId: string): Observable<MenuNav> {
        return this.httpClient.get<MenuNav>(this.API_URL + 'byClient/' + _clientId);
    }


    getNavByUserType(_userType: string): Observable<MenuNav> {
        return this.httpClient.get<MenuNav>(this.API_URL + 'user-type/' + _userType);
    }


    getEnabeldByClient(_clientId: string): Observable<MenuNav> {
        return this.httpClient.get<MenuNav>(this.API_URL + 'isEnabled/byClient/' + _clientId);
    }


    create(_menuNav): Observable<MenuNav> {
        return this.httpClient.post<MenuNav>(this.API_URL, JSON.stringify(_menuNav), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<MenuNavApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<MenuNavApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'createdAt', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<MenuNavApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<MenuNavApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    update(_menuNav: MenuNav): Observable<MenuNav> {
        return this.httpClient.put<MenuNav>(`${this.API_URL}${_menuNav._id}`, JSON.stringify(_menuNav), this.headerOptions);
    }


    delete(_id: string): Observable<MenuNav> {
        return this.httpClient.delete<MenuNav>(this.API_URL + _id);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    sortArrayByOrder(_arrayToSort): Promise<any[]> {
        return new Promise(async(resolve) => {
            let sortedUserDashArray = _arrayToSort.sort(this.sortByOrder);
            resolve(sortedUserDashArray);
        });
    }


    sortByOrder( a, b ) {
        if (a.options == undefined || a.options.order == undefined) return 1;
        else if (b.options == undefined || b.options.order == undefined) return -1;
        else if ( a.options.order < b.options.order ) return -1;
        else if ( a.options.order > b.options.order ) return 1;
        else return 0;
    }
}