import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { AccountCalendarEvent } from '@app/models';


@Injectable({ providedIn: 'root' })
export class AccountCalendarEventService {
  private currentAccountCalendarEventBeingViewed: AccountCalendarEvent;

  public colors = [
    /*
    {
      key: 'red',
      label: 'Red',
      primary: '#ad2121',
      secondary: '#FAE3E3'
    },
    */
    {
      key: 'orange',
      label: 'Orange',
      primary: '#FF6700',
      secondary: '#FF9045'
    },
    {
      key: 'yellow',
      label: 'Yellow',
      primary: '#e3bc08',
      secondary: '#FDF1BA'
    },        
    {
      key: 'green',
      label: 'Green',
      primary: '#3F7C15',
      secondary: '#74B945'
    },    
    {
      key: 'blue',
      label: 'Blue',
      primary: '#1e90ff',
      secondary: '#D1E8FF'
    },
    {
      key: 'purple',
      label: 'Purple',
      primary: '#8806CE',
      secondary: '#A849DB'
    },
    {
      key: 'gray',
      label: 'Gray',
      primary: '#757575',
      secondary: '#B3B3B3'
    }        
  ];

  public eventTypes = ['half day', 'teacher work day', 'holiday'];

  private API_URL = '/api/account-calendar-events/';
  headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(
    private httpClient: HttpClient
  ) { }


  setAccountCalendarEventBeingViewed(_event: AccountCalendarEvent): void {
    this.currentAccountCalendarEventBeingViewed = _event;
  }


  getAccountCalendarEventBeingViewed(): AccountCalendarEvent {
    return this.currentAccountCalendarEventBeingViewed;
  }


  getColorByKey(colorKey) {
    let districtColor = null;

    this.colors.forEach(_color => {
      if (_color.key === colorKey) {
        districtColor = _color;
        return districtColor;
      }
    });

    return districtColor;    
  }


  showStringAsTime(timeString) {
    if (!timeString) {
      return '';
    }

    const timeParts = timeString.split(':');

    let hour = parseInt(timeParts[0]);
    const minutes = timeParts[1];
    let amPm = 'AM';

    if (hour >= 12) {
      amPm = 'PM';
    } 

    if (hour > 12) {
      hour -= 12;
    }

    return `${hour}:${minutes} ${amPm}`;
  }


  getAll(): Observable<AccountCalendarEvent[]> {
    return this.httpClient.get<AccountCalendarEvent[]>(this.API_URL);
  }


  get(_eventId: string): Observable<AccountCalendarEvent> {
    return this.httpClient.get<AccountCalendarEvent>(this.API_URL + _eventId);
  } 


  create(_accountCalendarEvent: AccountCalendarEvent): Observable<AccountCalendarEvent> {
    return this.httpClient.post<AccountCalendarEvent>(this.API_URL, JSON.stringify(_accountCalendarEvent), this.headerOptions);
  }


  update(_accountCalendarEvent: AccountCalendarEvent): Observable<AccountCalendarEvent> {
    return this.httpClient.put<AccountCalendarEvent>(this.API_URL + _accountCalendarEvent._id, JSON.stringify(_accountCalendarEvent), this.headerOptions);
  }


  search(searchTerms): Observable<AccountCalendarEvent[]> {
    return this.httpClient.post<AccountCalendarEvent[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  searchForOne(searchTerms): Observable<AccountCalendarEvent> {
    return this.httpClient.post<AccountCalendarEvent>(this.API_URL + 'search-for-one', JSON.stringify(searchTerms), this.headerOptions);
  }


  deleteEvent(id: String): Observable<any> {
    return this.httpClient.delete(this.API_URL + id);
  }
}