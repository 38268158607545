import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Game } from '@app/models';

export interface GameApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class GameService {
    private currentGame;
    private gameInWizardForm;

    private API_URL = '/api/games/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    public appliesToOptions = [
        // {label: 'New and Updated Records', value: 'both'},
        {label: 'New Record Only', value: 'new'},
        {label: 'Updated Records Only', value: 'update'}
    ];

    public rewardTypeOptions = [
        {label: 'Coins', value: 'coins'},
        {label: 'Points', value: 'points'}
    ];

    public gameTypeOptions = [
        {label: 'GameBoard', value: 'gameboard'},
        {label: 'Arcade', value: 'arcade'}
    ];

    public arcadeTypeOptions = [
        {
            label: 'Dude Game',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/dude-game-icon.PNG',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/dude-game-background.PNG',
            instructions: `Collect the stars to gain points. 1 bomb will be added to the stage at the end of each level. 
                The game will end if you run into a bomb, or when time runs out. You can use the arrow keys or the w,a,s,d 
                keys to move your player around the stage. The up arrow, w key, or spacebar will cause player to jump. Hold 
                the button down a little longer to get more height.`,
            value: 'phaser-dude-game'
        },
        {
            label: 'Emoji Match',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/emoji-game-icon.PNG',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/emoji-game-background.PNG',
            instructions: `Match as many emoji as you can before the timer runs out. The timer starts when you make your first selection. 
                Emoji's are selected by using your mouse.`,
            value: 'phaser-emoji-match'
        },
        {
            label: 'Avoid The Germs',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/germs-game-icon.PNG',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/germs-game-background.PNG',
            instructions: `Avoid the Germs and collect the Rings. Collecting rings gives you points. The game will end if you 
                run into a germ, or when time runs out. The character is controlled by moving you mouse cursor around within the stage.`,
            value: 'phaser-avoid-the-germs'
        },
        {
            label: 'Bank Panic',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/bank-panic-game-icon.PNG',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/bank-panic-game-background.PNG',
            instructions: `Shoot enemies before they shoot back, and avoid shooting allies. When a door opens it can either be an enemy or an ally.
                If the character is holding a bag of money they are an ally, so don't shoot at them. The game ends if you shoot an ally, an 
                enemy shoots you before you kill them, or when time runs out. If you avoid shooting an ally, their bag of money 
                will be added to your collected cash when that door closes. After collecting 12 bags, the next level will start 
                and you repeat these steps. Points are awarded for each collected cash bag. Mouse click is used to shoot at 
                characters.`,
            value: 'phaser-bank-panic'
        },
        {
            label: 'Breakout',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/breakout-game-icon.PNG',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/breakout-game-background.PNG',
            instructions: `Destroy blocks to gain points. The blocks will reset when all blocks are destroyed. 
                The game ends if you miss the ball with your paddle, or when time runs out. Moving your mouse left and right 
                will move the paddle. Using the left mouse click will release the ball from the paddle at the start of the game.`,
            value: 'phaser-breakout'
        },
        {
            label: 'Drop It',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/drop-it-game-icon.jpg',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/drop-it-game-background.jpg',
            instructions: `Drop-It is a fun and relaxing block puzzle game. Drag the block to move it horizontally, then drop it 
                to make a move. If a row is filled, blocks at this row will be removed and your score will be increased. A new row of blocks 
                will slide in from bottom after making a move, so be careful. The game will end when the blocks reach the top of the stage, or 
                when time runs out.`,
            value: 'phaser-drop-it'
        },
        {
            label: 'Battleship',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/battleship-game-icon.png',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/battleship-game-icon.png',
            instructions: `Battle against an ai opponent and try to sink all its battleships before it sinks yours, or time runs out. 
                First the player sets the position of there ships. Then take turns with the ai and try to find and destroy the ships. 
                Each 4th attack will instantly sink any ship it hits. Player gains a point if they defeat the opponent. 
                Use you mouse to select the tile you wish to attack.`,
            value: 'phaser-battleship'
        },
        {
            label: 'Memory Match',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/memory-match-game-icon.png',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/memory-match-game-icon.png',
            instructions: `Memory Match is a simple classic board game which will challenge your memory. 
                Reach highest score as possible within the time limit. Tap, click, or touch a tile to flip it. 
                Then do the same for another. If the two flipped tiles match, they will be removed from the screen and your score will be increased.
                Each time all titles are cleared, a larger set of tiles will be added. This will repeat until timer runs out.`,
            value: 'phaser-memory-match'
        },
        {
            label: 'Merge Numbers',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/merge-numbers-game-icon.png',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/merge-numbers-game-icon.png',
            instructions: `Merge Numbers is a relaxing game. The objective of the game is to combine two of the same tiles 
                or numbers into one, then the tile numbers will be upgraded and the score will be increased. The game will end if 
                there is no room or movement available in the board, or when time runs out. To move tiles use touch/mouse (swipe game screen up, down, left or right), 
                or by pressing the W,A,S,D, or arrow keys.`,
            value: 'phaser-merge-numbers'
        },
        {
            label: 'Gold Miner',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/gold-miner-game-icon.png',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/gold-miner-game-background.png',
            instructions: `Gold Miner is a miner's adventure game that is simple and challenging. Collect as many gold 
                coins as possible, and beware of obstacles that can kill you. The aim of the game is to reach highest score as 
                possible. Points are awarded for collecting coins, and for every second you stay alive. The game ends if you hit 
                an obstacles, if you fall out of the stage, or when time runs out. Move the character by using touch or mouse 
                (swipe or hold game screen left or right). You can also use left/right arrow keys, or a/d keys.`,
            value: 'phaser-gold-miner'
        },
        {
            label: 'Tennis',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/tennis-game-icon.png',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/tennis-game-background.png',
            instructions: `Play against an ai opponent and hit the ball back and forth in a pong style game play. Ball speed 
                will be increased over time to make it harder. Score is increased if you hit the ball, or if the opponent 
                can't hit the ball. The game will end if you miss a ball hit back at you, or when time runs out. The character can 
                be moved using touch or mouse (swipe or hold game screen left or right). You can also use left/right arrow keys, 
                or a/d keys.`,
            value: 'phaser-tennis'
        },
        {
            label: 'Fishing Day',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/fishing-day-game-icon.png',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/fishing-day-game-icon.png',
            instructions: `Catch as many fish as you can before time runs out. Each caught fish will increase your score. 
                Mobile will have its own control buttons on screen. Web can control the boat by using the left/right arrow, or a/d keys. 
                The lure can be raised/lowered using the up/down arrow, or w/s keys. The line is reeled in automatically when a fish is caught, and it takes 
                the same amount of time to catch each fish. The game ends when time runs out.`,
            value: 'phaser-fishing-day'
        },
        {
            label: 'Snowmen Attack',
            iconImage: '/assets/images/gaming-assets/arcade-game-card-images/snowmen-attack-game-icon.png',
            backgroundImage: '/assets/images/gaming-assets/arcade-game-card-images/snowmen-attack-game-icon.png',
            instructions: `Protect your eggs from the attacking snowmen. Points are awarded for every second you protect your eggs. 
                Snowmen will gradually move toward your eggs and randomly toss snowballs. The game will end if one of your eggs are 
                hit by a snowball, reached by one of the snowmen, or when time runs out. You can throw snowballs at the snowmen to push them 
                back a little and to destroy their snowballs. Move between lanes by using the up/down arrow keys, and toss snowballs with 
                spacebar.`,
            value: 'phaser-snowmen-attack'
        }
    ];

    public defaultGameCardBg: string = '/assets/images/gaming-assets/default-game-bg.jpg';

    constructor(
        private formBuilder: UntypedFormBuilder,
        private httpClient: HttpClient,
        public snackBar: MatSnackBar,
    ) { }

    setCurrent(_game): void {
        this.currentGame = _game;
    }


    getCurrent() {
        return this.currentGame;
    }


    setGameInWizardForm(_game): void {
        this.gameInWizardForm = _game;
    }


    getGameInWizardForm() {
        return this.gameInWizardForm;
    }


    getAll(): Observable<Game[]> {
        return this.httpClient.get<Game[]>(this.API_URL);
    }


    get(_id: string): Observable<Game> {
        return this.httpClient.get<Game>(this.API_URL + _id);
    }


    create(_game): Observable<Game> {
        return this.httpClient.post<Game>(this.API_URL, JSON.stringify(_game), this.headerOptions);
    }


    search(searchTerms): Observable<Game[]> {
        return this.httpClient.post<Game[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    searchWithLimitAndSort(searchTerms, sortField = 'name', sortDirection = 'desc', pageSize: number = 10): Observable<Game[]> {
        searchTerms.sortField = sortField;
        searchTerms.sortOrder = sortDirection;
        searchTerms.pageSize = pageSize;

        return this.httpClient.post<Game[]>(this.API_URL + 'search-with-limit-and-sort', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<GameApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<GameApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<GameApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<GameApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    update(_game: Game): Observable<Game> {
        return this.httpClient.put<Game>(`${this.API_URL}${_game._id}`, JSON.stringify(_game), this.headerOptions);
    }


    markDeleted(id: string): Observable<any> {
        return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
    }


    markDeletedByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = { ids: arrayOfIds };
        return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    delete(_id: string): Observable<Game> {
        return this.httpClient.delete<Game>(this.API_URL + _id);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    gameFormGroup(formData: Game) {
        return this.formBuilder.group({
            name: [formData.name, Validators.required],
            related_module: [formData.related_module, Validators.required],
            appliesTo: [formData.appliesTo, Validators.required],
            fieldToWatch: [formData.fieldToWatch],
            fieldToWatchValue: [formData.fieldToWatchValue],
            goal: [formData.goal, Validators.required],
            progressMultiplier: [formData.progressMultiplier],
            templateKey: [formData.templateKey, Validators.required],
            description: [formData.description],
            background_image: [formData.trophy],
            trophy: [formData.trophy],
            teams: [formData.teams],
            rewards: [formData.rewards],
            deleted: [formData.deleted],
        });
    }
}