import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Template } from '../models';

export interface TemplateApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class TemplateService {
    private API_URL = '/api/templates/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    public currentSearch = {
        params: null,
        results: null
    };

    private currentBeingViewed;

    public templateTypes = ['email', 'sms', 'job description'];
    public variableTypes = ['Account', 'Contact/Lead/Target', 'User'];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private httpClient: HttpClient
    ) { }


    setCurrentBeingViewed(_template: Template): void {
        this.currentBeingViewed = _template;
    }


    getCurrentBeingViewed(): Template {
        return this.currentBeingViewed;
    }


    get(_id: string): Observable<Template> {
        return this.httpClient.get<Template>(this.API_URL + _id);
    }


    getAll(): Observable<Template[]> {
        return this.httpClient.get<Template[]>(this.API_URL);
    }


    create(_template): Observable<Template> {
        return this.httpClient.post<Template>(this.API_URL, JSON.stringify(_template), this.headerOptions);
    }


    update(_template: Template): Observable<Template> {
        return this.httpClient.put<Template>(`${this.API_URL}/${_template._id}`, JSON.stringify(_template), this.headerOptions);
    }


    delete(id: string): Observable<Template> {
        return this.httpClient.delete<Template>(this.API_URL + id);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + '/delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    search(searchTerms): Observable<Template[]> {
        return this.httpClient.post<Template[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchTerms): Observable<TemplateApi> {
        searchTerms['selectAll'] = true;
        searchTerms['selectNumberOfRecords'] = false;
        return this.httpClient.post<TemplateApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectNumberOfRecordsSearch(searchParams): Observable<any> {
        searchParams['selectAll'] = false;
        searchParams['selectNumberOfRecords'] = true;
        return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<Template[]> {
        searchParams['selectAll'] = false;
        searchParams['selectNumberOfRecords'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<Template[]>(this.API_URL + 'pagedSearch', JSON.stringify(searchParams), this.headerOptions); 
    }


    getCustomDatasourceTerms(searchParams): Observable<Template[]> {
        return this.httpClient.post<Template[]>(this.API_URL + 'pagedSearch', JSON.stringify(searchParams), this.headerOptions); 
    }


    templateFormGroup(formData: Template) {
        return this.formBuilder.group({
            name: [formData.name, Validators.required],
            type: [formData.type, Validators.required],
            description: [formData.description, Validators.required],
            send_type: [formData.send_type],
            accounts: [formData.accounts],
            group_category: [formData.group_category],
            content_type: [formData.content_type],
            profession: [formData.profession],
            subject: [formData.subject],
            body: [formData.body],
            body_html: [formData.body_html],
            send_text_only: [formData.send_text_only],
            use_for_auto_job_campaigns: [formData.use_for_auto_job_campaigns],
            variable_module: '',   
            variable_field: '',
            file_for_template: '',
            variable: '',
            restrict_to_module: [formData.restrict_to_module],
            teams: [formData.teams],
            deleted: [formData.deleted]  
        });
    }
}