import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { CourseEval } from '../models';

export interface CourseEvalApi {
  items: CourseEval[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class CourseEvalService {
  private currentCourseEvalBeingViewed: CourseEval;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/course-evals/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  public inputTypes: string[] = ['text', 'time', 'phone', 'checkbox', 'text-area', 'dropdown', 'dropdown-multi', 'blank'];

  constructor(
    private httpClient: HttpClient
  ) { }

  setCurrentBeingViewed(_courseEval: CourseEval): void {
    this.currentCourseEvalBeingViewed = _courseEval;
  }

  getCurrentBeingViewed(): CourseEval {
    return this.currentCourseEvalBeingViewed;
  }


  getAll(): Observable<CourseEval[]> {
    return this.httpClient.get<CourseEval[]>(this.API_URL);
  }


  get(_id: string): Observable<CourseEval> {
    return this.httpClient.get<CourseEval>(this.API_URL + _id);
  }


  update(_courseEval: CourseEval): Observable<CourseEval> {
    return this.httpClient.put<CourseEval>(this.API_URL + _courseEval._id, JSON.stringify(_courseEval), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<CourseEval[]> {
    return this.httpClient.put<CourseEval[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  updateEvalFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<CourseEval[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<CourseEval[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  deleteByIdArray(arrayOfIds): Observable<any[]> {
    const deleteRequest = {ids: arrayOfIds};
    return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  create(_courseEval: CourseEval): Observable<CourseEval> {
    return this.httpClient.post<CourseEval>(this.API_URL, JSON.stringify(_courseEval), this.headerOptions);
  }


  search(searchTerms): Observable<CourseEval[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<CourseEval[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'title', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<CourseEvalApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<CourseEvalApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }


  deleteForever(id: string): Observable<CourseEval> {
    return this.httpClient.delete<CourseEval>(this.API_URL + id);
  }
}
