import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';

import { Report } from '../models/report';
import { DropdownService } from './dropdown.service';

export interface ReportApi {
  items: Report[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class ReportService {
  private API_URL = '/api/reports/';
  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  private currentSetReport: Report;
  private moduleRecordData = new BehaviorSubject<any[]>(null);
  private basicReportParamChangeObs$ = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private _dropdownService: DropdownService
  ) { }


  setCurrent(_report: Report): void {
    this.currentSetReport = _report;
  }


  getCurrent(): Report {
    return this.currentSetReport;
  }


  getRelatedRecordData() {
    return this.moduleRecordData.asObservable();
  }


  announceRelatedRecordDataChange(_data: any[]) {
    this.moduleRecordData.next(_data);
  }


  listenForBasicReportParamChange() {
    return this.basicReportParamChangeObs$;
  }


  announceBasicReportParamChange() {
    this.basicReportParamChangeObs$.next(null);
  }


  get(_id: string): Observable<Report> {
    return this.httpClient.get<Report>(this.API_URL + _id);
  }


  getAll(): Observable<ReportApi> {
    return this.httpClient.get<ReportApi>(this.API_URL);
  }


  create(_setting): Observable<Report> {
    return this.httpClient.post<Report>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
  }


  update(_setting: Report): Observable<Report> {
    return this.httpClient.put<Report>(this.API_URL + _setting._id, JSON.stringify(_setting), this.headerOptions);
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = { ids: arrayOfIds, fields: fieldsToUpdate };
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  delete(id: string): Observable<Report> {
    return this.httpClient.delete<Report>(this.API_URL + id);
  }


  deleteByIdArray(arrayOfIds): Observable<any[]> {
    const deleteRequest = { ids: arrayOfIds };
    return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  search(searchTerms): Observable<ReportApi> {
    return this.httpClient.post<ReportApi>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectAllSearch(searchParams): Observable<ReportApi> {
    searchParams['selectAll'] = true;
    searchParams['selectNumberOfRecords'] = false;
    return this.httpClient.post<ReportApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<ReportApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<ReportApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  reportFormGroup(formData: Report) {
    return this.formBuilder.group({
      name: [formData.name, Validators.required],
      related_module: [formData.related_module, Validators.required]
    });
  }


  getAnyDropdowns(groupByColumns): Promise<any[]> {
    return new Promise(async (resolve) => {
      const dropdownsLookedUp = [];

      const updateControlsPromises = groupByColumns.map(async (_c) => {
        // console.log('Group by column: ', _c);
        // get dropdown if we need it

        if (_c['options'] && _c['options']['dropdownId']) {
          const dropdownId = _c['options']['dropdownId'];
          let dropdownField = null;
          const dropdownLookup = dropdownsLookedUp.find(_d => _d._id == dropdownId);

          if (dropdownLookup) {
            dropdownField = dropdownLookup;
          } else {
            dropdownField = await lastValueFrom(this._dropdownService.get(dropdownId));
            dropdownsLookedUp.push(dropdownField); // store it to save lookups
          }
        }
      });

      Promise.all(updateControlsPromises).then(() => {
        resolve(dropdownsLookedUp);
      });
    });
  }


  checkForDropdownValues(groupedData, _groupByColumns, _requestSource = 'table'): Promise<any[]> {
    return new Promise(async (resolve) => {
      // console.log('_requestSource: ', _requestSource);
      // if (_requestSource === 'card') console.log('groupedData: ', groupedData);
      // if (_requestSource === 'card') console.log('_groupByColumns: ', _groupByColumns);

      const dropdownGroups = _groupByColumns.filter(_gc => _gc.options && _gc.options.dropdownId);
      const dropdownsLookedUp = await this.getAnyDropdowns(dropdownGroups); // prevents multiple calls
      // console.log('dropdownsLookedUp: ', dropdownsLookedUp);

      if (dropdownGroups && dropdownGroups.length && dropdownsLookedUp) {
        // console.log('Remapping the data for dropdown labels.');

        dropdownGroups.forEach(_dg => {
          const dropdownId = _dg['options']['dropdownId'];
          const dropdownLookup = dropdownsLookedUp.find(_d => _d._id == dropdownId);

          // console.log('groupedData: ', groupedData);
          let fieldValue = null;
          let optionVal = null;
          let refParts = null;

          groupedData.forEach(_group => {
            if (_group[_dg.fieldName]) {
              // Group label
              if (dropdownLookup && dropdownLookup.options) {
                // console.log('dropdownLookup: ', dropdownLookup);
                // console.log('Group Value: ', _group.value);

                optionVal = dropdownLookup.options.find(_o => _o.value === _group.value);

                if (optionVal) {
                  // console.log('Option Val Found: ', optionVal);

                  const newLabel = optionVal.label;

                  _group.groupValueLabel = newLabel;
                  _group.value = newLabel;
                }
              }
            } else if (_group.name) {
              if (dropdownLookup && dropdownLookup.options) {
                optionVal = dropdownLookup.options.find(_o => _o.value === _group.name);
                if (optionVal) _group.name = optionVal.label;
              }
            } else {
              // group record
              fieldValue = _dg.fieldName.split('.').reduce((o, i) => o && o[i], _group);

              if (fieldValue && dropdownLookup && dropdownLookup.options) {
                // console.log('1111');

                optionVal = dropdownLookup.options.find(_o => _o.value === fieldValue);
                refParts = _dg.fieldName.split('.');

                if (refParts && refParts.length && _group[refParts[0]]) {
                  if (refParts.length === 2) {
                    // one level deep ref
                    _group[refParts[0]][refParts[1]] = optionVal.label;
                  } else if (refParts.length === 3) {
                    // two levels deep!
                    _group[refParts[0]][refParts[1]][refParts[2]] = optionVal.label;
                  }
                }
              }
            }
          });
        });
      }

      resolve(groupedData);
    });
  }


  setSearchData(_searchTerms, _currentUser): Promise<any> {
    return new Promise(async (resolve) => {
      const inclusionTerms = {};

      // console.log('_searchTerms: ', _searchTerms);

      _searchTerms.forEach(_st => {
        const lookupTerm = (_st.searchField.fieldName) ? _st.searchField.fieldName : _st.searchField;

        if (lookupTerm === 'myItems') {
          // currentLoggedInUser
          inclusionTerms['assigned_to'] = { inclusionType: 'Include', value: [_currentUser] };
        } else {
          inclusionTerms[lookupTerm] = { inclusionType: _st.searchInclusionType, value: _st.fieldValue };
        }
      });

      resolve(inclusionTerms);
    });
  }
}