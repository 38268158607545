<div class="colored-bg-wrapper bg">
  <div class="colored-bg-container">
      <div class="colored-bg-content">

        <div class="confirmation-wrapper">
          <div class="confirmation-inner">
            <p>Please paste this code in your gmail account confirmation input:</p>
        
            <div #authCodeDiv class="auth-code">{{ authCode }}</div>
        
            <div class="button-flex-wrap">
              <button type="button" class="auto-width" mat-flat-button color="primary" [cdkCopyToClipboard]="authCode" (click)="showConfirmCopy()">Copy to Clipboard</button>
            </div>
          </div>
        </div>

      </div>
  </div>
</div>