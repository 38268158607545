import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PublicRoutingModule } from './public.routing';
import { AngularMaterialModule } from '../angular-material/angular-material.module';

import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { NotificationsModule } from '../notifications/notifications.module';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { PublicPortalComponent } from './public-portal/public-portal.component';
import { PublicNavComponent } from './public-nav/public-nav.component';
import { PublicFooterComponent } from './public-footer/public-footer.component';
import { PipesModule } from '@app/pipes/pipes.module';

import { RecruitingSalesComponent } from './recruiting-sales/recruiting-sales.component';
import { HiringOnboardingComponent } from './hiring-onboarding/hiring-onboarding.component';
import { TalentManagementComponent } from './talent-management/talent-management.component';
import { TimeKeepingInvoicingComponent } from './time-keeping-invoicing/time-keeping-invoicing.component';
import { MarketingAutomationComponent } from './marketing-automation/marketing-automation.component';
import { ReportingAnalyticsComponent } from './reporting-analytics/reporting-analytics.component';
import { PricingComponent } from './pricing/pricing.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { RecruiterGamesComponent } from './recruiter-games/recruiter-games.component';
import { GmailAuthCodeComponent } from './gmail-auth-code/gmail-auth-code.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { TestComponent } from './test/test.component';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    HomeComponent,
    PublicPortalComponent,
    PublicNavComponent,
    PublicFooterComponent,
    RecruitingSalesComponent,
    HiringOnboardingComponent,
    TalentManagementComponent,
    TimeKeepingInvoicingComponent,
    MarketingAutomationComponent,
    ReportingAnalyticsComponent,
    PricingComponent,
    AboutUsComponent,
    RecruiterGamesComponent,
    GmailAuthCodeComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    TestComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NotificationsModule,
    PublicRoutingModule,
    PipesModule
  ],
  exports: [
    LoginComponent,
    HomeComponent
  ]
})
export class PublicModule { }
