<div class="game-rewards-viewer-dialog-wrapper">
    <div class="header-wrapper">
        <div class="search-title">
            <h2>Rewards</h2>
        </div>

        <div class="cancel-wrapper">
            <button mat-flat-button color="accent" (click)="closeDialog()">Close</button>
        </div>
    </div>

    <div class="game-rewards-viewer-dialog-content scrollable-dialog-content">
        <div class="game-reward-list-wrapper">
            <ng-container *ngIf="gameInstancePassedIn?.game?.rewards?.length">
                <div *ngFor="let _reward of gameInstancePassedIn?.game?.rewards; let i=index" class="game-reward-list-item" [ngClass]="{'with-top-border': i !== 0}">
                    <div class="reward-details">
                        <div class="detail-field">
                            <div class="flex-wrapper form-field">
                                <div class="title column">Type</div>
                                <div class="field column">{{ _reward?.rewardType | titlecase }}</div>
                            </div>
                        </div>

                        <div class="detail-field">
                            <div class="flex-wrapper form-field">
                                <div class="title column">Amount</div>
                                <div class="field column">{{ _reward?.amount }}</div>
                            </div>
                        </div>

                        <div class="detail-field">
                            <div class="flex-wrapper form-field">
                                <div class="title column">Applied Positions</div>
                                <div class="field column">
                                    <ng-container *ngIf="_reward?.appliedPositions?.length">
                                        <span *ngFor="let _numVal of _reward.appliedPositions; let j=index" class="position-item">
                                            {{ _numVal }}<span *ngIf="_reward?.appliedPositions[j + 1]">, </span>
                                        </span>
                                    </ng-container>

                                    <ng-container *ngIf="!_reward?.appliedPositions?.length">Not Set</ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!gameInstancePassedIn?.game?.rewards?.length">
                <p class="no-rewards-text">Just some friendly competition. Sorry no rewards set at this time, but have fun.</p>
            </ng-container>
        </div>
    </div>
</div>