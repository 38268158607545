// lives on front end only. This will come from a campaign
export class DialerCampaign {
    _id: string;
    status: number;
    name: string;
    organizer;
    currentCallerIndex: number;
    participants: any[]; 
    recording: any;
    twilioConferenceId;
    twilioConferenceName: string;
    
    isPaused: boolean;
    isStopped: boolean;
    isMasterActive: boolean;
    conferenceStarted: boolean;
    
    // just for front end
    currentCallee;
    attemptedCalls: any[];
    spokeWith: any[];
    voicemails: any[];
    remainingCalls: any[];
    campaignId;

    constructor(public _organizer = null, public _participants = [], public _voicemail = null) {
        this.status = 0;
        this.organizer = _organizer;
        this.participants = _participants;
        this.recording = _voicemail;
    }
}