import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { StoreCategory } from '../models/storeCategory';
import { Subject } from 'rxjs/internal/Subject';

export interface StoreCategoryApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class StoreCategoryService {
    private currentStoreCategory;
    private currentProductStoreCategory;
    private storeCategoryUpdateObs$ = new Subject<StoreCategory>();
    private currentProductCategoryObs$ = new Subject<StoreCategory>();

    private API_URL = '/api/store-categories/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    public rootCategories: StoreCategory[];
    public allCategories: StoreCategory[];

    constructor(
        private httpClient: HttpClient
    ) { }


    getCurrent() {
        return this.currentStoreCategory;
    }


    setCurrent(_storeCategory): void {
        this.currentStoreCategory = _storeCategory;
    }


    getCurrentProductCategory() {
        return this.currentProductStoreCategory;
    }


    listenForCategoryChange() {
        return this.currentProductCategoryObs$;
    }


    announceCategoryChange(_category) {
        this.currentProductStoreCategory = _category;
        this.currentProductCategoryObs$.next(_category);
    }


    getAll(): Observable<StoreCategory[]> {
        return this.httpClient.get<StoreCategory[]>(this.API_URL);
    }


    get(_id: string): Observable<StoreCategory> {
        return this.httpClient.get<StoreCategory>(this.API_URL + _id);
    }


    create(_setting): Observable<StoreCategory> {
        return this.httpClient.post<StoreCategory>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
    }


    search(searchTerms): Observable<StoreCategory[]> {
        return this.httpClient.post<StoreCategory[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<StoreCategoryApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<StoreCategoryApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<StoreCategoryApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<StoreCategoryApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    update(_storeCategory: StoreCategory): Observable<StoreCategory> {
        return this.httpClient.put<StoreCategory>(`${this.API_URL}${_storeCategory._id}`, JSON.stringify(_storeCategory), this.headerOptions);
    }


    delete(id: string): Observable<StoreCategory> {
        return this.httpClient.delete<StoreCategory>(this.API_URL + id);
    }


    // Sort array by calling recursive sort function
    sortCategories(_arr: StoreCategory[]) {
        return new Promise(async (resolve) => {
            await this.recurseSort(_arr);
            resolve(null);
        });
    }


    // Recursive sort by order
    recurseSort(arr = []) {
        return new Promise(async (resolve) => {
            if (arr.length === 0) resolve(null); // base case, to break the recursion when the array is empty

            arr.sort((a, b) => a.order - b.order); // sort this level

            const recurseSortPromises = await arr.map(async (_c) => {
                await this.recurseSort(_c.subCategories);
            }); // pass subCategories to next iteration for each category in this level

            await Promise.all(recurseSortPromises).then(async () => {
                resolve(null);
            });
        });
    }


    // Get parent of nested sub category
    recursiveGetParentNode(id: string, categoriesToSearch: StoreCategory[]): Promise<StoreCategory> {
        return new Promise(async (resolve) => {
            if (categoriesToSearch.length === 0) resolve(null); // base case, to break the recursion when the array is empty

            for (let i = 0; i < categoriesToSearch.length; i++) {
                if (categoriesToSearch[i]._id == id) resolve(categoriesToSearch[i]);

                const found = this.recursiveGetParentNode(id, categoriesToSearch[i].subCategories);
                if (found) resolve(found);
            }

            resolve(null);
        });
    }


    // Get top level parent from nested sub categories
    recursiveGetTopLevelParentNode(id: string, categoriesToSearch: StoreCategory[]): Promise<StoreCategory> {
        return new Promise(async (resolve) => {
            for (let category of categoriesToSearch) {
                let hasCatAsChild = category.subCategories.find(_p => _p._id === id);
                if (hasCatAsChild) resolve(category);

                let ret = this.recursiveGetTopLevelParentNode(id, category.subCategories);
                if (ret) resolve(ret);
            }
            resolve(null);
        });
    }
}