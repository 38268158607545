import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs/internal/Observable";

import { DialerCampaign } from "@app/models/dialerCampaign";

export class SessionKey {
  static CallCampaign = 'current-dialer-campaign';
}

@Injectable({ providedIn: 'root' })
export class YeeHroSession {
  private storage = sessionStorage;

  /* This section can be used to tell if an object in Session has changed.
  *  Add to it as needed.
  */
  private _callCampaign$: BehaviorSubject<DialerCampaign>
    = new BehaviorSubject<DialerCampaign>(<DialerCampaign>JSON.parse(this.storage.getItem(SessionKey.CallCampaign)));

  public callCampaign$: Observable<DialerCampaign>;

  constructor() {
    this.callCampaign$ = this._callCampaign$.asObservable();
  }

  get callCampaign(): DialerCampaign {
    // console.log('Getting call campaign data from storage.');
    return <DialerCampaign>JSON.parse(this.storage.getItem(SessionKey.CallCampaign));
  }

  set callCampaign(data: DialerCampaign) {
    // console.log('Setting Call Campaign to: ', data);
    this.storage.setItem(SessionKey.CallCampaign, JSON.stringify(data));
    this._callCampaign$.next(data);
  }

  // remove everything from session
  clear() {
    this.storage.removeItem(SessionKey.CallCampaign);
  }
}