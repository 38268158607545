import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class SendGridService {
  private API_URL = '/api/sendgrid/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  currentTemplate = null;

  constructor(
    private httpClient: HttpClient
  ) { }

  setCurrent(_template): void {
    this.currentTemplate = _template;
  }
  

  getCurrent(): any {
    return this.currentTemplate;
  }


  get(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + 'template-by-id/' + _id);
  }


  getAll(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.API_URL + 'get-all-templates');
  }


  search(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getApprovedSenders(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.API_URL + 'verified-senders');
  }
}
