import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { SocialAttachment } from '@app/models';

export interface SocialAttachmentApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class SocialAttachmentService {
    private currentSocialAttachment;

    private API_URL = '/api/social-attachments/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }

    setCurrent(_socialAttachment: SocialAttachment): void {
        this.currentSocialAttachment = _socialAttachment;
    }


    getCurrent() {
        return this.currentSocialAttachment;
    }


    getAll(): Observable<SocialAttachment[]> {
        return this.httpClient.get<SocialAttachment[]>(this.API_URL);
    }


    get(_id: string): Observable<SocialAttachment> {
        return this.httpClient.get<SocialAttachment>(this.API_URL + _id);
    }


    create(_socialAttachment): Observable<SocialAttachment> {
        return this.httpClient.post<SocialAttachment>(this.API_URL, JSON.stringify(_socialAttachment), this.headerOptions);
    }


    search(searchTerms): Observable<SocialAttachment[]> {
        return this.httpClient.post<SocialAttachment[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    update(_socialAttachment: SocialAttachment): Observable<SocialAttachment> {
        return this.httpClient.put<SocialAttachment>(`${this.API_URL}${_socialAttachment._id}`, JSON.stringify(_socialAttachment), this.headerOptions);
    }


    delete(_id: string): Observable<SocialAttachment> {
        return this.httpClient.delete<SocialAttachment>(this.API_URL + _id);
    }
}