
import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';

import { User } from '../models/user';

import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import * as jwt_decode from 'jwt-decode';
import jwt_decode from "jwt-decode";

export const TOKEN_NAME: string = 'jwt_token';

import { UserIdleService } from 'angular-user-idle';
import { SharedUtilsService } from './shared-utils.service';
import { TimeoutDialogComponent } from '@app/modules/dialogs/timeout-dialog/timeout-dialog.component';

@Injectable({ providedIn: 'root' })
export class AuthService {
  isLoggedIn: boolean;
  private loginObs$ = new Subject<User>();
  private currentUserLoggedIn;

  private changedLoggedInUserObs$ = new Subject<User>();

  public hasAdminAccess: boolean;
  public hasEditAccess: boolean;

  private API_URL = '/api/auth/';
  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  private userIdleSet: boolean = false;
  public disableTimeout: boolean = false;

  public userPreEmulateKey = 'user-pre-emulate';

  public redirectUrl: string; // Holds the site url that user is trying to access when logged out so they can be sent there after login.

  private timeoutTimer = {
    hasLoadedTimeoutDialog: false,
    countdownValue: 0,
    dialogRef: null
  }

  constructor(
    private userIdle: UserIdleService,
    private httpClient: HttpClient,
    private _sharedUtilsService: SharedUtilsService,
    private router: Router,
    public _timeoutDialog: MatDialog
  ) { }


  listenForLoggedInUserChange() {
    return this.changedLoggedInUserObs$;
  }


  announceLoggedInUserChange(_user: User) {
    // console.log('Change detected in Logged In User!');
    this.changedLoggedInUserObs$.next(_user);
  }


  /* ---------- JWT Attempt ---------- */
  getToken(): string {
    // check date set. If it was not today, then remove token from local storage
    return localStorage.getItem(TOKEN_NAME);
  }


  setToken(token: string): void {
    // set date set
    // const authStorageToken = {authToken: token, timestamp: new Date().getTime()};
    // console.log('Set storage auth item to: ', token);

    localStorage.setItem(TOKEN_NAME, token);
  }


  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded['exp'] === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded['exp']);
    return date;
  }


  isTokenExpired(token?: string): boolean {
    // console.log('Checking if token is expired: ', token);

    if (!token) token = this.getToken();
    if (!token) return true;

    const tokenExpDate = this.getTokenExpirationDate(token);

    if (tokenExpDate === undefined) return false;

    // const currentTime = new Date();
    // console.log('Current Time: ', currentTime);
    // const tokenValueOf = tokenExpDate.valueOf();
    // console.log('Comparing token exp time of: ' + tokenValueOf);
    // console.log('            Current time of: ', currentTime);

    // return false;

    return tokenExpDate.valueOf() < new Date().valueOf();
  }
  /* --------- END JWT Attempt --------- */


  checkLogin() {
    return this.loginObs$;
  }


  setIsLoggedIn(status: boolean): void {
    this.isLoggedIn = status;
  }


  getIsLoggedIn(): boolean {
    return this.isLoggedIn;
  }


  logout() {
    this.currentUserLoggedIn = null;
    this._sharedUtilsService.clearKeyFromLocal('user-access-controls');
  }


  setUserReqFromImpersonate(user: User): Observable<User> {
    return this.httpClient.post<User>('/api/users/set-user-impersonating', JSON.stringify(user), this.headerOptions);
  }


  cancelEmulate() {
    const userPreEmulate = this._sharedUtilsService.getFromLocal(this.userPreEmulateKey);
    this._sharedUtilsService.clearKeyFromLocal(this.userPreEmulateKey);

    this.impersonateUser(userPreEmulate, true);
  }


  async impersonateUser(user: User, _isCancellingEmulate: boolean = false) {
    if (!_isCancellingEmulate) this._sharedUtilsService.saveInLocal(this.userPreEmulateKey, this.getCurrentlyLoggedInUser());

    // this.setCurrentlyLoggedInUser(user);
    this._sharedUtilsService.clearKeyFromLocal('user-access-controls');

    const _userImpersonating = await lastValueFrom(this.setUserReqFromImpersonate(user));
    console.log('User Impersonating returned: ', _userImpersonating);

    this.setCurrentlyLoggedInUser(_userImpersonating);
    this.announceLoggedInUserChange(_userImpersonating); // Announce the user logged in use has changed so listeners can do there thing.
    this.getImpersonateUserRoute(_userImpersonating);
  }


  getImpersonateUserRoute(user) {
    const userType = user.userType;
    let userPortal = '';

    if (userType === 'System Admin') {
      userPortal = '/admin';
    } else if (userType === 'Dashboard') {
      userPortal = '/dashboard';
    } else if (userType === 'Provider') {
      userPortal = '/provider/dashboard';
    } else {
      userPortal = '/app/dashboard';
    }

    location.href = userPortal;
  }


  announceLogin(user: User) {
    this.isLoggedIn = true;
    this.setIsLoggedIn(true);
    this._sharedUtilsService.clearKeyFromLocal(this.userPreEmulateKey);

    // const currentDistrict = this._districtService.getFromLocal();
    // this.emulateDistrict(currentDistrict);
    // this._districtService.enterDistrictMode(currentDistrict);

    this.loginObs$.next(user);
  }


  announceLogout() {
    this.isLoggedIn = false;
    this.setIsLoggedIn(false);
    this.currentUserLoggedIn = null;

    localStorage.removeItem('currentUser');
    // localStorage.clear();

    this.loginObs$.next(null);
  }


  checkForAdminPrivileges(_user: User) {
    return _user.userType === 'System Admin';
  }


  initializeTimeoutDialog() {
    let currentUser = this.getCurrentlyLoggedInUser();

    // some pages are doing work. Do not time them out.
    if (this.disableTimeout || currentUser.userType === 'Dashboard' || (currentUser.email === 'jweber@eduhealthcare.com' && currentUser._id === '5e57d63e7e4f363784fcdf59')) {
      // console.log('TIMEOUT SUPPRESSED');

      this.timeoutTimer.hasLoadedTimeoutDialog = false;
      this.userIdle.resetTimer();
      return;
    }

    this.timeoutTimer.hasLoadedTimeoutDialog = true;

    const dialogRef = this._timeoutDialog.open(TimeoutDialogComponent);
    this.timeoutTimer.dialogRef = dialogRef;
    const instance = dialogRef.componentInstance;
    instance.secondsUntilTimeout = this.timeoutTimer.countdownValue - 2; // give it a bit of a head start

    dialogRef.afterClosed().subscribe(_usersChoiceToContinue => {
      if (_usersChoiceToContinue) {
        this.timeoutTimer.hasLoadedTimeoutDialog = false;
        this.userIdle.resetTimer();
      } else {
        this.userIdle.stopWatching();
        this.router.navigate(['/logout']);
      }
    });
  }


  setUserIdle() {
    // Checking to see what environment we are using.
    const docUrlOrigin = document.location.origin;
    const isDev = docUrlOrigin.includes('dev') || docUrlOrigin.includes('localhost');

    // If dev environment don't run.
    if (isDev) {
      // console.log('This is a dev environment so no need for userIdle.');
      return;
    }

    // prevent multiple subscribes, etc.
    if (this.userIdleSet) return;

    this.userIdleSet = true;

    this.userIdle.stopWatching();
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      const timeOut = this.userIdle.getConfigValue().timeout;

      // open a dialog and show the countdown along with a Continue Session button to close it and continue working...
      if (count === 1 && !this.timeoutTimer.hasLoadedTimeoutDialog) this.initializeTimeoutDialog();

      this.timeoutTimer.countdownValue = timeOut - count;
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.stopWatching();
      this._sharedUtilsService.clearKeyFromLocal(this.userPreEmulateKey);
      this.timeoutTimer.hasLoadedTimeoutDialog = false;
      this._sharedUtilsService.showNotification('You have been logged out due to inactivity.');

      this.router.navigate(['/logout']);
    });
  }


  stopUserIdle() {
    this.userIdle.stopTimer();
  }


  getCurrentlyLoggedInUser(): User {
    this.setUserIdle();
    this.userIdle.resetTimer();

    let currentUser = this.currentUserLoggedIn;

    if (currentUser) return currentUser;

    const ufs = this._sharedUtilsService.getFromLocal('currentUser');
    // console.log('User from storage: ', ufs);

    if (ufs) {
      currentUser = JSON.parse(ufs).user;
      this.currentUserLoggedIn = currentUser;
      // console.log('Found it in storage: ', this.currentUserLoggedIn);
    }

    return currentUser;
  }


  setCurrentlyLoggedInUser(_usr: User) {
    this.currentUserLoggedIn = _usr;
    const token = this.getToken();

    const newStorageItem = {
      auth: true,
      error: null,
      status: true,
      token: token,
      user: _usr
    };

    // console.log('New Storage Item: ', newStorageItem);

    this._sharedUtilsService.saveInLocal('currentUser', JSON.stringify(newStorageItem));
  }


  sendToRecordDetails(_currentUser, _record, detailsRoute) {
    if (!_currentUser) _currentUser = this.getCurrentlyLoggedInUser();

    const usersRoute = this.getRouteForUser(_currentUser.userType);
    // console.log('usersRoute: ', usersRoute);

    const constructedRoute = (usersRoute.includes('/app') && detailsRoute.includes('/app')) ? usersRoute.replace('/app', '') : usersRoute;
    // console.log('Constructed Route: ', constructedRoute);
    // console.log('detailsRoute: ', detailsRoute);

    this.router.navigate([constructedRoute + detailsRoute]);
  }


  getRouteForUser(userType) {
    let userPortal = '/app';

    if (userType === 'Provider') {
      userPortal = '/provider';
    }

    return userPortal;
  }


  doesUserHaveAccessForGivenType(typeNeededForRoute: string, userType = null) {
    // console.log('Checking if user has access type of: ', typeNeededForRoute);

    if (!userType) {
      const currentUser = this.getCurrentlyLoggedInUser();
      // console.log('currentUser: ', currentUser);
      userType = (currentUser && currentUser.userType) ? currentUser.userType : null;
    }

    // console.log('Type needed for this route is ' + typeNeededForRoute + '. Your user type is: ', userType);
    const passStatus = (userType === 'System Admin' || typeNeededForRoute === 'any') ? true : userType === typeNeededForRoute;
    // console.log('Pass Status for this: ', passStatus);

    return passStatus;
  }


  getUserAuthStatus(): Observable<Object[]> {
    return this.httpClient.get<Object[]>(this.API_URL + 'authenticated');
  }
}